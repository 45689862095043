import { Row, Col } from "react-bootstrap";
import React, { useState } from "react";

import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/dist/css/splide.min.css";

import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";

// import "./Css/Search.scss";
// import "../index.css";
import { FcOnlineSupport, FcPhone, FcViewDetails } from "react-icons/fc";
import {
  FaLink,
  FaFacebookSquare,
  FaLine,
  FaPhoneSquareAlt,
} from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import QRCode from "qrcode.react";
import { withRouter } from "react-router";
import axios from "axios";

import { useTranslation } from "react-i18next";

import no_img_innovat from "../../images/no_img_innovation.png";
import no_img_product from "../../images/no_img_product.png";
import no_img_creative from "../../images/no_img_creative.png";

import "../Css/innovation.scss";
import { Link } from "react-router-dom";
import { apiUrl } from "../../config/api.json";
function InnovationDetail() {
  const [innovationlist, setinnovationlist] = useState([]);
  const [innovation, setinnovation] = useState({});
  const [innovation_image, setinnovation_image] = useState([]);
  const [innovation_video, setinnovation_video] = useState("");
  const { t } = useTranslation();
  const getParamsId = () => {
    const windowUrl = window.location.search;
    const params = new URLSearchParams(windowUrl);
    return params.get("innovationid");
  };

  const getParamsType = () => {
    const windowUrl = window.location.search;
    const params = new URLSearchParams(windowUrl);
    return params.get("type");
  };

  const getParamsTypeTable = () => {
    const windowUrl = window.location.search;
    const params = new URLSearchParams(windowUrl);
    return params.get("typetable");
  };

  const innovation_id = getParamsId();
  const type = getParamsType();
  const typetable = getParamsTypeTable();

  var url_all;
  var url_find;
  var title_more;
  var no_img_innovation;

  if (type === "innovation") {
    url_all = `${apiUrl}/api/get/co_researcher_productionnovation/innovation/list?innovation_group_id=&title=`;
    url_find = `${apiUrl}/api/get/co_researcher_productionnovation/innovation/list?innovation_group_id=&title=&innovation_id=${innovation_id}&typetable=${typetable}`;
    title_more = "innovation.title_more_innovation";
    no_img_innovation = no_img_innovat;
  } else if (type === "product") {
    url_all = `${apiUrl}/api/get/co_researcher_productionnovation/product/list?title=`;
    url_find = `${apiUrl}/api/get/co_researcher_productionnovation/product/list?product_group_id=&title=&innovation_id=${innovation_id}`;
    title_more = "innovation.title_more_product";
    no_img_innovation = no_img_product;
  } else if (type === "creative") {
    url_all = `${apiUrl}/api/get/co_researcher_productionnovation/cretive/list?title=`;
    url_find = `${apiUrl}/api/get/co_researcher_productionnovation/cretive/list?product_group_id=&title=&innovation_id=${innovation_id}&typetable=${typetable}`;
    title_more = "innovation.title_more_creative";
    no_img_innovation = no_img_creative;
  }
  const getinnovationlist = () => {
    axios
      .get(url_all)
      .then((res) => {
        setinnovationlist(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getinnovation = () => {
    axios
      .get(url_find)
      .then((res) => {
        if (res.data.length > 0) {
          setinnovation(res.data[0]);
          setinnovation_image(res.data[0].images);
          setinnovation_video(
            res.data[0].innovation_vdo
              ? res.data[0].innovation_vdo
              : res.data[0].videos[0].innovation_vdolink_name
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  React.useEffect(() => {
    setinnovationlist([]);
    setinnovation({});
    setinnovation_image([]);
    setinnovation_video("");

    window.scrollTo(0, 0);
    getinnovationlist();
    getinnovation();
    // getParamsId();
    // getParamsType();
    // getParamsTypeTable();
  }, [innovation_id]);

  const getId = (url) => {
    const regExp =
      /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|shorts\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = url.match(regExp);
    return match && match[2].length === 11 ? match[2] : null;
  };

  let innovationdata = innovationlist.map(function (listValue, i) {
    var url_image = "";
    if (listValue.innovation_image) {
      url_image = listValue.innovation_image;
    } else if (listValue.images.length > 0) {
      // if (listValue.images[0].innovation_image_name) {
      //   url_image = listValue.images[0].innovation_image_name;
      // }
      url_image = listValue.images[0]?.innovation_image_name;
    } else {
      url_image = no_img_product;
    }

    var url_detail;

    if (type === "innovation") {
      url_detail = `/InnovationDetail?type=innovation&innovationid=${listValue.innovation_id}&typetable=${listValue.type}`;
    } else if (type === "product") {
      url_detail = `/InnovationDetail?type=product&innovationid=${listValue.innovation_id}&typetable=`;
    } else if (type === "creative") {
      url_detail = `/InnovationDetail?type=creative&innovationid=${listValue.innovation_id}&typetable=${listValue.type}`;
    }
    return (
      <SplideSlide key={i}>
        <div className="card card-bg-innovation card-innovation">
          <div className="card__image-holder">
            <img
              className="card__image img-innovation"
              src={url_image}
              // onError={(e) =>
              //   (e.target.onerror = null)((e.target.src = no_img_innovation))
              // }
              alt="co_innovation_image"
            />
          </div>
          <div className="card-innovation-title">
            <h2 className="innovation-title" style={{ paddingTop: "0.5rem" }}>
              {listValue.innovation_name ? listValue.innovation_name : "-"}
            </h2>
            <small className="innovation">
              {t("innovation.price")}:{" "}
              {listValue.innovation_price ? listValue.innovation_price : 0}{" "}
              {t("innovation.baht")}
            </small>
          </div>
          <div className="descriptions">
            <h5 className="title-hover">
              {listValue.innovation_name ? listValue.innovation_name : "-"}
            </h5>
            <p>
              <FcViewDetails size={15} /> :{" "}
              {listValue.innovation_detail
                ? listValue.innovation_detail.replace(
                    /<[^>]+>|&nbsp;|&quot;/g,
                    ""
                  )
                : "-"}
            </p>
            <p>
              {t("innovation.productionamount")} :{" "}
              {listValue.innovation_amount ? listValue.innovation_amount : "-"}{" "}
              {t("innovation.item")}
            </p>
            <p>
              <FcOnlineSupport size={15} /> :{" "}
              {listValue.co_researcher_pi_coordinator
                ? listValue.co_researcher_pi_coordinator
                : "-"}
            </p>
            <p>
              <FcPhone size={15} /> :{" "}
              {listValue.co_researcher_pi_phone
                ? listValue.co_researcher_pi_phone
                : "-"}
            </p>
            <Link to={url_detail} className="btn-innovation">
              {t("innovation.seemore")}
            </Link>
          </div>
        </div>
      </SplideSlide>
    );
  });

  const options = {
    type: "loop",
    gap: "1rem",
    perPage: 5,
    perMove: 1,
    interval: 2500,
    pagination: false,
    speed: 2000,
    breakpoints: {
      2560: {
        perPage: 5,
      },
      1440: {
        perPage: 4,
      },
      1024: {
        perPage: 4,
      },
      768: {
        perPage: 3,
      },
      640: {
        perPage: 1,
      },
    },
    autoplay: true,
    pauseOnHover: true,
    resetProgress: false,
  };

  const images = [];

  if (Object.keys(innovation).length > 0) {
    if (innovation.innovation_image) {
      images.push({
        original: innovation.innovation_image,
        thumbnail: innovation.innovation_image,
      });
    }
  }

  innovation_image.forEach(function (data) {
    if (data.innovation_image_name) {
      images.push({
        original: data.innovation_image_name,
        thumbnail: data.innovation_image_name,
      });
    }
  });

  return (
    <div className="body-detail">
      <div className="body-innovation">
        <Row>
          <Col sm={12}>
            <div className="inno-title">
              <h5>{innovation.innovation_name}</h5>
            </div>
          </Col>
          <Col sm={12} md={6} style={{ padding: "15px 10px 10px 30px" }}>
            {images.length > 1 ? (
              <div className="inno-image">
                <ImageGallery showThumbnails={true} items={images} />
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                className="inno-image"
              >
                {innovation.innovation_image ? (
                  <img
                    className="img-radius"
                    src={innovation.innovation_image}
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null; // prevents looping
                      currentTarget.src = no_img_product;
                    }}
                    style={{ width: "100%" }}
                    alt="img_product"
                  />
                ) : (
                  <>
                    {innovation.images ? (
                      <>
                        {innovation.images.length ? (
                          <img
                            className="img-radius"
                            src={innovation.images[0].innovation_image_name}
                            onError={({ currentTarget }) => {
                              currentTarget.onerror = null; // prevents looping
                              currentTarget.src = no_img_product;
                            }}
                            style={{ width: "100%" }}
                            alt="img_product"
                          />
                        ) : (
                          <div className="title-center">
                            <Row>
                              <Col md={12}>
                                <h3
                                  style={{
                                    color: "#000",
                                    fontFamily: "Prompt",
                                  }}
                                >
                                  {t("notimage")}
                                </h3>
                              </Col>
                            </Row>
                          </div>
                        )}
                      </>
                    ) : (
                      <div className="title-center">
                        <Row>
                          <Col md={12}>
                            <h3 style={{ color: "#000", fontFamily: "Prompt" }}>
                              {t("notimage")}
                            </h3>
                          </Col>
                        </Row>
                      </div>
                    )}
                  </>
                )}
              </div>
            )}
          </Col>
          <Col sm={12} md={6}>
            <div className="inno-video">
              <h4>{t("innovation.video")}</h4>
              {innovation_video ? (
                <>
                  {getId(innovation_video) !== null ? (
                    <iframe
                      style={{ width: "100%", height: "90%" }}
                      src={`https://www.youtube.com/embed/${getId(
                        innovation_video
                      )}`}
                      title="YouTube video player"
                      frameBorder={0}
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    />
                  ) : (
                    <div className="title-center">
                      <h3 style={{ color: "#000", fontFamily: "Prompt" }}>
                        ลิงค์วิดีโอไม่ถูกต้อง
                      </h3>
                    </div>
                  )}
                </>
              ) : (
                <div className="title-center">
                  <Row>
                    <Col md={12}>
                      <h3 style={{ color: "#000", fontFamily: "Prompt" }}>
                        {t("notvideo")}
                      </h3>
                    </Col>
                  </Row>
                </div>
              )}
            </div>
            <div className="inno-contact">
              <h6>
                {t("innovation.details")} :{" "}
                {innovation.innovation_detail
                  ? innovation.innovation_detail.replace(
                      /<[^>]+>|&nbsp;|&quot;/g,
                      ""
                    )
                  : "-"}
              </h6>
              <br />
              <br />
              <Row>
                <Col lg={12} xl={5}>
                  <h6>
                    {t("innovation.patent_number")} :
                    {innovation.patent_number ? innovation.patent_number : " -"}
                  </h6>
                  <h6>
                    {t("innovation.productionamount")} :
                    {innovation.innovation_amount
                      ? innovation.innovation_amount + t("innovation.item")
                      : " -"}
                  </h6>
                  <h6>
                    {t("innovation.price")} :
                    {innovation.innovation_price
                      ? innovation.innovation_price + " บาท"
                      : " -"}
                  </h6>
                  <h6>
                    {t("innovation.coordinator")} :
                    {innovation.co_researcher_pi_coordinator
                      ? innovation.co_researcher_pi_coordinator
                      : " -"}
                  </h6>
                  <h6>
                    {t("innovation.tel")} :
                    {innovation.co_researcher_pi_phone
                      ? innovation.co_researcher_pi_phone
                      : " -"}
                  </h6>
                  <div style={{ paddingTop: "1rem" }}>
                    {innovation.innovation_link ? (
                      <a
                        href={innovation.innovation_link}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <FaLink
                          size={30}
                          color={"#ae8753"}
                          className="icon-socail"
                        />
                      </a>
                    ) : null}

                    {innovation.co_researcher_pi_mail ? (
                      <a
                        href={`mailto:${innovation.co_researcher_pi_mail}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <MdEmail
                          size={30}
                          color={"#ea4335"}
                          className="icon-socail"
                        />
                      </a>
                    ) : null}
                    <a
                      href="https://www.lazada.co.th/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        className="icon-socail"
                        style={{ width: "30px", height: "30px" }}
                        src="https://lzd-img-global.slatic.net/g/tps/tfs/TB1iTziekWE3KVjSZSyXXXocXXa-42-42.png"
                        alt="iconlazada"
                      />
                    </a>
                    <a
                      href="https://shopee.co.th/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        className="icon-socail"
                        style={{ width: "30px", height: "30px" }}
                        src="https://aukey.co.th/wp-content/uploads/2020/03/Shopee-logo.png"
                        alt="iconshoppee"
                      />
                    </a>
                    <a
                      href="https://rcbt-sip.com/app/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        className="icon-socail"
                        style={{ width: "100px" }}
                        src="https://rcbt-sip.com/app/img/cbt1.8338ae81.png"
                        alt="icontrinitytrip"
                      />
                    </a>
                  </div>
                </Col>
                <Col
                  lg={12}
                  xl={7}
                  style={{
                    bordeleft: "2px solid #fcac4b",
                    borderRadius: "10px",
                    marginTop: "1rem",
                  }}
                >
                  <Row>
                    <Col
                      sm={12}
                      md={12}
                      lg={6}
                      xl={6}
                      xxl={4}
                      style={{ textAlign: "center" }}
                    >
                      <FaLine
                        size={40}
                        color={"#0ec863"}
                        className="icon-socail"
                        align="center"
                      />

                      <div>
                        <p className="card-title" align="center">
                          &nbsp;
                          {innovation.co_researcher_pi_line ? (
                            <div>
                              <a
                                href={`https://line.me/ti/p/~${innovation.co_researcher_pi_line}`}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <QRCode
                                  value={`https://line.me/ti/p/~${innovation.co_researcher_pi_line}`}
                                  style={{
                                    width: "100%",
                                    height: "100%",
                                  }}
                                />
                              </a>

                              <p>LINE</p>
                            </div>
                          ) : (
                            <p>ไม่พบผู้ใช้ หรือผู้ใช้ไม่อนุญาตให้ค้นหา</p>
                          )}
                          &nbsp;
                        </p>
                      </div>
                    </Col>
                    <Col
                      sm={12}
                      md={12}
                      lg={6}
                      xl={6}
                      xxl={4}
                      style={{ textAlign: "center" }}
                    >
                      <FaFacebookSquare
                        size={40}
                        color={"#1877F2"}
                        className="icon-socail"
                      />

                      <div>
                        <p className="card-title" align="center">
                          &nbsp;{" "}
                          {innovation.co_researcher_pi_facebook ? (
                            <div>
                              <a
                                href={`${innovation.co_researcher_pi_facebook}`}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <QRCode
                                  value={`${innovation.co_researcher_pi_facebook}`}
                                  style={{
                                    width: "100%",
                                    height: "100%",
                                  }}
                                />
                              </a>

                              <p>FACEBOOK</p>
                            </div>
                          ) : (
                            <p>ไม่พบผู้ใช้ หรือผู้ใช้ไม่อนุญาตให้ค้นหา</p>
                          )}
                          &nbsp;
                        </p>
                      </div>
                    </Col>
                    <Col
                      sm={12}
                      md={12}
                      lg={6}
                      xl={6}
                      xxl={4}
                      style={{ textAlign: "center" }}
                    >
                      <FaPhoneSquareAlt
                        size={40}
                        color={"#cd8503"}
                        className="icon-socail"
                      />

                      <div>
                        <p className="card-title" align="center">
                          &nbsp;{" "}
                          {innovation.co_researcher_pi_phone ? (
                            <div>
                              <QRCode
                                value={`tel:${innovation.co_researcher_pi_phone}`}
                                style={{
                                  width: "100%",
                                  height: "100%",
                                }}
                              />
                              <p>Tel</p>
                            </div>
                          ) : (
                            <p>ไม่พบผู้ใช้ หรือผู้ใช้ไม่อนุญาตให้ค้นหา</p>
                          )}
                          &nbsp;
                        </p>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
        <Row className="inno-other">
          <Col sm={12}>
            <h3 className="inno-other-title">
              {t(title_more)}
              {/* นวัตกรรม */}
            </h3>
          </Col>
          <Col md={12} style={{ textAlign: "right" }}>
            <Link
              class="custom-btn btn-14"
              style={{
                color: "white",
                fontFamily: "Prompt",
                cursor: "pointer",
                textDecoration: "none",
                textAlign: "center",
                width: "max-content",
              }}
              to={`/InnovationAll?type=${type}`}
            >
              <span style={{ fontSize: "16px" }}>
                {t("innovation.viewall")} ({innovationlist.length})
              </span>
            </Link>
          </Col>
          <Col sm={12} style={{ marginRight: "1rem" }}>
            {innovationlist.length > 0 ? (
              <Splide
                options={options}
                aria-labelledby="autoplay-example-heading"
              >
                {innovationdata}
              </Splide>
            ) : null}
          </Col>
        </Row>
      </div>
    </div>
  );
}
export default withRouter(InnovationDetail);
