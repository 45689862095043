import { Row, Col } from "reactstrap";
import React from "react";

import logo_footer from "../images/logo_footer.png";
import footer_stiker from "../images/footer_stiker.png";
import rmutilogo from "../images/kku_logo.jpg";
import sidelogo from "../images/ischool_kku.png";

export default function Footer() {
  return (
    <div className="footer-detail">
      <div className="footer-bg">
        <Row style={{ width: "100%", padding: "2rem", paddingBottom: "1rem" }}>
          <Col xs={6} xl={2}>
            <Row>
              <Col sm={12} style={{ textAlign: "left" }}>
                <h6>Made by</h6>
              </Col>
              <Col sm={12}>
                <img
                  src={logo_footer}
                  style={{ width: "80%", maxWidth: "150px" }}
                  alt="logo"
                />
              </Col>
            </Row>
          </Col>
          <Col xs={6} xl={2}>
            <Row>
              <Col sm={12} style={{ textAlign: "left" }}>
                <h6>Sponsorship</h6>
              </Col>
              <Col xs={6} sm={6}>
                <img
                  src={rmutilogo}
                  style={{ width: "80%", maxWidth: "85px" }}
                  alt="rmutilogo"
                />
              </Col>
              <Col
                xs={6}
                sm={6}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img
                  src={sidelogo}
                  style={{ width: "100%", maxWidth: "100px" }}
                  alt="sidelogo"
                />
              </Col>
            </Row>
          </Col>
          <Col xs={12} xl={8}>
            <Row>
              <Col sm={12} style={{ textAlign: "left" }}>
                <h6>ติดต่อ </h6>
              </Col>
              <Col
                sm={12}
                style={{
                  textAlign: "left",
                  marginBottom: "1.2rem",
                  marginTop: "0.2rem",
                  paddingRight: "2rem",
                }}
              >
                <h6>
                  Department of Information Science, Faculty of Humanities and
                  Social Sciences, Khon Kaen University Mittraparp Road, Nai
                  Muang, Muang, Khon Kaen, Thailand, 40002
                </h6>
                <h6>
                  Tel. +6643202861 | Ext. 45921 | Mobile. +66909562727
                  <br /> E-Mail. wirach@kku.ac.th | Website.
                  <a
                    href="https://ischool.kku.ac.th"
                    target="_blank"
                    rel="noreferrer"
                  >
                    https://ischool.kku.ac.th
                  </a>
                </h6>
              </Col>
              <Col sm={12} style={{ textAlign: "left", bottom: "0" }}>
                <h6>&copy; Copyright 2021 KIL. All Rights Reserved</h6>
              </Col>
            </Row>
          </Col>
          {/* <Col
            xs={6}
            xl={1}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              src={footer_stiker}
              style={{ width: "150%", maxWidth: "150px" }}
              alt="stiker"
            />
          </Col> */}
        </Row>
      </div>
    </div>
  );
}
