import React from "react";

import { Row } from "react-bootstrap";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";

import { Link } from "react-router-dom";

import { makeStyles, useTheme } from "@material-ui/core/styles";

import { BiNetworkChart, BiShoppingBag } from "react-icons/bi";
import {
  AiOutlineProject,
  AiFillHome,
  AiOutlineFundProjectionScreen,
} from "react-icons/ai";
import { FaUserTie } from "react-icons/fa";
import rmuti from "../images/rmuti.png";

// import rmutilogo from "../images/rmutilogo.png";
import kminn from "../images/kminn.png";
import logo_km from "../images/logo_km.png";
import bg_logo from "../images/bg-logo.png";

import "../index.css";
import axios from "axios";

import sidelogo from "../images/ischool_kku.png";

import ip from "../images/ip.png";

import FlTh from "../images/th-fl.png";
import FlEn from "../images/en-fl.jpg";

import { useTranslation } from "react-i18next";

import token from "../config/token.json";

import "./Css/menubar.scss";

import { FaAngleUp } from "react-icons/fa";
import { apiUrl } from "../config/api.json";
const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },

  shadowMenu: {
    boxShadow: "10px 0 20px -5px rgba(115,115,115,0.75)",
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  icon: {
    color: "rgb(219, 219, 219)",
  },
  dividerColor: {
    backgroundColor: "white",
  },
  imgone: {
    paddingRight: 10,
    [theme.breakpoints.up("sm")]: {
      paddingTop: 10,
      paddingRight: 10,
    },
  },
  imgtwo: {
    paddingTop: 0,
    display: "none",
    [theme.breakpoints.up("sm")]: {
      width: "230px",
      display: "inline-block",
      paddingTop: 10,
    },
  },
  imgthree: {
    paddingTop: 0,
    [theme.breakpoints.up("sm")]: {
      display: "none",
      width: "230px",
      paddingTop: 10,
    },
  },
  textUnderline: {
    "&:hover": {
      borderBottom: `2px solid #FFFFFF`,
      // width: 240,
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.easeInOut,
        duration: theme.transitions.duration.standard,
      }),
    },
  },
  link: {
    "&:hover": {
      color: "#FFF",
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    boxShadow: "rgb(95 90 90 / 36%) 1px 2px 20px 0px",
    width: drawerWidth,
    background: "#5D1809",
    color: "white",
    fontFamily: "Prompt",
    borderRight: "none",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  active: {
    backgroundColor: theme.palette.action.selected,
    "&:hover": {
      color: "#FFF",
    },
  },
  selected: {},
}));

function ResponsiveDrawer(props) {
  const classes = useStyles();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const { t } = useTranslation();
  const [count, setcount] = React.useState("");
  const [visible, setVisible] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300) {
      setVisible(true);
    } else if (scrolled <= 300) {
      setVisible(false);
    }
  };
  const onTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  window.addEventListener("scroll", toggleVisible);

  const locationsLanguage = (language) => {
    const url = new URL(document.location.href);
    url.searchParams.set("language", language);

    const params = new URLSearchParams(url.search);
    localStorage.setItem("language", params.get("language"));
    document.location = url.href;
  };

  React.useEffect(() => {
    // getLanguage();
    axios
      .get(`${apiUrl}/api/get/counts`, {
        headers: {
          "content-type": "application/json",
          "x-access-token": token.accesstoken,
        },
      })
      .then((res) => {
        setcount(res.data.count_ipaddress);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const drawer = (
    <div>
      <List>
        <ListItem style={{ backgroundColor: "#fff", marginBottom: "1.5rem" }}>
          <img
            src={sidelogo}
            width={150}
            style={{
              marginLeft: "1rem",
            }}
            alt="image_sidelogo"
          />
        </ListItem>

        <ListItem>
          <img
            src={rmuti}
            width="100%"
            style={{ marginTop: -15 }}
            alt="rmuti"
          />
        </ListItem>

        <Divider className={classes.dividerColor} />

        <Link
          to="/?checkproject=true&checkservice=true&checku2t=true&year=2564"
          onClick={() => setMobileOpen(false)}
          style={{ color: "#fff", textDecorationLine: "none" }}
        >
          <ListItem button className={classes.link}>
            <ListItemIcon className={classes.icon}>
              <AiFillHome size={25} />
            </ListItemIcon>
            <ListItemText>
              <text
                className={classes.textUnderline}
                style={{ fontFamily: "Prompt", fontSize: 14 }}
              >
                {t("menubar.index")}
              </text>
            </ListItemText>
          </ListItem>
        </Link>

        <Link
          to="/SearchProject"
          style={{ color: "#fff", textDecorationLine: "none" }}
          onClick={() => setMobileOpen(false)}
        >
          <ListItem button className={classes.link}>
            <ListItemIcon className={classes.icon}>
              <AiOutlineProject size={25} />
            </ListItemIcon>
            <ListItemText>
              <text
                className={classes.textUnderline}
                style={{ fontFamily: "Prompt", fontSize: 14 }}
              >
                {t("menubar.research")}
              </text>
            </ListItemText>
          </ListItem>
        </Link>

        <Link
          to="/SearchProjectService"
          style={{ color: "#fff", textDecorationLine: "none" }}
          onClick={() => setMobileOpen(false)}
        >
          <ListItem button className={classes.link}>
            <ListItemIcon className={classes.icon}>
              <AiOutlineFundProjectionScreen size={25} />
            </ListItemIcon>
            <ListItemText>
              <text
                className={classes.textUnderline}
                style={{ fontFamily: "Prompt", fontSize: 14 }}
              >
                {t("menubar.academic")}
              </text>
            </ListItemText>
          </ListItem>
        </Link>

        <Link
          to="/SearchResearcher"
          style={{ color: "#fff", textDecorationLine: "none" }}
          onClick={() => setMobileOpen(false)}
        >
          <ListItem button className={classes.link}>
            <ListItemIcon className={classes.icon}>
              <FaUserTie size={25} />
            </ListItemIcon>
            <ListItemText>
              <text
                className={classes.textUnderline}
                style={{ fontFamily: "Prompt", fontSize: 14 }}
              >
                {t("menubar.researcher")}
              </text>
            </ListItemText>
          </ListItem>
        </Link>

        <Link
          to="/SearchPageCo"
          style={{ color: "#fff", textDecorationLine: "none" }}
          onClick={() => setMobileOpen(false)}
        >
          <ListItem className={classes.link} button>
            <ListItemIcon className={classes.icon}>
              <BiNetworkChart size={25} />
            </ListItemIcon>
            <ListItemText>
              <text
                className={classes.textUnderline}
                style={{ fontFamily: "Prompt", fontSize: 14 }}
              >
                {t("menubar.co_researcher")}
              </text>
            </ListItemText>
          </ListItem>
        </Link>

        <Link
          to="/Innovation"
          onClick={() => setMobileOpen(false)}
          style={{ color: "#fff", textDecorationLine: "none" }}
        >
          <ListItem className={classes.link} button>
            <ListItemIcon className={classes.icon}>
              <BiShoppingBag size={25} />
            </ListItemIcon>
            <ListItemText>
              <text
                className={classes.textUnderline}
                style={{ fontFamily: "Prompt", fontSize: 14 }}
              >
                {t("menubar.innovation")}
              </text>
            </ListItemText>
          </ListItem>
        </Link>

        <Link
          to="/Patent"
          style={{ color: "#fff", textDecorationLine: "none" }}
          onClick={() => setMobileOpen(false)}
        >
          <ListItem className={classes.link} button>
            <ListItemIcon className={classes.icon}>
              <img src={ip} width={28} alt="ip" />
            </ListItemIcon>
            <ListItemText>
              <text
                className={classes.textUnderline}
                style={{ fontFamily: "Prompt", fontSize: 14 }}
              >
                {t("menubar.intellectual")}
              </text>
            </ListItemText>
          </ListItem>
        </Link>
      </List>
      <Divider className={classes.dividerColor} />
      <div
        style={{
          bottom: 0,
          marginBottom: 10,
          marginTop: "1rem",
          textAlign: "center",
        }}
      >
        <img
          src={FlTh}
          style={{ width: "40px", marginRight: "10px", cursor: "pointer" }}
          onClick={() => {
            locationsLanguage("th");
          }}
          alt="flag_th"
        />
        <img
          src={FlEn}
          style={{ width: "40px", cursor: "pointer" }}
          onClick={() => {
            locationsLanguage("en");
          }}
          alt="flag_en"
        />
        <ListItem>
          <img src={kminn} width="100%" alt="kmin" />
        </ListItem>

        <div>
          {t("countuser")} {count ? count.toLocaleString() : "0"} {t("view")}
        </div>
        <div className="website-counter"></div>
      </div>
    </div>
  );

  return (
    <div>
      <nav className="top-bar">
        <Row>
          <img src={bg_logo} className="bg-img-menubar" alt="bg_logo" />
          <div
            style={{ width: "150px", height: "70px" }}
            onClick={handleDrawerToggle}
          >
            <div className="div-icon-menu">
              <div className="icon-menu-bar">
                <div className="icon-menu" />
                <div className="icon-menu" />
                <div className="icon-menu" />
              </div>
            </div>
          </div>
          <div className="menubar2"></div>
          <img src={logo_km} className="img-menubar2" alt="logo_km" />
          <div className="bg-title-th">
            <p style={{ fontSize: "13px", color: "#fff" }}>
              ระบบจัดการความรู้และนวัตกรรม
            </p>
          </div>
          <div className="bg-title-en">
            <p style={{ fontSize: "13px", color: "#fff" }}>
              Knowledge & Innovation Management System
            </p>
          </div>
        </Row>
      </nav>
      <Hidden smUp implementation="css">
        <Drawer
          variant="temporary"
          anchor={theme.direction === "rtl" ? "right" : "left"}
          open={mobileOpen}
          onClose={handleDrawerToggle}
          classes={{
            paper: classes.drawerPaper,
          }}
          ModalProps={{
            keepMounted: true,
          }}
        >
          {drawer}
        </Drawer>
      </Hidden>
      <nav className="leftbar">
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
      <div
        className="uptotop"
        onClick={onTop}
        style={{ display: visible ? "block" : "none" }}
      >
        <FaAngleUp style={{ marginTop: "5px" }} size={30} />
      </div>
    </div>
  );
}

export default ResponsiveDrawer;
