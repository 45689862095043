import "leaflet/dist/leaflet.css";
import { useEffect } from "react";
import axios from "axios";
import { Switch, Route, Redirect } from "react-router-dom";
import Researcher from "./Components/Researcher";
import CoResearcher from "./Components/CoResearcher";
import Leftbar from "./Components/Leftbar";
import Footer from "./Components/Footer";
import ProjcetDetailConcept from "./Components/ProjcetDetailConcept";
import SearchPageProject from "./Components/SearchPageProject";
import SearchPageCoRe from "./Components/SearchPageCoRe";
import SearchPageResearch from "./Components/SearchPageResearch";
import SearchPageProjectSevice from "./Components/SearchPageProjectSevice";
import SearchMap from "./Components/SearchMap";
import Innovation from "./Components/Innovation.js";
import InnovationAll from "./Components/InnovationPage/InnovationAll";
import InnovationDetail from "./Components/InnovationPage/InnovationDetail";
import Patent from "./Components/Patent.js";
import PatentAll from "./Components/PatentPage/PatentAll";
import PatentDetail from "./Components/PatentPage/PatentDetail";
import Research from "./Components/PatentPage/Research";
import CoRe from "./Components/PatentPage/CoRe";
import Chumchon from "./Components/PatentPage/Chumchon";
import i18n from "./i18n";

import token from "./config/token.json";
import { apiUrl } from "./config/api.json";
// const baseRouteUrl = "/:locale(th|en)?";
// const baseUrl = i18n.language === "en" ? "" : "/" + i18n.language;
function App(props) {
  const locationCo = [];
  const locationSearch = [];
  // const d = new Date();
  // const year = d.getFullYear() + 543;
  const year = 2564;

  const getData = async () => {
    const res = await axios.get("https://geolocation-db.com/json/");
    postip(res.data);
  };
  const postip = async (params) => {
    await axios.post(`${apiUrl}/api/post/create_visitors`, params, {
      headers: {
        "content-type": "application/json",
        "x-access-token": token.accesstoken,
      },
    });
  };

  useEffect(() => {
    getData();
    // const params = new URLSearchParams(document.location.search);
    // const ns = params.get("language");

    localStorage.getItem("language") === "th"
      ? i18n.changeLanguage("th")
      : i18n.changeLanguage("en");

    // const url = new URL(document.location.href);
    // url.searchParams.append("language", "th");

    // document.location = url.href;

    // axios.get(`${apiUrl}/api/get/co-researcher`).then((locationCo) => {
    //   Setdatamap(locationCo.data);
    // });

    // axios.get(`${apiUrl}/api/get/us-project`).then((loc) => {
    //   // console.log(loc.data);
    //   SetdataMapSe(loc.data);
    // });
  }, []);

  return (
    <div>
      <Leftbar />
      <Switch>
        <Route
          exact
          path="/"
          render={() => {
            return (
              <Redirect
                to={{
                  pathname: "/map",
                  search: `?checkproject=true&checkservice=true&checku2t=true&year=${year}`,
                }}
              ></Redirect>
            );
          }}
        ></Route>

        <Route path={"/map"}>
          <SearchMap />
        </Route>

        <Route path="/Researcher">
          <Researcher />
        </Route>

        <Route path="/ProjectDetailConcep/knowledege">
          <knowledgePage />
        </Route>

        <Route path="/CoResearcher">
          <CoResearcher />
        </Route>

        <Route path="/ProjectDetailConcep">
          <ProjcetDetailConcept typeproject="concep" />
        </Route>

        <Route path="/ProjectDetail">
          <ProjcetDetailConcept typeproject="usproject" />
        </Route>

        <Route path="/SearchProject">
          <SearchPageProject locationSearch={locationSearch} />
        </Route>

        <Route path="/SearchProjectService">
          <SearchPageProjectSevice />
        </Route>

        <Route path="/SearchResearcher">
          <SearchPageResearch locationSearch={locationSearch} />
        </Route>

        <Route path="/SearchPageCo">
          <SearchPageCoRe locationCo={locationCo} />
        </Route>

        <Route path="/Innovation">
          <Innovation />
        </Route>

        <Route path="/InnovationAll">
          <InnovationAll />
        </Route>
        <Route exact path="/InnovationDetail">
          <InnovationDetail />
        </Route>
        <Route path="/Patent">
          <Patent />
        </Route>

        <Route path="/PatentAll">
          <PatentAll />
        </Route>

        <Route path="/PatentDetail">
          <PatentDetail />
        </Route>

        <Route path="/Research">
          <Research />
        </Route>

        <Route path="/CoRe">
          <CoRe />
        </Route>

        <Route path="/Chumchon">
          <Chumchon />
        </Route>
      </Switch>
      <Footer />
    </div>
  );
}

export default App;
