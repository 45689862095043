import TableContainer from "@material-ui/core/TableContainer";
import { MapContainer, TileLayer, ZoomControl } from "react-leaflet";

import Map from "../D3Layer";
import { useEffect, useState } from "react";
import axios from "axios";
import token from "../../config/token.json";
import { apiUrl } from "../../config/api.json";
export default function ResearcherGroup(props) {
  const { concept_proposal_id } = props;
  const [mapData, setMapData] = useState([]);
  const localUrl = "http://localhost:4000";
  let id = concept_proposal_id;

  const mapDataTeam = async () => {
    const response = await axios.get(
      `${apiUrl}/api/get/us-project-map/team/${id}`,
      {
        headers: {
          "content-type": "application/json",
          "x-access-token": token.accesstoken,
        },
      }
    );
    setMapData(response.data);
  };

  useEffect(() => {
    mapDataTeam();
  }, []);
  return (
    <TableContainer className="p-4">
      <MapContainer
        className="minimap2sub1 "
        center={[15, 103]}
        zoom={7}
        scrollWheelZoom={true}
        zoomControl={false}
        style={{ zIndex: "0", borderRadius: "15px" }}
      >
        <TileLayer
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <ZoomControl position="topright" />
        {Object.keys(mapData).length > 0 ? <Map location={mapData} /> : null}
      </MapContainer>
    </TableContainer>
  );
}
