import { useEffect, useState } from "react";
import { AiOutlineZoomIn } from "react-icons/ai";
import "./lightbox.scss";

// const image1 =
//   "https://images.unsplash.com/photo-1497752531616-c3afd9760a11?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2100&q=80";
// const image2 =
//   "https://images.unsplash.com/photo-1470093851219-69951fcbb533?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2100&q=80";
// const image3 =
//   "https://images.unsplash.com/photo-1447684808650-354ae64db5b8?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2094&q=80";
// const image4 =
//   "https://images.unsplash.com/photo-1425082661705-1834bfd09dca?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2110&q=80";
// const image5 =
//   "https://images.unsplash.com/photo-1494256997604-768d1f608cac?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2301&q=80";
// const image6 =
//   "https://images.unsplash.com/photo-1500694216671-a4e54fc4b513?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2092&q=80";

// //IMAGE ARRAY
// const images = [image1, image2, image3, image4, image5, image6];

const Lightbox = (props) => {
  const { image, images, position } = props;

  // console.log(props);
  const [imageToShow, setImageToShow] = useState("");
  const [lightboxDisplay, setLightBoxDisplay] = useState(false);

  // console.log(image);

  const newimages = images.map((item) => {
    return item.book_image
      ? item.book_image
      : item.conference_image
      ? item.conference_image
      : item.journal_image
      ? item.journal_image
      : "https://teelindy.com/wp-content/uploads/2019/03/default_image.png";
  });
  newimages.unshift(image);

  // console.log(newimages);

  //looping through our images array to create img elements
  const imageCards = newimages.map((image) => (
    <div class="gallery">
      <div>
        <a onClick={() => showImage(image)}>
          <img
            className="image-card"
            src={image}
            width="100%"
            height={260}
            alt="imageToShow"
          />
          <span class="overlay">
            {position !== 0 ? (
              "+" + images.length
            ) : (
              <AiOutlineZoomIn size={60} />
            )}
          </span>
        </a>
      </div>
    </div>
  ));

  //function to show a specific image in the lightbox, amd make lightbox visible
  const showImage = (image) => {
    setImageToShow(image);
    setLightBoxDisplay(true);
  };

  //hide lightbox
  const hideLightBox = () => {
    setLightBoxDisplay(false);
  };

  //show next image in lightbox
  const showNext = (e) => {
    e.stopPropagation();
    let currentIndex = newimages.indexOf(imageToShow);
    // if (currentIndex >= newimages.length - 1) {
    //   setLightBoxDisplay(true);
    // } else
    if (currentIndex + 1 >= newimages.length) {
      setImageToShow(newimages[0]);
    } else {
      let nextImage = newimages[currentIndex + 1];
      setImageToShow(nextImage);
    }
  };

  //show previous image in lightbox
  const showPrev = (e) => {
    e.stopPropagation();
    let currentIndex = newimages.indexOf(imageToShow);
    // if (currentIndex <= 0) {
    //   setLightBoxDisplay(false);
    // } else
    if (currentIndex <= 0) {
      setImageToShow(newimages[newimages.length - 1]);
    } else {
      let nextImage = newimages[currentIndex - 1];
      setImageToShow(nextImage);
    }
  };

  return (
    <>
      <div>{imageCards[position]}</div>

      {lightboxDisplay ? (
        <div id="lightbox" onClick={hideLightBox}>
          <div className="btnlightbox prev" onClick={showPrev}></div>
          <img id="lightbox-img" src={imageToShow} alt="imageToShow"></img>
          <div className="btnlightbox next" onClick={showNext}></div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default Lightbox;
