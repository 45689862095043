import React, { useState, useEffect, useRef } from "react";
import { CardTitle, Card, CardBody, Row, Col } from "reactstrap";
import { Tabs, Tab } from "react-bootstrap";
// import { useTranslation } from "react-i18next";
import { FaChevronCircleDown } from "react-icons/fa";
import axios from "axios";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import { apiUrl } from "../../config/api.json";
import { makeStyles } from "@material-ui/core/styles";
import Youtubeshow from "../Detail/Youtubeshow";
const useStyles = makeStyles((theme) => ({
  appbar: {
    alignItems: "center",
    backgroundColor: "#fff",
    boxShadow: "none",
  },
  root: {
    flexGrow: 1,
    width: "100%",
    // backgroundColor: theme.palette.background.paper,
  },

  customLabel: {
    fontFamily: "Prompt",
    fontWeight: "bold",
    color: "black",
  },

  round: {
    borderRadius: "50%",
    border: "4px solid rgb(223, 223, 223)",
    width: "100%",
    boxShadow:
      "rgba(0, 0, 0, 0.293) 0px 19px 38px, rgba(0, 0, 0, 0.129) 0px 15px 12px",
    [theme.breakpoints.up("sm")]: {
      width: "65%",
    },
  },

  underlineCol: {
    borderBottom: "2px solid rgb(211, 211, 211)",
    paddingTop: 12,
  },

  tabpanel: {
    marginLeft: "auto",
    marginRight: "auto",
  },

  headerLabel: {
    position: "relative",
    fontFamily: "Prompt",
    fontWeight: "bold",
    color: "black",
    zIndex: 1,

    textAlign: "center",

    "&::after": {
      position: "absolute",
      content: '""',
      top: 10,
      borderBottom: "3px solid black",
      width: 120,
      left: "50%",
      marginTop: 10,
      marginLeft: "-60px",
      bottom: 0,
    },
  },

  cardlayout: {
    marginTop: 20,
    boxShadow:
      "rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px",
    [theme.breakpoints.up("sm")]: {
      marginTop: 0,
    },
  },

  tabsBg: {
    background:
      "linear-gradient(90deg,rgba(240, 99, 0, 1) 0%,rgba(255, 115, 0, 1) 38%,rgba(254, 148, 0, 1) 100%)",
  },
}));

const GoalsDetail = (props) => {
  const classes = useStyles();
  // const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [sdg, setsdg] = useState([]);
  const [sdgDetail, setsdgDetail] = useState([]);
  const [bcg, setbcg] = useState([]);
  const [bcgDetail, setbcgDetail] = useState([]);
  const [curve, setcurve] = useState([]);
  const [curveDetail, setcurveDetail] = useState([]);
  const [cluster, setcluster] = useState([]);
  const [clusterDetail, setclusterDetail] = useState([]);

  const detailRef = useRef();
  const detailCurveRef = useRef();
  const detailBcgRef = useRef();
  const detailSdgsRef = useRef();

  const onClickCluster = (index) => {
    const data = cluster[index];
    data.cluster_detail.forEach((el, index) => {
      const img_cluster = [];
      el.images.forEach((img) => {
        img_cluster.push({
          original: img.goal_img_path,
          thumbnail: img.goal_img_path,
        });
      });
      data.cluster_detail[index]["img_side"] = img_cluster;
    });
    setclusterDetail(data);
    detailRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const onClickCurve = (index) => {
    const data = curve[index];
    data.curve_detail.forEach((el, index) => {
      const img_curve = [];
      el.images.forEach((img) => {
        img_curve.push({
          original: img.goal_img_path,
          thumbnail: img.goal_img_path,
        });
      });
      data.curve_detail[index]["img_side"] = img_curve;
      // console.log("123",data.curve_detail);
    });
    setcurveDetail(data);
    detailCurveRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const onClickBcg = (index) => {
    const data = bcg[index];
    data.bcg_detail.forEach((el, index) => {
      const img_bcg = [];
      el.images.forEach((img) => {
        img_bcg.push({
          original: img.goal_img_path,
          thumbnail: img.goal_img_path,
        });
      });
      data.bcg_detail[index]["img_side"] = img_bcg;
    });
    setbcgDetail(data);
    detailBcgRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const onClickSdgs = (index) => {
    const data = sdg[index];
    data.sdgs_detail.forEach((el, index) => {
      const img_sdg = [];
      el.images.forEach((img) => {
        img_sdg.push({
          original: img.goal_img_path,
          thumbnail: img.goal_img_path,
        });
      });
      data.sdgs_detail[index]["img_side"] = img_sdg;
    });
    setsdgDetail(data);
    detailSdgsRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const getParamsId = () => {
    const windowUrl = window.location.search;
    const params = new URLSearchParams(windowUrl);
    const project_id = params.get("project_id");
    if (!project_id) {
      const project_id = params.get("concep_id");
      return project_id;
    }
    return project_id;
  };
  const idencrypt = getParamsId();

  const getgoals = async (id) => {
    try {
      const res = await axios.get(
        `${apiUrl}/api/get/concept_proposal/goals/${id}`
      );
      setsdg(res.data[0].sdg);
      setbcg(res.data[0].bcg);
      setcurve(res.data[0].curve);
      setcluster(res.data[0].cluster);

      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  useEffect(() => {
    let id;
    // console.log("goalpage:", props);
    if ((Object.keys(props).length === 0) === true) {
      if (idencrypt === null || idencrypt === "") {
        props.history.push({
          pathname: "/SearchProject",
        });
      } else {
        id = atob(idencrypt);
      }
      getgoals(id);
    } else {
      setsdg(props.sdgs);
      setbcg(props.bcg);
      setcurve(props.curve);
      setcluster(props.cluster);
      setLoading(false);
      // console.log("curve Goals ", props.curve);
    }
  }, []);

  const sdgEl = sdg.map((ListItem, index) => {
    return (
      <Col key={index} xs={12} sm={12} md={6} lg={4} xl={4}>
        <div
          className="card-output"
          style={{ backgroundColor: "#fff", height: "auto" }}
          onClick={() => onClickSdgs(index)}
        >
          <img
            src={`https://researcher.kims-rmuti.com/icon/${ListItem.sdgs_image}`}
            alt="Cardimagecap"
            className={classes.round}
          />
          <Row style={{ paddingTop: "1rem" }}>
            <Col xs={10}>
              <CardTitle
                className={classes.customLabel}
                style={{
                  fontFamily: "Prompt",
                  paddingTop: 15,
                  paddingBottom: 0,
                }}
              >
                {ListItem.sdgs_name}
              </CardTitle>
            </Col>
            <Col xs={2} style={{ textAlign: "left", marginTop: "1rem" }}>
              <FaChevronCircleDown size={30} className="arrow-down" />
            </Col>
          </Row>

          {/* <p>รายละเอียดความสอดคล้อง: {ListItem.sdgs_detail}</p> */}
        </div>
      </Col>
    );
  });

  const bcgEl = bcg.map((ListItem, index) => {
    return (
      <Col key={index} xs={12} sm={12} md={6} lg={4} xl={4}>
        <div
          className="card-output"
          style={{ backgroundColor: "#fff", height: "auto" }}
          onClick={() => onClickBcg(index)}
        >
          <img
            src={`https://researcher.kims-rmuti.com/icon/${ListItem.bcg_image}`}
            alt="Cardimagecap"
            className={classes.round}
          />
          <Row style={{ paddingTop: "1rem" }}>
            <Col xs={10}>
              <CardTitle
                className={classes.customLabel}
                style={{
                  fontFamily: "Prompt",
                  paddingTop: 15,
                  paddingBottom: 0,
                }}
              >
                {ListItem.bcg_name}
              </CardTitle>
            </Col>
            <Col xs={2} style={{ textAlign: "left", marginTop: "1rem" }}>
              <FaChevronCircleDown size={30} className="arrow-down" />
            </Col>
          </Row>
        </div>
        {/* <p> รายละเอียดความสอดคล้อง: {ListItem.bcg_detail}</p> */}
      </Col>
    );
  });

  const curveEl = curve.map((ListItem, index) => {
    return (
      <Col key={index} xs={12} sm={12} md={6} lg={4} xl={4}>
        <div
          className="card-output"
          style={{ backgroundColor: "#fff", height: "auto" }}
          onClick={() => onClickCurve(index)}
        >
          <img
            src={`https://researcher.kims-rmuti.com/icon/${ListItem.curve_image}`}
            alt="Cardimagecap"
            className={classes.round}
          />
          <Row style={{ paddingTop: "1rem" }}>
            <Col xs={10}>
              <CardTitle
                className={classes.customLabel}
                style={{
                  fontFamily: "Prompt",
                  paddingTop: 15,
                  paddingBottom: 0,
                }}
              >
                {ListItem.curve_name}
              </CardTitle>
            </Col>
            <Col xs={2} style={{ textAlign: "left", marginTop: "1rem" }}>
              <FaChevronCircleDown size={30} className="arrow-down" />
            </Col>
          </Row>
        </div>

        {/* <p> รายละเอียดความสอดคล้อง:{ListItem.curve_detail}</p> */}
      </Col>
    );
  });

  const clusterEl = cluster.map((ListItem, index) => {
    return (
      <Col key={index} xs={12} sm={12} md={6} lg={4} xl={4}>
        <div
          className="card-output"
          style={{ backgroundColor: "#fff", height: "auto" }}
          onClick={() => onClickCluster(index)}
        >
          <img
            src={`https://researcher.kims-rmuti.com/icon/${ListItem.cluster_image}`}
            alt="Cardimagecap"
            className={classes.round}
          />
          <Row style={{ paddingTop: "1rem" }}>
            <Col xs={10}>
              <CardTitle
                className={classes.customLabel}
                style={{
                  fontFamily: "Prompt",
                  paddingTop: 15,
                  paddingBottom: 0,
                }}
              >
                {ListItem.cluster_name}
              </CardTitle>
            </Col>
            <Col xs={2} style={{ textAlign: "left", marginTop: "1rem" }}>
              <FaChevronCircleDown size={30} className="arrow-down" />
            </Col>
          </Row>
        </div>
        {/* <p>รายละเอียดความสอดคล้อง: {ListItem.cluster_detail}</p> */}
      </Col>
    );
  });

  return (
    <>
      {loading ? (
        <div className="demo"></div>
      ) : (
        <Card className="card-goal" style={{ paddingBottom: "0" }}>
          <CardTitle tag="h5" className="card-title-goal">
            ประเภทเป้าหมายเพื่อการพัฒนา
          </CardTitle>
          <CardBody style={{ fontFamily: "Prompt" }}>
            <Tabs
              defaultActiveKey="RMUTICLUSTER"
              id="uncontrolled-tab-example"
              className="mb-3"
            >
              <Tab
                eventKey="RMUTICLUSTER"
                title="RMUTI CLUSTER"
                tabClassName="RMUTICLUSTER"
              >
                <div className="tab-table">
                  {cluster.length ? (
                    <Row className="justify-content-center">{clusterEl}</Row>
                  ) : (
                    <h6>ไม่พบข้อมูล</h6>
                  )}
                </div>
                <div ref={detailRef}>
                  {clusterDetail.cluster_name ? (
                    <Col sm={12}>
                      <div className="goal-detail">
                        <h5 style={{ textAlign: "left" }}>
                          <b> {clusterDetail.cluster_name}</b>{" "}
                        </h5>

                        <hr />
                        {clusterDetail.cluster_detail ? (
                          <div>
                            {clusterDetail.cluster_detail.map((data, index) => (
                              <Row key={index} className="goal-row-detail">
                                <Col sm={12}>
                                  <p style={{ textAlign: "left" }}>
                                    <b>รายละเอียดความสอดคล้อง : </b>
                                    {data.detail}
                                  </p>
                                </Col>
                                <hr style={{ height: "0.5px" }} />

                                {data.images.length ? (
                                  <>
                                    {data.images.length > 1 ? (
                                      <Col sm={12} md={12} lg={6} xl={6}>
                                        <ImageGallery
                                          showThumbnails={true}
                                          items={data.img_side}
                                        />
                                      </Col>
                                    ) : (
                                      <Col
                                        sm={12}
                                        md={12}
                                        lg={6}
                                        xl={6}
                                        className="div-image-knowledge"
                                      >
                                        {data.images.map((data_img, index) => (
                                          <img
                                            key={index}
                                            style={{ width: "100%" }}
                                            src={data_img.goal_img_path}
                                            alt="goal_image"
                                          />
                                        ))}
                                      </Col>
                                    )}
                                  </>
                                ) : (
                                  <Col sm={12} md={12} lg={6} xl={6}>
                                    <div className="title-center">
                                      <h3
                                        style={{
                                          color: "#000",
                                          fontFamily: "Prompt",
                                        }}
                                      >
                                        ไม่มีรูปภาพ
                                      </h3>
                                    </div>
                                  </Col>
                                )}

                                <Youtubeshow
                                  url_video={
                                    data.links.length
                                      ? data.links[0]?.goal_link_path
                                      : ""
                                  }
                                  xs={12}
                                  sm={12}
                                  md={12}
                                  lg={6}
                                  paddingTop={"0"}
                                  className={"goal-video"}
                                />
                              </Row>
                            ))}
                          </div>
                        ) : null}
                      </div>
                    </Col>
                  ) : null}
                </div>
              </Tab>
              <Tab eventKey="SDG" title="SDGs" tabClassName="SDG">
                <div className="tab-table">
                  {sdg.length ? (
                    <Row className="justify-content-center">{sdgEl}</Row>
                  ) : (
                    <h6>ไม่พบข้อมูล</h6>
                  )}
                </div>
                <div ref={detailSdgsRef}>
                  {sdgDetail.sdgs_name ? (
                    <Col sm={12}>
                      <div className="goal-detail">
                        <h5 style={{ textAlign: "left" }}>
                          <b> {sdgDetail.sdgs_name}</b>{" "}
                        </h5>

                        <hr />
                        {sdgDetail.sdgs_detail ? (
                          <div>
                            {sdgDetail.sdgs_detail.map((data, index) => (
                              <Row key={index} className="goal-row-detail">
                                <Col sm={12}>
                                  <p style={{ textAlign: "left" }}>
                                    <b>รายละเอียดความสอดคล้อง : </b>
                                    {data.detail}
                                  </p>
                                </Col>
                                <hr style={{ height: "0.5px" }} />

                                {data.images.length ? (
                                  <>
                                    {data.images.length > 1 ? (
                                      <Col sm={12} md={12} lg={6} xl={6}>
                                        <ImageGallery
                                          showThumbnails={true}
                                          items={data.img_side}
                                        />
                                      </Col>
                                    ) : (
                                      <Col
                                        sm={12}
                                        md={12}
                                        lg={6}
                                        xl={6}
                                        className="div-image-knowledge"
                                      >
                                        {data.images.map((data_img, index) => (
                                          <img
                                            key={index}
                                            style={{ width: "100%" }}
                                            src={data_img.goal_img_path}
                                            alt="goal_image"
                                          />
                                        ))}
                                      </Col>
                                    )}
                                  </>
                                ) : (
                                  <Col sm={12} md={12} lg={6} xl={6}>
                                    <div className="title-center">
                                      <h3
                                        style={{
                                          color: "#000",
                                          fontFamily: "Prompt",
                                        }}
                                      >
                                        ไม่มีรูปภาพ
                                      </h3>
                                    </div>
                                  </Col>
                                )}

                                <Youtubeshow
                                  url_video={
                                    data.links.length
                                      ? data.links[0]?.goal_link_path
                                      : ""
                                  }
                                  xs={12}
                                  sm={12}
                                  md={12}
                                  lg={6}
                                  paddingTop={"0"}
                                  className={"goal-video"}
                                />
                              </Row>
                            ))}
                          </div>
                        ) : null}
                      </div>
                    </Col>
                  ) : null}
                </div>
              </Tab>
              <Tab eventKey="BCG" title="BCG" tabClassName="BCG">
                <div className="tab-table">
                  {bcg.length ? (
                    <Row className="justify-content-center">{bcgEl}</Row>
                  ) : (
                    <h6>ไม่พบข้อมูล</h6>
                  )}
                </div>
                <div ref={detailBcgRef}>
                  {bcgDetail.bcg_name ? (
                    <Col sm={12}>
                      <div className="goal-detail">
                        <h5 style={{ textAlign: "left" }}>
                          <b> {bcgDetail.bcg_name}</b>{" "}
                        </h5>

                        <hr />
                        {bcgDetail.bcg_detail ? (
                          <div>
                            {bcgDetail.bcg_detail.map((data, index) => (
                              <Row key={index} className="goal-row-detail">
                                <Col sm={12}>
                                  <p style={{ textAlign: "left" }}>
                                    <b>รายละเอียดความสอดคล้อง : </b>
                                    {data.detail}
                                  </p>
                                </Col>
                                <hr style={{ height: "0.5px" }} />

                                {data.images.length ? (
                                  <>
                                    {data.images.length > 1 ? (
                                      <Col sm={12} md={12} lg={6} xl={6}>
                                        <ImageGallery
                                          showThumbnails={true}
                                          items={data.img_side}
                                        />
                                      </Col>
                                    ) : (
                                      <Col
                                        sm={12}
                                        md={12}
                                        lg={6}
                                        xl={6}
                                        className="div-image-knowledge"
                                      >
                                        {data.images.map((data_img, index) => (
                                          <img
                                            key={index}
                                            style={{ width: "100%" }}
                                            src={data_img.goal_img_path}
                                            alt="goal_image"
                                          />
                                        ))}
                                      </Col>
                                    )}
                                  </>
                                ) : (
                                  <Col sm={12} md={12} lg={6} xl={6}>
                                    <div className="title-center">
                                      <h3
                                        style={{
                                          color: "#000",
                                          fontFamily: "Prompt",
                                        }}
                                      >
                                        ไม่มีรูปภาพ
                                      </h3>
                                    </div>
                                  </Col>
                                )}
                                <Youtubeshow
                                  url_video={
                                    data.links.length
                                      ? data.links[0]?.goal_link_path
                                      : ""
                                  }
                                  xs={12}
                                  sm={12}
                                  md={12}
                                  lg={6}
                                  paddingTop={"0"}
                                  className={"goal-video"}
                                />
                              </Row>
                            ))}
                          </div>
                        ) : null}
                      </div>
                    </Col>
                  ) : null}
                </div>
              </Tab>
              <Tab eventKey="SCURVE" title="10S CURVE" tabClassName="SCURVE">
                <div className="tab-table">
                  {curve.length ? (
                    <Row className="justify-content-center">{curveEl}</Row>
                  ) : (
                    <h6>ไม่พบข้อมูล</h6>
                  )}
                </div>
                <div ref={detailCurveRef}>
                  {curveDetail.curve_name ? (
                    <Col sm={12}>
                      <div className="goal-detail">
                        <h5 style={{ textAlign: "left" }}>
                          <b> {curveDetail.curve_name}</b>{" "}
                        </h5>

                        <hr />
                        {curveDetail.curve_detail ? (
                          <div>
                            {curveDetail.curve_detail.map((data, index) => (
                              <Row key={index} className="goal-row-detail">
                                <Col sm={12}>
                                  <p style={{ textAlign: "left" }}>
                                    <b>รายละเอียดความสอดคล้อง : </b>
                                    {data.detail}
                                  </p>
                                </Col>
                                <hr style={{ height: "0.5px" }} />

                                {data.images.length ? (
                                  <>
                                    {data.images.length > 1 ? (
                                      <Col sm={12} md={12} lg={6} xl={6}>
                                        <ImageGallery
                                          showThumbnails={true}
                                          items={data.img_side}
                                        />
                                      </Col>
                                    ) : (
                                      <Col
                                        sm={12}
                                        md={12}
                                        lg={6}
                                        xl={6}
                                        className="div-image-knowledge"
                                      >
                                        {data.images.map((data_img, index) => (
                                          <img
                                            key={index}
                                            style={{ width: "100%" }}
                                            src={data_img.goal_img_path}
                                            alt="goal_image"
                                          />
                                        ))}
                                      </Col>
                                    )}
                                  </>
                                ) : (
                                  <Col sm={12} md={12} lg={6} xl={6}>
                                    <div className="title-center">
                                      <h3
                                        style={{
                                          color: "#000",
                                          fontFamily: "Prompt",
                                        }}
                                      >
                                        ไม่มีรูปภาพ
                                      </h3>
                                    </div>
                                  </Col>
                                )}

                                <Youtubeshow
                                  url_video={
                                    data.links.length
                                      ? data.links[0]?.goal_link_path
                                      : ""
                                  }
                                  xs={12}
                                  sm={12}
                                  md={12}
                                  lg={6}
                                  paddingTop={"0"}
                                  className={"goal-video"}
                                />
                              </Row>
                            ))}
                          </div>
                        ) : null}
                      </div>
                    </Col>
                  ) : null}
                </div>
              </Tab>
            </Tabs>
          </CardBody>
        </Card>
      )}
    </>
  );
};

export default GoalsDetail;
