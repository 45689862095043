import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { Row, Col } from "reactstrap";
// import { useTranslation } from "react-i18next";
import no_img_product from "../../images/no_img_product.png";
import { FaChevronCircleDown } from "react-icons/fa";

// import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import Youtubeshow from "../Detail/Youtubeshow";
import Imageshow from "../Detail/Imageshow";
import { apiUrl } from "../../config/api.json";

const NewKnowledgeDetail = (props) => {
  // const { t } = useTranslation();
  const [loading, setloading] = useState(true);
  const [new_knowledge, setnew_knowledge] = useState([]);
  const [knowledgeDetail, setknowledgeDetail] = useState([]);
  const divdetailKn = useRef();

  const getParamsId = () => {
    const windowUrl = window.location.search;
    const params = new URLSearchParams(windowUrl);
    const project_id = params.get("project_id");
    if (!project_id) {
      const project_id = params.get("concep_id");
      return project_id;
    }
    return project_id;
  };
  const idencrypt = getParamsId();
  const getnewknowledge = async (id) => {
    try {
      const response = await axios.get(
        `${apiUrl}/api/get/concept_proposal/new-knowledge/${id}`
      );
      setnew_knowledge(response.data);
      setloading(false);
    } catch (err) {
      setloading(false);
    }
  };

  var id;
  useEffect(() => {
    if (idencrypt === null || idencrypt === "") {
      props.history.push({
        pathname: "/SearchProject",
      });
    } else {
      id = atob(idencrypt);
    }
    getnewknowledge(id);
  }, []);

  const onClickKn = (index) => {
    // console.log(index);
    setknowledgeDetail([new_knowledge[index]]);
    divdetailKn.current.scrollIntoView({ behavior: "smooth" });
  };

  const knowledgediv = knowledgeDetail.map((data, index) => {
    const imagesKn = [];
    if (data.outcome_knowledge_image) {
      imagesKn.push({
        original: data.outcome_knowledge_image,
        thumbnail: data.outcome_knowledge_image,
      });
    }
    data.images.forEach(function (data) {
      if (data.outcome_knowledge_image) {
        imagesKn.push({
          original: data.outcome_knowledge_image,
          thumbnail: data.outcome_knowledge_image,
        });
      }
    });
    return (
      <div className="blog-card newkn-div" key={index}>
        <Row
          key={index}
          className="body-expertise"
          style={{ backgroundColor: "#fff" }}
        >
          <Col sm={12} style={{ marginBottom: "1rem" }}>
            <h3 style={{ textAlign: "left" }}>{data.outcome_knowledge_name}</h3>
            <hr />
          </Col>

          <Imageshow
            image={data.outcome_knowledge_image}
            images={imagesKn}
            imageshow={imagesKn}
            lg={6}
          />

          <Youtubeshow
            url_video={
              data.outcome_knowledge_video ? data.outcome_knowledge_video : ""
            }
            xs={12}
            sm={12}
            md={12}
            lg={6}
            paddingTop={"0"}
          />

          <Col xs={12} sm={12} style={{ paddingTop: "5rem" }}>
            <hr />
            <div className="newkn-detail">
              <p>
                <b>รายละเอียด : </b>{" "}
                {data.outcome_knowledge_detail.replace(
                  /<[^>]+>|&nbsp;|&quot;/g,
                  ""
                )}
              </p>
            </div>
          </Col>
        </Row>
      </div>
    );
  });
  return (
    <div className="div-knowledge-detail">
      {loading ? (
        <div className="demo"></div>
      ) : (
        <Row className="div-detail">
          {new_knowledge.length ? (
            <>
              <h5 style={{ paddingTop: "1rem", textAlign: "left" }}>
                ความรู้ใหม่ทั้งหมด {new_knowledge.length} รายการ
              </h5>
              <div className="line-bottom" style={{ margin: "1rem 0rem" }} />
              <Row>
                {new_knowledge.map((data, index) => (
                  <Col xs={12} sm={12} md={6} lg={6} xl={4} key={index}>
                    <div
                      className="card-output"
                      style={{ backgroundColor: "#fff" }}
                      onClick={() => onClickKn(index)}
                    >
                      <img
                        className="img-output"
                        src={
                          data.outcome_knowledge_image
                            ? data.outcome_knowledge_image
                            : no_img_product
                        }
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null; // prevents looping
                          currentTarget.src = no_img_product;
                        }}
                        alt="outcome_knowledge_image"
                      />
                      <div className="output-descreption">
                        <Row>
                          <Col xs={10}>
                            <h6>
                              <b>{data.outcome_knowledge_name}</b>
                            </h6>
                          </Col>
                          <Col xs={2} style={{ textAlign: "left" }}>
                            <FaChevronCircleDown
                              size={30}
                              className="arrow-down"
                            />
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </Col>
                ))}
              </Row>
              <div className="line-bottom" style={{ margin: "1rem 0rem" }} />
              <div ref={divdetailKn} style={{ marginTop: "2rem" }}>
                {knowledgediv}
              </div>
            </>
          ) : (
            <h6 style={{ textAlign: "center" }}>ไม่พบข้อมูล</h6>
          )}
        </Row>
      )}
    </div>
  );
};

export default NewKnowledgeDetail;
