/* eslint-disable jsx-a11y/alt-text */
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Timeline from "@material-ui/lab/Timeline";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineOppositeContent from "@material-ui/lab/TimelineOppositeContent";
import TimelineContent from "@material-ui/lab/TimelineContent";
import TimelineDot from "@material-ui/lab/TimelineDot";
import { GrSolaris } from "react-icons/gr";
import no_img_product from "../../images/no_img_product.png";
import { FaChevronCircleDown } from "react-icons/fa";

import { Row, Col } from "react-bootstrap";

// import CardContent from "@material-ui/core/CardContent";
// import { Card } from "@material-ui/core";
import { useEffect, useState, useRef } from "react";
import axios from "axios";
import token from "../../config/token.json";
import { apiUrl } from "../../config/api.json";
function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-controls={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography style={{ fontFamily: "Prompt" }}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `scrollable-force-tab-${index}`,
    "aria-controls": `scrollable-force-tabpanel-${index}`,
  };
}

export default function ExpertisePage(props) {
  const { classes, user } = props;

  const getParamsId = () => {
    const windowUrl = window.location.search;
    const params = new URLSearchParams(windowUrl);
    return params.get("user_idcard");
  };
  const idencrypt = getParamsId();
  const [valuePage1, setValuePage1] = useState(0);
  const handleChangePage1 = (event, newValue) => {
    setValuePage1(newValue);
  };
  const [award, setaward] = useState([]);
  const [coaward, setcoaward] = useState([]);
  const [certificate, setcertificate] = useState([]);
  const [cocertificate, setcocertificate] = useState([]);
  const [professional, setprofessional] = useState([]);
  const [expertise, setexpertise] = useState([]);
  const [consulting, setconsulting] = useState([]);
  const [currentPageAw, setCurrentPageAw] = useState([]);
  const [currentPageCer, setCurrentPageCer] = useState([]);
  const divdetailAw = useRef();
  const divdetailCer = useRef();
  let id = idencrypt;
  // console.log('id: ', id);

  useEffect(() => {
    axios
      .get(`${apiUrl}/api/get/consulting_experience/${id}`, {
        headers: {
          "content-type": "application/json",
          "x-access-token": token.accesstoken,
        },
      })
      .then((result) => {
        // console.log(result.data);
        setconsulting(result.data);
      });

    axios
      .get(`${apiUrl}/api/get/professional_license/${id}`, {
        headers: {
          "content-type": "application/json",
          "x-access-token": token.accesstoken,
        },
      })
      .then((result) => {
        // console.log(result.data);
        setprofessional(result.data);
      });

    axios
      .get(`${apiUrl}/api/get/us-award/${id}`, {
        headers: {
          "content-type": "application/json",
          "x-access-token": token.accesstoken,
        },
      })
      .then((result) => {
        // console.log('result AWARD', result.data);
        setaward(result.data);
      });

    axios
      .get(`${apiUrl}/api/get/us-expertise/${id}`, {
        headers: {
          "content-type": "application/json",
          "x-access-token": token.accesstoken,
        },
      })
      .then((result) => {
        // console.log(result.data);
        setexpertise(result.data);
      });

    axios
      .get(`${apiUrl}/api/get/bb-user/award/${id}`, {
        headers: {
          "content-type": "application/json",
          "x-access-token": token.accesstoken,
        },
      })
      .then((result) => {
        // console.log(result.data);
        setcoaward(result.data);
      });

    axios
      .get(`${apiUrl}/api/get/us-certificate/${id}`, {
        headers: {
          "content-type": "application/json",
          "x-access-token": token.accesstoken,
        },
      })
      .then((result) => {
        // console.log('certificate', result.data);
        setcertificate(result.data);
      });

    axios
      .get(`${apiUrl}/api/get/bb-user/certificate/${id}`, {
        headers: {
          "content-type": "application/json",
          "x-access-token": token.accesstoken,
        },
      })
      .then((result) => {
        // console.log('co-certificate', result.data);
        setcocertificate(result.data);
      });
  }, []);

  const awarddiv = currentPageAw.map((listValue, index) => {
    return (
      <div
        className="blog-card "
        key={index}
        style={{ padding: "1.5rem", marginBottom: "0" }}
      >
        <Row>
          <Col sm={12}>
            <div className="description">
              <h1>{listValue.award_name_th}</h1>
              <h2>{listValue.award_name_en}</h2>
              <hr style={{ padding: "0 3rem" }} />
            </div>
          </Col>
          <Col sm={12} md={6}>
            <img
              className="img-radius"
              src={`https://researcher.kims-rmuti.com/file-upload/award-upload/${listValue.award_images}`}
              // onError={(e) => (
              //   (e.target.onerror = null), (e.target.src = no_img_product)
              // )}
              style={{ maxWidth: "100%" }}
            />
          </Col>
          <Col sm={12} md={6}>
            <div className="description">
              <h5>{listValue.award_Portfolio_th}</h5>
              <p>
                <b>ผู้มอบรางวัล : </b> {listValue.award_giver}
              </p>
              <p>
                <b>รายละเอียด : </b> {listValue.award_detail}
              </p>
              <p>
                <b>วันที่รับรางวัล : </b>{" "}
                {listValue.award_date
                  ? new Date(listValue.award_date).toLocaleDateString("th-TH", {
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                      weekday: "long",
                    })
                  : ""}
              </p>
            </div>
          </Col>
        </Row>
      </div>
    );
  });

  const certificatediv = currentPageCer.map((listValue, index) => {
    return (
      <div
        className="blog-card "
        key={index}
        style={{ padding: "1.5rem", marginBottom: "0" }}
      >
        <Row>
          <Col sm={12}>
            <div className="description">
              <h1>{listValue.certificate_name}</h1>
              <h2>{listValue.certificate_name}</h2>
              <hr style={{ padding: "0 3rem" }} />
            </div>
          </Col>
          <Col sm={12} md={6}>
            <img
              className="img-radius"
              src={`https://researcher.kims-rmuti.com/file-upload/certificate-upload/${listValue.certificate_images}`}
              // onError={(e) => (
              //   (e.target.onerror = null), (e.target.src = no_img_product)
              // )}
              style={{ maxWidth: "100%" }}
            />
          </Col>
          <Col sm={12} md={6}>
            <div className="description">
              <h5>{listValue.certificate_name}</h5>
              <p>
                <b>ผู้มอบรางวัล : </b> {}
              </p>
              <p>
                <b>รายละเอียด : </b> {listValue.certificate_detail}
              </p>
              <p>
                <b>วันที่รับรางวัล : </b>
                {listValue.certificate_end}
              </p>
            </div>
          </Col>
        </Row>
      </div>
    );
  });

  const onClickAw = (index) => {
    setCurrentPageAw([award[index]]);
    divdetailAw.current.scrollIntoView({ behavior: "smooth" });
  };

  const onClickCer = (index) => {
    setCurrentPageCer([certificate[index]]);
    divdetailCer.current.scrollIntoView({ behavior: "smooth" });
  };
  return (
    <div>
      <AppBar
        position="static"
        color="default"
        className={classes.appbar}
        style={{ borderRadius: "10px 10px 0 0", paddingTop: "2rem" }}
      >
        <Tabs
          value={valuePage1}
          onChange={handleChangePage1}
          variant="scrollable"
          scrollButtons="on"
          TabIndicatorProps={{
            style: { background: "orange" },
          }}
          textColor="secondary"
          aria-label="scrollable tabs menu"
        >
          <Tab
            label="ความเชี่ยวชาญ"
            className={classes.customLabel}
            {...a11yProps(0)}
          />
          <Tab
            label="รางวัล"
            className={classes.customLabel}
            {...a11yProps(1)}
          />
          <Tab
            label="ใบประกาศ"
            className={classes.customLabel}
            {...a11yProps(2)}
          />
          {user && user.length > 0 && user[0].group_id === 1 && (
            <Tab
              label="ใบประกอบวิชาชีพ"
              className={classes.customLabel}
              {...a11yProps(3)}
            />
          )}
          {user && user.length > 0 && user[0].group_id === 1 && (
            <Tab
              label="ประสบการณ์การเป็นที่ปรึกษา"
              className={classes.customLabel}
              {...a11yProps(4)}
            />
          )}
        </Tabs>
      </AppBar>

      <div className="div-expertise">
        <TabPanel value={valuePage1} index={0} style={{ padding: "0 1rem" }}>
          {expertise.length ? (
            <>
              <h5 style={{ paddingTop: "1rem", textAlign: "left" }}>
                ความเชี่ยวชาญทั้งหมด {expertise.length} รายการ
              </h5>
              <div className="line-bottom" style={{ margin: "1rem 1rem" }} />
              <Row>
                {expertise.map((listValue, index) => {
                  return (
                    <Col xs={12} key={index} className="body-expertise">
                      <div>
                        <h6>{listValue.certificate_agency}</h6>
                        <h6>{listValue.expertise_name}</h6>
                        <h6>{listValue.expertise_type_name}</h6>
                        {user && user.length > 0 && user[0].group_id === 1 && (
                          <h6
                            style={{ fontFamily: "Prompt" }}
                            color="textSecondary"
                          >
                            {" "}
                            ISCED {listValue.expertise_isced1}
                          </h6>
                        )}
                      </div>
                    </Col>
                  );
                })}
              </Row>
            </>
          ) : (
            <h6 style={{ textAlign: "center" }}>ไม่พบข้อมูล</h6>
          )}
        </TabPanel>

        <TabPanel
          value={valuePage1}
          index={1}
          style={{ padding: "0 1rem" }}
          className="re-expertise-detail"
        >
          {award.length ? (
            <>
              <h5 style={{ paddingTop: "1rem", textAlign: "left" }}>
                รางวัลทั้งหมด {award.length} รายการ
              </h5>
              <hr />
              <Row style={{ paddingRight: "2rem" }}>
                {award.map((data, index) => (
                  <Col xs={12} sm={12} md={6} lg={6} xl={4} key={index}>
                    <div
                      className="card-output"
                      style={{ backgroundColor: "#fff" }}
                      onClick={() => onClickAw(index)}
                    >
                      <img
                        className="img-output"
                        src={
                          `https://researcher.kims-rmuti.com/file-upload/award-upload/${data.award_images}`
                            ? `https://researcher.kims-rmuti.com/file-upload/award-upload/${data.award_images}`
                            : no_img_product
                        }
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null; // prevents looping
                          currentTarget.src = no_img_product;
                        }}
                        alt="output_image"
                      />
                      <div className="output-descreption">
                        <Row>
                          <Col xs={10}>
                            <h6>
                              <b>{data.award_name_th}</b>
                            </h6>
                          </Col>
                          <Col xs={2} style={{ textAlign: "left" }}>
                            <FaChevronCircleDown
                              size={30}
                              className="arrow-down"
                            />
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </Col>
                ))}
              </Row>
              <div className="line-bottom" style={{ margin: "2rem 1rem" }} />
              <div ref={divdetailAw} style={{ marginTop: "2rem" }}>
                {awarddiv}
              </div>
            </>
          ) : (
            <h6 style={{ textAlign: "center" }}>ไม่พบข้อมูล</h6>
          )}
        </TabPanel>
        <TabPanel
          value={valuePage1}
          index={2}
          style={{ padding: "0 1rem" }}
          className="re-expertise-detail"
        >
          {certificate.length ? (
            <>
              <h5 style={{ paddingTop: "1rem", textAlign: "left" }}>
                ใบประกาศทั้งหมด {certificate.length} รายการ
              </h5>
              <hr />
              <Row style={{ paddingRight: "2rem" }}>
                {certificate.map((data, index) => (
                  <Col xs={12} sm={12} md={6} lg={6} xl={4} key={index}>
                    <div
                      className="card-output"
                      style={{ backgroundColor: "#fff" }}
                      onClick={() => onClickCer(index)}
                    >
                      <img
                        className="img-output"
                        src={
                          `https://researcher.kims-rmuti.com/file-upload/certificate-upload/${data.certificate_images}`
                            ? `https://researcher.kims-rmuti.com/file-upload/certificate-upload/${data.certificate_images}`
                            : no_img_product
                        }
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null; // prevents looping
                          currentTarget.src = no_img_product;
                        }}
                        alt="output_image"
                      />
                      <div className="output-descreption">
                        <Row>
                          <Col xs={10}>
                            <h6>
                              <b>{data.certificate_name}</b>
                            </h6>
                          </Col>
                          <Col xs={2} style={{ textAlign: "left" }}>
                            <FaChevronCircleDown
                              size={30}
                              className="arrow-down"
                            />
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </Col>
                ))}
                {cocertificate.map((data, index) => (
                  <Col xs={12} sm={12} md={6} lg={6} xl={4} key={index}>
                    <div
                      className="card-output"
                      style={{ backgroundColor: "#fff" }}
                      onClick={() => onClickAw(index)}
                    >
                      <img
                        className="img-output"
                        src={
                          `https://researcher.kims-rmuti.com/file-upload/co_certificate-upload/${data.co_certificate_image}`
                            ? `https://researcher.kims-rmuti.com/file-upload/co_certificate-upload/${data.co_certificate_image}`
                            : no_img_product
                        }
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null; // prevents looping
                          currentTarget.src = no_img_product;
                        }}
                        alt="output_image"
                      />
                      <div className="output-descreption">
                        <Row>
                          <Col xs={10}>
                            <h6>
                              <b>{data.co_certificate_name_th}</b>
                            </h6>
                          </Col>
                          <Col xs={2} style={{ textAlign: "left" }}>
                            <FaChevronCircleDown
                              size={30}
                              className="arrow-down"
                            />
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </Col>
                ))}
              </Row>
              <div className="line-bottom" style={{ margin: "2rem 1rem" }} />
              <div ref={divdetailCer} style={{ marginTop: "2rem" }}>
                {certificatediv}
              </div>
            </>
          ) : (
            <h6 style={{ textAlign: "center" }}>ไม่พบข้อมูล</h6>
          )}
        </TabPanel>
        <TabPanel value={valuePage1} index={3} style={{ padding: "0 1rem" }}>
          {professional.length ? (
            <div>
              <Timeline align="alternate" className="rc-body3">
                {professional.map((listValue, index) => {
                  return (
                    <TimelineItem>
                      <TimelineSeparator>
                        <TimelineDot color="secondary" variant="outlined">
                          <GrSolaris />
                        </TimelineDot>
                        <TimelineConnector />
                      </TimelineSeparator>
                      <TimelineContent align="left">
                        <Paper elevation={5}>
                          <Typography
                            style={{
                              fontFamily: "Prompt",
                            }}
                            align="left"
                          >
                            ชื่อใบประกอบวิชาชีพ : {listValue.license_name}
                          </Typography>
                          <Typography
                            style={{
                              fontFamily: "Prompt",
                            }}
                            align="left"
                          >
                            เลขที่ใบประกอบวิชาชีพ : {listValue.license_number}
                          </Typography>
                          <Typography
                            style={{
                              fontFamily: "Prompt",
                            }}
                            align="left"
                          >
                            วันหมดอายุใบประกอบวิชาชีพ :
                            {listValue.license_expiration}
                          </Typography>
                        </Paper>
                      </TimelineContent>
                    </TimelineItem>
                  );
                })}
                <TimelineItem>
                  <TimelineDot variant="outlined" />
                  <TimelineContent></TimelineContent>
                </TimelineItem>
              </Timeline>
            </div>
          ) : (
            <h6 style={{ textAlign: "center" }}>ไม่พบข้อมูล</h6>
          )}
        </TabPanel>
        <TabPanel value={valuePage1} index={4} style={{ padding: "0 1rem" }}>
          {consulting.length ? (
            <div>
              <Timeline align="alternate" className="rc-body3">
                {consulting.map((listValue, index) => {
                  return (
                    <TimelineItem>
                      <TimelineOppositeContent align="left">
                        <Typography
                          style={{ fontFamily: "Prompt" }}
                          variant="body2"
                          padding="1px 1px 1px 1px"
                        >
                          {listValue.consulting_experience_start
                            ? new Date(
                                listValue.consulting_experience_start
                              ).toLocaleDateString("th-TH", {
                                year: "numeric",
                                month: "long",
                                day: "numeric",
                              })
                            : ""}
                          -
                          {listValue.consulting_experience_end
                            ? new Date(
                                listValue.consulting_experience_end
                              ).toLocaleDateString("th-TH", {
                                year: "numeric",
                                month: "long",
                                day: "numeric",
                              })
                            : ""}
                        </Typography>
                      </TimelineOppositeContent>
                      <TimelineSeparator>
                        <TimelineDot color="secondary" variant="outlined">
                          <GrSolaris />
                        </TimelineDot>
                        <TimelineConnector />
                      </TimelineSeparator>
                      <TimelineContent align="left">
                        <Paper elevation={5}>
                          <Typography
                            style={{
                              fontFamily: "Prompt",
                            }}
                            align="left"
                          >
                            ชื่อชื่อโครงการ/ผลงาน :{" "}
                            {listValue.consulting_experience_name}
                          </Typography>
                          <Typography
                            style={{
                              fontFamily: "Prompt",
                            }}
                            align="left"
                          >
                            ตำแหน่งในโครงการ :{" "}
                            {listValue.consulting_experience_position}
                          </Typography>
                        </Paper>
                      </TimelineContent>
                    </TimelineItem>
                  );
                })}
                <TimelineItem>
                  <TimelineDot variant="outlined" />
                  <TimelineContent></TimelineContent>
                </TimelineItem>
              </Timeline>
            </div>
          ) : (
            <h6 style={{ textAlign: "center" }}>ไม่พบข้อมูล</h6>
          )}
        </TabPanel>
      </div>
    </div>
  );
}
