import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { useState, useEffect } from "react";
import axios from "axios";

import Button from "@material-ui/core/Button";

import "react-awesome-slider/dist/styles.css";

import {
  Col,
  // Container,
  Row,
} from "reactstrap";
import "../index.css";
import {
  BiMapPin,
  BiMedal,
  BiNews,
  BiGroup,
  BiCertification,
} from "react-icons/bi";
import { FaRegIdCard } from "react-icons/fa";
import { HiOutlineLightBulb, HiOutlineAcademicCap } from "react-icons/hi";

import { useTranslation } from "react-i18next";

import Accordion from "react-bootstrap/Accordion";

import "./Css/researcher.scss";

import { Route, Switch, Link } from "react-router-dom";

import ResearcherLocationDetail from "../Components/ResearcherPage/ResearcherLocationDetail";
import ResearcherExpertise from "../Components/ResearcherPage/ResearcherExpertise";
import ResearcherProperty from "../Components/ResearcherPage/ResearcherProperty";
import ResearcherPublication from "../Components/ResearcherPage/ResearcherPublication";
import ResearcherInnovation from "../Components/ResearcherPage/ResearcherInnovation";
import ResearcherGroup from "../Components/ResearcherPage/ResearcherGroup";
import ResearcherDetail from "../Components/ResearcherPage/ResearcherDetail";
import ResearcherCourse from "../Components/ResearcherPage/ResearcherCourse";
import token from "../config/token.json";
import { apiUrl } from "../config/api.json";

const useStyles = makeStyles((theme) => ({
  appbar: {
    alignItems: "center",
    backgroundColor: "#fff",
    boxShadow: "none",
  },
  root: {
    flexGrow: 1,
    width: "100%",
    // backgroundColor: theme.palette.background.paper,
  },

  customLabel: {
    fontFamily: "Prompt",
    fontWeight: "bold",
    color: "black",
  },

  round: {
    borderRadius: "50%",
    border: "4px solid rgb(223, 223, 223)",
    width: "100%",
    boxShadow:
      "rgba(0, 0, 0, 0.293) 0px 19px 38px, rgba(0, 0, 0, 0.129) 0px 15px 12px",
    [theme.breakpoints.up("sm")]: {
      width: "65%",
    },
  },

  underlineCol: {
    borderBottom: "2px solid rgb(211, 211, 211)",
    paddingTop: 12,
  },

  tabpanel: {
    marginLeft: "auto",
    marginRight: "auto",
  },

  headerLabel: {
    position: "relative",
    fontFamily: "Prompt",
    fontWeight: "bold",
    color: "black",
    zIndex: 1,

    textAlign: "center",

    "&::after": {
      position: "absolute",
      content: '""',
      top: 10,
      borderBottom: "3px solid black",
      width: 120,
      left: "50%",
      marginTop: 10,
      marginLeft: "-60px",
      bottom: 0,
    },
  },

  cardlayout: {
    marginTop: 20,
    boxShadow:
      "rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px",
    [theme.breakpoints.up("sm")]: {
      marginTop: 0,
    },
  },

  tabsBg: {
    background:
      "linear-gradient(90deg,rgba(240, 99, 0, 1) 0%,rgba(255, 115, 0, 1) 38%,rgba(254, 148, 0, 1) 100%)",
  },
}));

const useTimeline = makeStyles((theme) => ({
  paper: {
    padding: "3px ",
  },
  secondaryTail: {
    backgroundColor: theme.palette.secondary.main,
  },
}));

function Researcher(props) {
  const { t } = useTranslation();
  const [loading_profile, setLoading_profile] = useState(true);
  const [user, setuser] = useState([]);
  const [course, setcourse] = useState([]);
  const classTimeline = useTimeline();

  const getParamsId = () => {
    const windowUrl = window.location.search;
    const params = new URLSearchParams(windowUrl);
    return params.get("user_idcard");
  };
  const idencrypt = getParamsId();
  const courseDetail = async (id) => {
    const response = await axios.get(`${apiUrl}/api/get/course?id=${id}`, {
      headers: {
        "content-type": "application/json",
        "x-access-token": token.accesstoken,
      },
    });
    return response.data;
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    if (idencrypt === null || idencrypt === "") {
      props.history.push({
        pathname: "/SearchResearcher",
      });
    }
    axios
      .get(`${apiUrl}/api/get/bb-user/${idencrypt}`, {
        headers: {
          "content-type": "application/json",
          "x-access-token": token.accesstoken,
        },
      })
      .then(async (result) => {
        // console.log("test", result.data[0]);
        setuser(result.data);
        try {
          const courseData = await courseDetail(idencrypt);
          setcourse(courseData);
        } catch (error) {
          console.log(error);
        }
        setLoading_profile(false);
      });
  }, []);

  const classes = useStyles();
  return (
    <div className="body-detail">
      <div className="body-coresearcher">
        <div className={`${loading_profile && "demo"}`}></div>
        {user.map((listValue) => {
          return (
            <Row>
              <Col
                xs={12}
                sm={4}
                md={4}
                style={{ textAlign: "right" }}
                className="img-profile-header"
              >
                <img
                  className="profile-image"
                  src={listValue.user_image_user}
                  alt="img-user"
                />
              </Col>
              <Col xs={12} sm={8} md={8}>
                <section className="profile-info" style={{ marginTop: "50px" }}>
                  <h4>
                    <strong>
                      {listValue.prefix_name_th}
                      {listValue.user_first_name_th} {"  "}
                      {listValue.user_last_name_th}
                    </strong>
                  </h4>
                  {/* <h1 className="first-name">Angela</h1>
                  <h1 className="second-name">Yun He</h1> */}
                  <p>
                    {t("researcher.carddetail.text1")}:{" "}
                    {listValue.user_academic}
                  </p>
                  <p>
                    {t("researcher.carddetail.text2")}:{" "}
                    {
                      listValue.university_name
                      // != university.map((v) => v.organization_code)
                      //         ?   university.map((v) => v.name)
                      //         : " "
                    }
                  </p>
                  <p>
                    {t("researcher.carddetail.text4")}:{" "}
                    {
                      listValue.organization_name
                      // != university.map((v) => v.organization_code)
                      //   ? organization1.map((v) => v.organization_name_th)
                      //   :   "   "
                    }
                  </p>
                  <p>
                    {t("researcher.carddetail.text3")}:{" "}
                    {
                      listValue.major_name
                      // != university.map((v) => v.organization_code)
                      //   ?  organization.map((v) => v.organization_name_th)
                      //   :  " "
                    }
                  </p>

                  {listValue.active_phone === 1 ? (
                    <p>โทร: {listValue.user_phone}</p>
                  ) : (
                    <p>โทร: {listValue.user_phone}</p>
                  )}
                  {listValue.active_mobile === 1 ? (
                    <p>เบอร์โทรศัพท์: {listValue.user_mobile}</p>
                  ) : (
                    <p>เบอร์โทรศัพท์: -</p>
                  )}
                  {listValue.active_mail === 1 ? (
                    <p>Email: {listValue.user_mail}</p>
                  ) : (
                    <p>Email: -</p>
                  )}

                  {listValue.GG_URL ? (
                    <p>
                      GoogleScholar:
                      <Button>
                        <a
                          target="_blank"
                          href={listValue.GG_URL}
                          rel="noreferrer"
                        >
                          {/* https://scholar.google.com/
                          {scholar.map((v) => v.GG_URL).slice(-15)}{" "} */}
                          https://scholar.google.com/
                          {/* {listValue.GG_URL} */}
                        </a>
                      </Button>
                    </p>
                  ) : (
                    "-"
                  )}
                </section>
              </Col>

              <Col sm={12}>
                <div className="line-bottom" style={{ margin: "1rem 3rem" }} />
              </Col>
            </Row>
          );
        })}
        <Row>
          <Col sm={12}>
            <section className="statistics">
              <Route
                path={"/"}
                render={(history) => (
                  <AppBar
                    position="static"
                    color="default"
                    className={classes.appbar}
                  >
                    <Tabs
                      indicatorColor="secondary"
                      textColor="secondary"
                      variant="scrollable"
                      scrollButtons="on"
                      TabIndicatorProps={{
                        style: { background: "#5d1809" },
                      }}
                      aria-label="scrollable tabs menu"
                      value={
                        history.location.pathname !== "/"
                          ? history.location.pathname
                          : false
                      }
                    >
                      <Tab
                        value={`/Researcher/locations`}
                        label={t("researcher.tabmenu.menu1")}
                        className={classes.customLabel}
                        icon={<BiMapPin size={30} color="#5d1809" />}
                        component={Link}
                        to={`/Researcher/locations?user_idcard=${idencrypt}`}
                      />

                      <Tab
                        value={`/Researcher/expertise`}
                        label={t("researcher.tabmenu.menu2")}
                        className={classes.customLabel}
                        icon={<BiMedal size={30} color="#5d1809" />}
                        component={Link}
                        to={`/Researcher/expertise?user_idcard=${idencrypt}`}
                      />
                      <Tab
                        value={`/Researcher/intellectual-property`}
                        label={t("researcher.tabmenu.menu3")}
                        className={classes.customLabel}
                        component={Link}
                        icon={<BiCertification size={30} color="#5d1809" />}
                        to={`/Researcher/intellectual-property?user_idcard=${idencrypt}`}
                      />

                      <Tab
                        value={`/Researcher/publication`}
                        label={t("researcher.tabmenu.menu4")}
                        className={classes.customLabel}
                        icon={<BiNews size={30} color="#5d1809" />}
                        component={Link}
                        to={`/Researcher/publication?user_idcard=${idencrypt}`}
                      />

                      <Tab
                        value={`/Researcher/innovation`}
                        label={t("researcher.tabmenu.menu5")}
                        className={classes.customLabel}
                        component={Link}
                        icon={<HiOutlineLightBulb size={30} color="#5d1809" />}
                        to={`/Researcher/innovation?user_idcard=${idencrypt}`}
                      />

                      <Tab
                        value={`/Researcher/group`}
                        label={t("researcher.tabmenu.menu6")}
                        className={classes.customLabel}
                        icon={<BiGroup size={30} color="#5d1809" />}
                        component={Link}
                        to={`/Researcher/group?user_idcard=${idencrypt}`}
                      />

                      <Tab
                        value={`/Researcher/detail`}
                        label={t("researcher.tabmenu.menu7")}
                        className={classes.customLabel}
                        icon={<FaRegIdCard size={30} color="#5d1809" />}
                        component={Link}
                        to={`/Researcher/detail?user_idcard=${idencrypt}`}
                      />

                      {user && user.length > 0 && user[0].group_id === 1 && (
                        <Tab
                          value={`/Researcher/course`}
                          label={t("researcher.tabmenu.menu8")}
                          className={classes.customLabel}
                          icon={
                            <HiOutlineAcademicCap size={30} color="#5d1809" />
                          }
                          component={Link}
                          to={`/Researcher/course?user_idcard=${idencrypt}`}
                        />
                      )}
                    </Tabs>
                  </AppBar>
                )}
              />
            </section>
          </Col>
        </Row>
        <div className="expertise-detail">
          <Switch>
            <Route path="/Researcher/locations">
              <ResearcherLocationDetail />
            </Route>

            <Route path="/Researcher/expertise">
              <ResearcherExpertise user={user} classes={classes} />
            </Route>

            <Route path="/Researcher/intellectual-property">
              <ResearcherProperty
                concept_proposal_id={idencrypt}
                classes={classes}
                classTimeline={classTimeline}
              />
            </Route>

            <Route path="/Researcher/publication">
              <ResearcherPublication
                classes={classes}
                Accordion={Accordion}
                classTimeline={classTimeline}
              />
            </Route>

            <Route path="/Researcher/innovation">
              <ResearcherInnovation
                concept_proposal_id={idencrypt}
                classes={classes}
              />
            </Route>

            <Route path="/Researcher/group">
              <ResearcherGroup
                concept_proposal_id={idencrypt}
                classes={classes}
              />
            </Route>

            <Route path="/Researcher/detail">
              <ResearcherDetail
                concept_proposal_id={idencrypt}
                classes={classes}
                Accordion={Accordion}
                classTimeline={classTimeline}
              />
            </Route>

            <Route path="/Researcher/course">
              <ResearcherCourse
                concept_proposal_id={idencrypt}
                classes={classes}
                course={course}
              />
            </Route>
          </Switch>
        </div>
      </div>
    </div>
  );
}

export default Researcher;
