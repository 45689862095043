import React, { useEffect, useState, useRef } from "react";

import { Col, Row } from "reactstrap";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import "../Css/Detail.scss";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import AppBar from "@material-ui/core/AppBar";
import axios from "axios";
import { FaChevronCircleDown } from "react-icons/fa";
import no_img_product from "../../images/no_img_product.png";
import "./style_coresearcher.scss";
import "../Css/pagination.scss";
import { t } from "i18next";
import Youtubeshow from "../Detail/Youtubeshow";
import Imageshow from "../Detail/Imageshow";
import { apiUrl } from "../../config/api.json";
function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `scrollable-force-tab-${index}`,
    "aria-controls": `scrollable-force-tabpanel-${index}`,
  };
}

export default function ExpertisePage(props) {
  const { co_researcher_id, classes } = props;
  const [expertise, setexpertise] = useState([]);
  const [award, setaward] = useState([]);
  const [certificate, setcertificate] = useState([]);

  const [expertise_district, setexpertise_district] = useState([]);
  const [expertise_community, setexpertise_community] = useState([]);
  const [expertise_phi, setexpertise_phi] = useState([]);

  const [award_district, setaward_district] = useState([]);
  const [award_community, setaward_community] = useState([]);
  const [award_phi, setaward_phi] = useState([]);

  const [certificate_district, setcertificate_district] = useState([]);
  const [certificate_community, setcertificate_community] = useState([]);
  const [certificate_phi, setcertificate_phi] = useState([]);

  const [currentPageEx, setCurrentPageEx] = useState([]);
  const [currentPageAw, setCurrentPageAw] = useState([]);
  const [currentPageCe, setCurrentPageCe] = useState([]);

  const divdetailEx = useRef();
  const divdetailAw = useRef();
  const divdetailCe = useRef();
  const refexpertise = useRef();

  let id = atob(co_researcher_id);
  const [valuePage1, setValuePage1] = useState(0);

  const handleChangePage1 = (event, newValue) => {
    setValuePage1(newValue);
  };

  const getAward = async () => {
    const data = await axios.get(`${apiUrl}/api/get/co-researcher/award/${id}`);
    if (data.data.award) {
      setaward(data.data.award);
    }
    if (data.data.details) {
      data.data.details.forEach((el) => {
        if (el.co_group_name === "ตำบล") {
          if (el.award.length) {
            setaward_district(el);
          }
        } else if (el.co_group_name === "ชุมชน") {
          if (el.award.length) {
            setaward_community(el);
          }
        } else if (el.co_group_name === "ปราชญ์") {
          if (el.award.length) {
            setaward_phi(el);
          }
        }
      });
    }
  };

  const getExpertise = async () => {
    const result = await axios.get(
      `${apiUrl}/api/get/co-researcher-expertise/image/${id}`
    );
    if (result.data.expertise) {
      setexpertise(result.data.expertise);
    }
    if (result.data.details) {
      result.data.details.forEach((el) => {
        if (el.co_group_name === "ตำบล") {
          if (el.expertises.length) {
            setexpertise_district(el);
          }
        } else if (el.co_group_name === "ชุมชน") {
          if (el.expertises.length) {
            setexpertise_community(el);
          }
        } else if (el.co_group_name === "ปราชญ์") {
          if (el.expertises.length) {
            setexpertise_phi(el);
          }
        }
      });
    }
  };

  const getCertificate = async () => {
    const result = await axios.get(
      `${apiUrl}/api/get/co-researcher/certificate/${id}`
    );
    if (result.data.certificate) {
      setcertificate(result.data.certificate);
    }
    if (result.data.details) {
      result.data.details.forEach((el) => {
        if (el.co_group_name === "ตำบล") {
          if (el.certificate.length) {
            setcertificate_district(el);
          }
        } else if (el.co_group_name === "ชุมชน") {
          if (el.certificate.length) {
            setcertificate_community(el);
          }
        } else if (el.co_group_name === "ปราชญ์") {
          if (el.certificate.length) {
            setcertificate_phi(el);
          }
        }
      });
    }
  };

  useEffect(() => {
    getAward();
    getExpertise();
    getCertificate();
  }, []);

  const onClickEx = (data) => {
    setCurrentPageEx([data]);
    divdetailEx.current.scrollIntoView({ behavior: "smooth" });
  };

  const onClickAw = (data) => {
    setCurrentPageAw([data]);
    divdetailAw.current.scrollIntoView({ behavior: "smooth" });
  };

  const onClickCe = (data) => {
    setCurrentPageCe([data]);
    divdetailCe.current.scrollIntoView({ behavior: "smooth" });
  };

  const expertisediv = currentPageEx.map((data, index) => {
    const imagesEx = [];
    imagesEx.push({
      original: `https://researcher.kims-rmuti.com/file-upload/co_expertise-upload/${data.co_researcher_expertise_image}`,
      thumbnail: `https://researcher.kims-rmuti.com/file-upload/co_expertise-upload/${data.co_researcher_expertise_image}`,
    });
    data.images.forEach(function (listitem) {
      if (listitem.co_ex_image) {
        imagesEx.push({
          original: `https://researcher.kims-rmuti.com/file-upload/co_expertise-upload/${listitem.co_ex_image}`,
          thumbnail: `https://researcher.kims-rmuti.com/file-upload/co_expertise-upload/${listitem.co_ex_image}`,
        });
      }
    });
    return (
      <Row key={index} className="body-expertise">
        <Col sm={12} style={{ marginBottom: "1rem" }}>
          <h3 style={{ textAlign: "left" }}>{data.co_researcher_expertise}</h3>
          <hr />
        </Col>
        <Imageshow
          image={`https://researcher.kims-rmuti.com/file-upload/co_expertise-upload/${data.co_researcher_expertise_image}`}
          images={data.images}
          imageshow={imagesEx}
          lg={6}
        />
        <Youtubeshow
          url_video={
            data.co_researcher_expertise_vdo
              ? data.co_researcher_expertise_vdo
              : ""
          }
          xs={12}
          sm={12}
          md={12}
          lg={6}
          paddingTop={"0"}
          className={""}
        />
        <Col xs={12} sm={12} style={{ paddingTop: "2rem" }}>
          <hr />
          <div style={{ padding: "0 2rem 0 2rem", textAlign: "left" }}>
            <p>
              <b> {t("community.expertise.detail")} : </b>{" "}
              {data.co_researcher_expertise_details}
            </p>
          </div>
        </Col>
      </Row>
    );
  });

  const awarddiv = currentPageAw.map((listValue, index) => {
    let img = `https://researcher.kims-rmuti.com/file-upload/co-award-upload/${listValue.co_researcher_award_images}`;
    const imagesAw = [];
    imagesAw.push({
      original: `https://researcher.kims-rmuti.com/file-upload/co-award-upload/${listValue.co_researcher_award_images}`,
      thumbnail: `https://researcher.kims-rmuti.com/file-upload/co-award-upload/${listValue.co_researcher_award_images}`,
    });
    listValue.images.forEach(function (listitem) {
      if (listitem.co_award_image) {
        imagesAw.push({
          original: `https://researcher.kims-rmuti.com/file-upload/co-award-upload/${listitem.co_award_image}`,
          thumbnail: `https://researcher.kims-rmuti.com/file-upload/co-award-upload/${listitem.co_award_image}`,
        });
      }
    });
    return (
      <div
        className="blog-card newkn-div"
        key={index}
        style={{ marginBottom: "0" }}
      >
        <Row>
          <Col sm={12}>
            <div className="description">
              <h1>{listValue.co_researcher_award_name_th}</h1>
              <h2>{listValue.co_researcher_award_name_en}</h2>
              <hr style={{ padding: "0 3rem" }} />
            </div>
          </Col>

          <Imageshow
            image={img}
            images={listValue.images}
            imageshow={imagesAw}
            lg={6}
          />
          <hr className="line-expertise" />
          <Col sm={12} md={6}>
            <div className="description">
              <h5>{listValue.co_researcher_award_Portfolio_th}</h5>
              <p>
                <b>{t("community.expertise.award_giver")} : </b>{" "}
                {listValue.co_researcher_award_giver}
              </p>
              <p>
                <b>{t("community.expertise.detail")} : </b>{" "}
                {listValue.co_researcher_award_detail}
              </p>
              <p>
                <b>{t("community.expertise.date_of_award")} : </b>{" "}
                {listValue.co_researcher_award_date
                  ? new Date(
                      listValue.co_researcher_award_date
                    ).toLocaleDateString("th-TH", {
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                      weekday: "long",
                    })
                  : ""}
              </p>
            </div>
          </Col>
        </Row>
      </div>
    );
  });

  const certificatediv = currentPageCe.map((listValue, index) => {
    const imagesCa = [];
    imagesCa.push({
      original: `https://researcher.kims-rmuti.com/file-upload/co_certificate-upload/${listValue.co_certificate_image}`,
      thumbnail: `https://researcher.kims-rmuti.com/file-upload/co_certificate-upload/${listValue.co_certificate_image}`,
    });
    listValue.images.forEach(function (listitem) {
      if (listitem.co_certificate_image) {
        imagesCa.push({
          original: `https://researcher.kims-rmuti.com/file-upload/co_certificate-upload/${listitem.co_certificate_image}`,
          thumbnail: `https://researcher.kims-rmuti.com/file-upload/co_certificate-upload/${listitem.co_certificate_image}`,
        });
      }
    });
    return (
      <div
        key={index}
        className="blog-card"
        style={{ padding: "2rem", marginBottom: "0" }}
      >
        <Row>
          <Col sm={12}>
            <div className="description">
              <h1>{listValue.co_certificate_name_th}</h1>
              <hr style={{ padding: "0 3rem" }} />
            </div>
          </Col>

          <Imageshow
            image={`https://researcher.kims-rmuti.com/file-upload/co_certificate-upload/${listValue.co_certificate_image}`}
            images={listValue.images}
            imageshow={imagesCa}
            lg={6}
          />

          <hr className="line-expertise" />
          <Col sm={12} md={6}>
            <div className="description">
              <h5>{listValue.co_certificate_venue}</h5>
              <p>
                {" "}
                <b>{t("community.expertise.training_date")} : </b>&nbsp;
                {listValue.co_certificate_start
                  ? new Date(listValue.co_certificate_start).toLocaleDateString(
                      "th-TH",
                      {
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                        weekday: "long",
                      }
                    )
                  : ""}
              </p>
              <p>
                {" "}
                <b> {t("community.expertise.to")} :&nbsp; </b>
                {listValue.co_certificate_end
                  ? new Date(listValue.co_certificate_start).toLocaleDateString(
                      "th-TH",
                      {
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                        weekday: "long",
                      }
                    )
                  : ""}
              </p>
              <p>
                <b>{t("community.expertise.location")} : </b>{" "}
                {listValue.co_certificate_agency}
              </p>
              <p>
                <b>{t("community.expertise.country")} : </b>{" "}
                {listValue.co_certificate_country}
              </p>
            </div>
          </Col>
        </Row>
      </div>
    );
  });

  const DetailPage = (obj) => {
    let { dataEx } = obj;
    return (
      <>
        {dataEx ? (
          <div>
            <h5 style={{ paddingTop: "1rem", textAlign: "left" }}>
              {dataEx.co_group_name}
            </h5>
            <div className="line-bottom" style={{ margin: "1rem 1rem" }} />

            {dataEx.expertises?.map((ex, i) => (
              <div key={i}>
                <p style={{ textAlign: "left" }}>{ex.co_researcher_name_th}</p>
                <Row style={{ paddingRight: "2rem" }}>
                  {ex.expertises.map((d, j) => (
                    <Col xs={12} sm={12} md={6} lg={6} xl={4} key={j}>
                      <div
                        className="card-output"
                        style={{ backgroundColor: "#fff" }}
                        onClick={() => onClickEx(d)}
                      >
                        <img
                          className="img-output"
                          src={
                            d.co_researcher_expertise_image
                              ? `https://researcher.kims-rmuti.com/file-upload/co_expertise-upload/${d.co_researcher_expertise_image}`
                              : no_img_product
                          }
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null; // prevents looping
                            currentTarget.src = no_img_product;
                          }}
                          alt="output_image"
                        />
                        <div className="output-descreption">
                          <Row>
                            <Col xs={10}>
                              <h6>
                                <b>{d.co_researcher_expertise}</b>
                              </h6>
                            </Col>
                            <Col xs={2} style={{ textAlign: "left" }}>
                              <FaChevronCircleDown
                                size={30}
                                className="arrow-down"
                              />
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </Col>
                  ))}
                </Row>
                <hr />
              </div>
            ))}
          </div>
        ) : null}
      </>
    );
  };

  const DetailPageAw = (obj) => {
    let { dataAw } = obj;
    return (
      <>
        {dataAw ? (
          <div>
            <h5 style={{ paddingTop: "1rem", textAlign: "left" }}>
              {dataAw.co_group_name}
            </h5>
            <div className="line-bottom" style={{ margin: "1rem 1rem" }} />

            {dataAw.award?.map((ex, i) => (
              <div key={i}>
                <p style={{ textAlign: "left" }}>{ex.co_researcher_name_th}</p>
                <Row style={{ paddingRight: "2rem" }}>
                  {ex.award.map((data, j) => (
                    <Col xs={12} sm={12} md={6} lg={6} xl={4} key={j}>
                      <div
                        className="card-output"
                        style={{ backgroundColor: "#fff" }}
                        onClick={() => onClickAw(data)}
                      >
                        <img
                          className="img-output"
                          src={
                            data.co_researcher_award_images
                              ? `https://researcher.kims-rmuti.com/file-upload/co-award-upload/${data.co_researcher_award_images}`
                              : no_img_product
                          }
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null; // prevents looping
                            currentTarget.src = no_img_product;
                          }}
                          alt="output_image"
                        />
                        <div className="output-descreption">
                          <Row>
                            <Col xs={10}>
                              <h6>
                                <b>{data.co_researcher_award_name_th}</b>
                              </h6>
                            </Col>
                            <Col xs={2} style={{ textAlign: "left" }}>
                              <FaChevronCircleDown
                                size={30}
                                className="arrow-down"
                              />
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </Col>
                  ))}
                </Row>
                <hr />
              </div>
            ))}
          </div>
        ) : null}
      </>
    );
  };

  const DetailPageCe = (obj) => {
    let { dataCe } = obj;
    return (
      <>
        {dataCe ? (
          <div>
            <h5 style={{ paddingTop: "1rem", textAlign: "left" }}>
              {dataCe.co_group_name}
            </h5>
            <div className="line-bottom" style={{ margin: "1rem 1rem" }} />

            {dataCe.certificate?.map((ex, i) => (
              <div key={i}>
                <p style={{ textAlign: "left" }}>{ex.co_researcher_name_th}</p>
                <Row style={{ paddingRight: "2rem" }}>
                  {ex.certificate.map((data, j) => (
                    <Col xs={12} sm={12} md={6} lg={6} xl={4} key={j}>
                      <div
                        className="card-output"
                        style={{ backgroundColor: "#fff" }}
                        onClick={() => onClickCe(data)}
                      >
                        <img
                          className="img-output"
                          src={
                            data.co_certificate_image
                              ? `https://researcher.kims-rmuti.com/file-upload/co_certificate-upload/${data.co_certificate_image}`
                              : no_img_product
                          }
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null; // prevents looping
                            currentTarget.src = no_img_product;
                          }}
                          alt="output_image"
                        />
                        <div className="output-descreption">
                          <Row>
                            <Col xs={10}>
                              <h6>
                                <b>{data.co_certificate_name_th}</b>
                              </h6>
                            </Col>
                            <Col xs={2} style={{ textAlign: "left" }}>
                              <FaChevronCircleDown
                                size={30}
                                className="arrow-down"
                              />
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </Col>
                  ))}
                </Row>
                <hr />
              </div>
            ))}
          </div>
        ) : null}
      </>
    );
  };

  return (
    <div ref={refexpertise}>
      <AppBar
        position="static"
        color="default"
        className={classes.appbar}
        style={{ borderRadius: "10px 10px 0 0", paddingTop: "2rem" }}
      >
        <Tabs
          value={valuePage1}
          onChange={handleChangePage1}
          variant="scrollable"
          scrollButtons="on"
          TabIndicatorProps={{
            style: { background: "#5d1809" },
          }}
          classes={{
            root: classes.abRoot,
            positionStatic: classes.abStatic,
          }}
          textColor="secondary"
          aria-label="scrollable tabs menu"
        >
          <Tab
            label={t("community.expertise.expertise")}
            className={classes.customLabel}
            {...a11yProps(0)}
          />
          <Tab
            label={t("community.expertise.award")}
            className={classes.customLabel}
            {...a11yProps(1)}
          />
          <Tab
            label={t("community.expertise.certificate")}
            className={classes.customLabel}
            {...a11yProps(2)}
          />
        </Tabs>
      </AppBar>
      <div className="div-expertise">
        <TabPanel value={valuePage1} index={0} style={{ paddingLeft: "1rem" }}>
          <div>
            <div className="line-bottom" style={{ margin: "1rem 1rem" }} />
            <Row style={{ paddingRight: "2rem" }}>
              {expertise.map((data, index) => (
                <Col xs={12} sm={12} md={6} lg={6} xl={4} key={index}>
                  <div
                    className="card-output"
                    style={{ backgroundColor: "#fff" }}
                    onClick={() => onClickEx(data)}
                  >
                    <img
                      className="img-output"
                      src={
                        data.co_researcher_expertise_image
                          ? `https://researcher.kims-rmuti.com/file-upload/co_expertise-upload/${data.co_researcher_expertise_image}`
                          : no_img_product
                      }
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = no_img_product;
                      }}
                      alt="output_image"
                    />
                    <div className="output-descreption">
                      <Row>
                        <Col xs={10}>
                          <h6>
                            <b>{data.co_researcher_expertise}</b>
                          </h6>
                        </Col>
                        <Col xs={2} style={{ textAlign: "left" }}>
                          <FaChevronCircleDown
                            size={30}
                            className="arrow-down"
                          />
                        </Col>
                      </Row>
                    </div>
                  </div>
                </Col>
              ))}
            </Row>
            {Object.keys(expertise_district).length ? (
              <DetailPage dataEx={expertise_district} />
            ) : null}
            {Object.keys(expertise_community).length ? (
              <DetailPage dataEx={expertise_community} />
            ) : null}
            {Object.keys(expertise_phi).length ? (
              <DetailPage dataEx={expertise_phi} />
            ) : null}
            <div className="line-bottom" style={{ margin: "2rem 1rem" }} />
            <div
              ref={divdetailEx}
              style={{ margin: "2rem -1.3rem -2.4rem -1rem" }}
            >
              {expertisediv}
            </div>
          </div>
        </TabPanel>
        <TabPanel value={valuePage1} index={1}>
          <div>
            <div className="line-bottom" style={{ margin: "1rem 1rem" }} />
            <Row style={{ paddingRight: "2rem" }}>
              {award.map((data, index) => (
                <Col xs={12} sm={12} md={6} lg={6} xl={4} key={index}>
                  <div
                    className="card-output"
                    style={{ backgroundColor: "#fff" }}
                    onClick={() => onClickAw(data)}
                  >
                    <img
                      className="img-output"
                      src={
                        data.co_researcher_award_images
                          ? `https://researcher.kims-rmuti.com/file-upload/co-award-upload/${data.co_researcher_award_images}`
                          : no_img_product
                      }
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = no_img_product;
                      }}
                      alt="output_image"
                    />
                    <div className="output-descreption">
                      <Row>
                        <Col xs={10}>
                          <h6>
                            <b>{data.co_researcher_award_name_th}</b>
                          </h6>
                        </Col>
                        <Col xs={2} style={{ textAlign: "left" }}>
                          <FaChevronCircleDown
                            size={30}
                            className="arrow-down"
                          />
                        </Col>
                      </Row>
                    </div>
                  </div>
                </Col>
              ))}
            </Row>

            {Object.keys(award_district).length ? (
              <DetailPageAw dataAw={award_district} />
            ) : null}
            {Object.keys(award_community).length ? (
              <DetailPageAw dataAw={award_community} />
            ) : null}
            {Object.keys(award_phi).length ? (
              <DetailPageAw dataAw={award_phi} />
            ) : null}

            <div className="line-bottom" style={{ margin: "2rem 1rem" }} />
            <div
              ref={divdetailAw}
              style={{ margin: "2rem -0.7rem -0.7rem -0.7rem" }}
            >
              {awarddiv}
            </div>
          </div>
        </TabPanel>

        <TabPanel value={valuePage1} index={2}>
          <div>
            <div className="line-bottom" style={{ margin: "1rem 1rem" }} />
            <Row style={{ paddingRight: "2rem" }}>
              {certificate.map((data, index) => (
                <Col xs={12} sm={12} md={6} lg={6} xl={4} key={index}>
                  <div
                    className="card-output"
                    style={{ backgroundColor: "#fff" }}
                    onClick={() => onClickCe(data)}
                  >
                    <img
                      className="img-output"
                      src={
                        data.co_certificate_image
                          ? `https://researcher.kims-rmuti.com/file-upload/co_certificate-upload/${data.co_certificate_image}`
                          : no_img_product
                      }
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = no_img_product;
                      }}
                      alt="output_image"
                    />
                    <div className="output-descreption">
                      <Row>
                        <Col xs={10}>
                          <h6>
                            <b>{data.co_certificate_name_th}</b>
                          </h6>
                        </Col>
                        <Col xs={2} style={{ textAlign: "left" }}>
                          <FaChevronCircleDown
                            size={30}
                            className="arrow-down"
                          />
                        </Col>
                      </Row>
                    </div>
                  </div>
                </Col>
              ))}
            </Row>

            {Object.keys(certificate_district).length ? (
              <DetailPageCe dataCe={certificate_district} />
            ) : null}
            {Object.keys(certificate_community).length ? (
              <DetailPageCe dataCe={certificate_community} />
            ) : null}
            {Object.keys(certificate_phi).length ? (
              <DetailPageCe dataCe={certificate_phi} />
            ) : null}

            <div className="line-bottom" style={{ margin: "2rem 1rem" }} />
            <div
              ref={divdetailCe}
              style={{ margin: "2rem -0.7rem -0.7rem -0.7rem" }}
            >
              {certificatediv}
            </div>
          </div>
        </TabPanel>
      </div>
    </div>
  );
}
