/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-undef */
import {
  Box,
  TextField,
  Button,
  CircularProgress,
  IconButton,
} from "@material-ui/core";

import { Card, Row, Col, Accordion } from "react-bootstrap";

import Select from "react-select";

import makeAnimated from "react-select/animated";

import React, { useCallback, useState, useMemo } from "react";
import "./Css/Search.scss";
import "../index.css";
import {
  MapContainer,
  TileLayer,
  Marker,
  CircleMarker,
  Popup,
  useMap,
  useMapEvent,
  Rectangle,
} from "react-leaflet";
import { useEventHandlers } from "@react-leaflet/core";
import { Icon } from "leaflet";
import { withRouter } from "react-router";
import { FaSearch } from "react-icons/fa";
import axios from "axios";
import { BiInfoCircle } from "react-icons/bi";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { apiUrl } from "../config/api.json";
// Classes used by Leaflet to position controls
const POSITION_CLASSES = {
  bottomleft: "leaflet-bottom leaflet-left",
  bottomright: "leaflet-bottom leaflet-right",
  topleft: "leaflet-top leaflet-left",
  topright: "leaflet-top leaflet-right",
};

const BOUNDS_STYLE = { weight: 1 };

function MinimapBounds({ parentMap, zoom }) {
  const minimap = useMap();

  // Clicking a point on the minimap sets the parent's map center
  const onClick = useCallback(
    (e) => {
      parentMap.setView(e.latlng, parentMap.getZoom());
    },
    [parentMap]
  );
  useMapEvent("click", onClick);

  // Keep track of bounds in state to trigger renders
  const [bounds, setBounds] = useState(parentMap.getBounds());
  const onChange = useCallback(() => {
    setBounds(parentMap.getBounds());
    // Update the minimap's view to match the parent map's center and zoom
    minimap.setView(parentMap.getCenter(), zoom);
  }, [minimap, parentMap, zoom]);

  // Listen to events on the parent map
  const handlers = useMemo(() => ({ move: onChange, zoom: onChange }), []);
  useEventHandlers({ instance: parentMap }, handlers);

  return <Rectangle bounds={bounds} pathOptions={BOUNDS_STYLE} />;
}

function SearchPageCoRe(props) {
  const { t } = useTranslation();

  const animatedComponents = makeAnimated();

  const [count_in_country, setcount_in_country] = React.useState(0);
  const [count_out_country, setcount_out_country] = React.useState(0);
  const [count_co, setcount_co] = React.useState(0);

  const [coResearchers, setCoResearchers] = React.useState([]);
  const [international, setInternational] = React.useState([]);
  const [national, setNational] = React.useState([]);

  const [province, setprovince] = React.useState("");
  const [Cogroup, setCogroup] = React.useState([
    { value: "1", label: "ชุมชน" },
    { value: "2", label: "หน่วยงานภาคธุรกิจ" },
    { value: "3", label: "หน่วยงานภาครัฐ" },
    { value: "7", label: "ปราชญ์" },
    {
      value: "8",
      label: "หน่วยงานระดับนานาชาติ",
    },
  ]);
  const [provincelist, setprovincelist] = React.useState([]);
  const [searchTitle, setSearchTitle] = React.useState("");
  const [message, setMessage] = React.useState("");
  const [loading, setLoading] = React.useState(false);

  const localUrl = "http://localhost:4000";

  const getRequestParams = (
    title,
    Cogroup,
    province
    // page
  ) => {
    let params = {};
    if (Cogroup.length) {
      // console.log("IfCo");
      Cogroup.forEach((data) => {
        if (data.value === "1") {
          params["groupOne"] = data.value;
        } else if (data.value === "2") {
          params["groupTwo"] = data.value;
        } else if (data.value === "3") {
          params["groupThree"] = data.value;
        } else if (data.value === "7") {
          params["groupSeven"] = data.value;
        } else if (data.value === "8") {
          params["group8"] = data.value;
        }
      });
    } else {
      // console.log("ElseCO");
      params["groupOne"] = "1";
      params["groupTwo"] = "2";
      params["groupThree"] = "3";
      params["groupSeven"] = "7";
      params["group8"] = "8";
    }

    if (title != undefined) {
      params["title"] = title;
    }
    if (province != "") {
      params["province_id"] = province;
    } else {
      params["province_id"] = "";
    }

    return params;
  };

  const getprovincelist = async () => {
    const response = await axios.get(
      `${apiUrl}/api/get/province?fbclid=IwAR3jm18SxLgXZya9vfwYnPVwldUCelLQi25evki3jTvVsPM2jNzK7E6Mhs4`
    );
    setprovincelist(response.data);
  };

  const retrieveCoResearchers = () => {
    setLoading(true);
    setcount_in_country(0);
    setcount_out_country(0);
    setcount_co(0);

    setCoResearchers([]);
    setInternational([]);
    setNational([]);
    if (province === 33) {
      window.open("https://rmuti.ac.th/user/kasidin.ke/web/");
    }
    const params = getRequestParams(
      searchTitle,
      Cogroup,
      province
      // page
    );
    axios
      .get(`${apiUrl}/api/get/co-researchers`, { params })
      .then((res) => {
        setcount_in_country(res.data.count_in_country);
        setcount_out_country(res.data.count_out_country);
        setcount_co(res.data.count_co);

        setCoResearchers(res.data.co_researchers);
        setInternational(res.data.co_inter);
        setNational([
          {
            title: t("community_search.community"),
            item_name: t("community_search.community"),
            data: res.data.co_community,
          },
          {
            title: t("community_search.business"),
            item_name: t("community_search.agency"),
            data: res.data.co_business,
          },
          {
            title: t("community_search.government"),
            item_name: t("community_search.agency"),
            data: res.data.co_government,
          },
          {
            title: t("community_search.philosopher"),
            item_name: t("community_search.people"),
            data: res.data.co_expert,
          },
        ]);
      })
      .finally(() => {
        setMessage("");
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  React.useEffect(() => {
    window.scrollTo(0, 0);
    retrieveCoResearchers();
    getprovincelist();
  }, []);

  const handleChangeProvince = (e) => {
    if (e) {
      setprovince(e.value);
    } else {
      setprovince("");
    }
  };

  function MinimapControl({ position, zoom }) {
    const parentMap = useMap();
    const mapZoom = zoom || 0;

    // Memoize the minimap so it's not affected by position changes
    const minimap = useMemo(
      () => (
        <MapContainer
          style={{ height: 120, width: 200 }}
          center={parentMap.getCenter()}
          zoom={mapZoom}
          dragging={false}
          doubleClickZoom={false}
          scrollWheelZoom={false}
          attributionControl={false}
          zoomControl={false}
        >
          {coResearchers.map((p) => (
            <CircleMarker
              // key={index}
              center={[
                p.co_researcher_latitude ? p.co_researcher_latitude : 15,
                p.co_researcher_longitude ? p.co_researcher_longitude : 102,
              ]}
              // radius={10}
              opacity={0}
            >
              <Marker
                position={[
                  p.co_researcher_latitude ? p.co_researcher_latitude : 15,
                  p.co_researcher_longitude ? p.co_researcher_longitude : 102,
                ]}
                icon={
                  new Icon({
                    iconUrl: p.co_researcher_image,
                    iconSize: [10, 11],
                    // iconAnchor: [19, 0],
                    className: "minimap-image-icon",
                  })
                }
              >
                <IconButton
                  color="primary"
                  aria-label="view info co"
                  style={{
                    marginTop: "10px",
                    fontFamily: "Prompt",
                    fontSize: "15px",
                  }}
                ></IconButton>
              </Marker>
            </CircleMarker>
          ))}

          <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
          <MinimapBounds parentMap={parentMap} zoom={mapZoom} />
        </MapContainer>
      ),
      []
    );
    const positionClass =
      (position && POSITION_CLASSES[position]) || POSITION_CLASSES.topright;
    return (
      <div className={positionClass}>
        <div className="leaflet-control leaflet-bar">{minimap}</div>
      </div>
    );
  }

  // console.log("province :", provincelist);
  // console.log("coResearchers :", coResearchers);
  return (
    <div className="body-detail ">
      <div className="card-searcher">
        <Row>
          <Col sm={12}>
            <Card className="card-border card-search">
              <Card.Body
                style={{ paddingTop: "2rem", paddingBottom: "0.5rem" }}
              >
                <div>
                  <Box
                    component="form"
                    sx={{
                      "& .MuiTextField-root": {
                        m: 2,
                        width: "100%",
                        marginTop: "-20px",
                      },
                    }}
                    noValidate
                    autoComplete="off"
                  >
                    <div>
                      <Row className="align-items-center justify-content-md-center">
                        <Col md="7" xs="12">
                          <TextField
                            id="standard-helperText"
                            label={t("community_search.label")}
                            defaultValue="Default Value"
                            helperText={t("community_search.label_datail")}
                            InputProps={{ style: { fontFamily: "Prompt" } }}
                            InputLabelProps={{
                              style: { fontFamily: "Prompt" },
                            }}
                            FormHelperTextProps={{
                              style: { fontFamily: "Prompt" },
                            }}
                            variant="standard"
                            value={searchTitle}
                            onChange={(e) => setSearchTitle(e.target.value)}
                          />
                          <Row style={{ marginBottom: "0.5rem" }}>
                            <Col
                              xs={12}
                              sm={12}
                              md={12}
                              lg={6}
                              xl={6}
                              xxl={6}
                              style={{ marginTop: "0.5rem" }}
                            >
                              <Select
                                placeholder={t("community_search.agency_type")}
                                closeMenuOnSelect={false}
                                components={animatedComponents}
                                // defaultValue={Cogroup}
                                isMulti
                                options={[
                                  {
                                    value: "1",
                                    label: t("community_search.community"),
                                  },
                                  {
                                    value: "2",
                                    label: t("community_search.business"),
                                  },
                                  {
                                    value: "3",
                                    label: t("community_search.government"),
                                  },
                                  {
                                    value: "7",
                                    label: t("community_search.philosopher"),
                                  },
                                  {
                                    value: "8",
                                    label: t("community_search.international"),
                                  },
                                ]}
                                onChange={(e) => setCogroup(e)}
                              />
                            </Col>
                            <Col
                              xs={12}
                              sm={12}
                              md={12}
                              lg={6}
                              xl={6}
                              xxl={6}
                              style={{ marginTop: "0.5rem" }}
                            >
                              <Select
                                placeholder={t("community_search.province")}
                                className="basic-single"
                                classNamePrefix="select"
                                isClearable={true}
                                options={provincelist}
                                onChange={(e) => handleChangeProvince(e)}
                              />
                            </Col>
                          </Row>
                        </Col>
                        <Col md="1" xs="12">
                          <Button
                            className="btn-search"
                            variant="contained"
                            size="large"
                            onClick={retrieveCoResearchers}
                            disabled={loading}
                            type="submit"
                            startIcon={<FaSearch size={13} />}
                          >
                            {loading && <CircularProgress size={22} />}
                            {!loading && t("search")}
                          </Button>
                        </Col>
                      </Row>
                    </div>
                  </Box>
                </div>
              </Card.Body>
            </Card>
          </Col>
          {loading && <div class="demo" />}
          {!loading && (
            <>
              <Col sm={12}>
                <Card
                  className="card-border"
                  style={{
                    marginTop: "10px",
                    fontFamily: "Prompt",
                    borderRadius: "15px",
                    boxShadow: "none",
                    border: "none",
                  }}
                >
                  <MapContainer
                    center={[13, 102]}
                    zoom={6}
                    minZoom={3}
                    maxZoom={21}
                    zoomControl={false}
                    style={{
                      width: "100%",
                      minHeight: "450px",
                      height: "65vh",
                      margin: "0",
                      zIndex: "0",
                      borderRadius: "15px",
                    }}
                  >
                    <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                    {!message
                      ? coResearchers.map((p, index) => {
                          return (
                            <CircleMarker
                              key={index}
                              center={[
                                p.co_researcher_latitude
                                  ? p.co_researcher_latitude
                                  : 15,
                                p.co_researcher_longitude
                                  ? p.co_researcher_longitude
                                  : 102,
                              ]}
                              opacity={0}
                            >
                              <Marker
                                position={[
                                  p.co_researcher_latitude
                                    ? p.co_researcher_latitude
                                    : 15,
                                  p.co_researcher_longitude
                                    ? p.co_researcher_longitude
                                    : 102,
                                ]}
                                icon={
                                  new Icon({
                                    iconUrl: p.co_researcher_image,
                                    iconSize: [20, 20],
                                    className:
                                      p.co_researcher_group_id === 1
                                        ? "image-icon-community "
                                        : p.co_researcher_group_id === 2
                                        ? "image-icon-partner"
                                        : p.co_researcher_group_id === 3
                                        ? "image-icon-government"
                                        : p.co_researcher_group_id === 7
                                        ? "image-icon-philosopher "
                                        : p.co_researcher_group_id === 8
                                        ? "image-icon-International "
                                        : "image-icon-other ",
                                  })
                                }
                              >
                                <Popup>
                                  <Card.Title className="tip__container">
                                    <text
                                      style={{
                                        marginTop: "10px",
                                        fontFamily: "Prompt",
                                        fontSize: "15px",
                                      }}
                                    >
                                      {p.co_researcher_name_th}
                                    </text>
                                    <hr />

                                    <img
                                      style={{
                                        objectPosition: "center ",
                                      }}
                                      width="150px"
                                      src={p.co_researcher_image}
                                    />
                                    <hr />
                                    <Link
                                      to={`/CoResearcher/expertise?co_researcher_id=${btoa(
                                        p.co_researcher_id
                                      )}`}
                                    >
                                      <IconButton
                                        color="primary"
                                        aria-label="view info co"
                                        style={{
                                          marginTop: "10px",
                                          fontFamily: "Prompt",
                                          fontSize: "15px",
                                        }}
                                      >
                                        {t("map.more")}
                                        <BiInfoCircle size={18} />
                                      </IconButton>
                                    </Link>
                                  </Card.Title>
                                </Popup>
                              </Marker>
                            </CircleMarker>
                          );
                        })
                      : null}
                    {/* <div
                      style={{
                        backgroundColor: "#fff",
                        width: "100px",
                        height: "100px",
                        position: "absolute",
                      }}
                    ></div> */}

                    <div className="coRe-detail">
                      <div id="box" style={{ width: 250, marginRight: 10 }}>
                        <div className="wrap-collabsible">
                          <input
                            id="collapsible"
                            className="toggle"
                            type="checkbox"
                            defaultChecked="true"
                            style={{ pointerEvents: "auto", display: "none" }}
                          />
                          <label
                            htmlFor="collapsible"
                            className="lbl-toggle"
                            style={{ pointerEvents: "auto" }}
                          >
                            {t("community_search.detail")}
                          </label>
                          <div className="collapsible-content">
                            <div className="content-inner">
                              <Row>
                                <Col xs={4}>
                                  <div
                                    style={{
                                      background: "rgb(0, 201, 10)",
                                      width: "100%",
                                      height: "10px",
                                    }}
                                  />
                                </Col>
                                <Col xs={8}>
                                  <p style={{ textAlign: "left" }}>
                                    {t("community_search.community")}
                                  </p>
                                </Col>
                              </Row>
                              <Row className="mt-2">
                                <Col xs={4}>
                                  <div
                                    style={{
                                      background: " #ff32f4",
                                      width: "100%",
                                      height: "10px",
                                    }}
                                  />
                                </Col>
                                <Col xs={8}>
                                  <p style={{ textAlign: "left" }}>
                                    {t("community_search.business")}
                                  </p>
                                </Col>
                              </Row>
                              <Row className="mt-2">
                                <Col xs={4}>
                                  <div
                                    style={{
                                      background: "rgb(0, 140, 255)",
                                      width: "100%",
                                      height: "10px",
                                    }}
                                  />
                                </Col>
                                <Col xs={8}>
                                  <p style={{ textAlign: "left" }}>
                                    {t("community_search.government")}
                                  </p>
                                </Col>
                              </Row>
                              <Row className="mt-2">
                                <Col xs={4}>
                                  <div
                                    style={{
                                      background: "orange",
                                      width: "100%",
                                      height: "10px",
                                    }}
                                  />
                                </Col>
                                <Col xs={8}>
                                  <p style={{ textAlign: "left" }}>
                                    {" "}
                                    {t("community_search.philosopher")}
                                  </p>
                                </Col>
                              </Row>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <MinimapControl position="topright" />
                  </MapContainer>
                </Card>
              </Col>
              <Col sm={12}>
                <div
                  className="dashboard"
                  style={{ marginTop: "-4.5rem", marginBottom: "-1rem" }}
                >
                  <div className="all-card">
                    <Row style={{ paddingLeft: "10px" }}>
                      <Col>
                        <div className="card">
                          <div className="card-body ">
                            <h5 className="card-title text-title">
                              {t("community_search.all_network")}
                            </h5>
                            <h2 className="card-text text-amount">
                              {count_co} {t("community_search.network")}
                            </h2>
                          </div>
                        </div>
                      </Col>
                      <Col>
                        <div className="card">
                          <div className="card-body ">
                            <h5 className="card-title text-title">
                              {t("community_search.national_network")}
                            </h5>
                            <h2 className="card-text text-amount">
                              {count_in_country} {t("community_search.network")}
                            </h2>
                          </div>
                        </div>
                      </Col>
                      <Col>
                        <div className="card">
                          <div className="card-body ">
                            <h5 className="card-title text-title">
                              {t("community_search.inter_network")}
                            </h5>
                            <h2 className="card-text text-amount">
                              {count_out_country}{" "}
                              {t("community_search.network")}
                            </h2>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Col>
              {/* เครือข่ายระดับชาติ */}
              <Col sm={12}>
                <Accordion
                  className="bg-title body-universitys"
                  defaultActiveKey="0"
                >
                  <Accordion.Item
                    eventKey="0"
                    style={{
                      backgroundColor: "transparent",
                      border: "none",
                    }}
                  >
                    <Accordion.Header>
                      <h3
                        style={{
                          textAlign: "left",
                          color: "#212529",
                          marginBottom: "2rem",
                        }}
                      >
                        {t("community_search.national_network")}
                      </h3>
                    </Accordion.Header>
                    <Accordion.Body>
                      <Row style={{ paddingLeft: "1rem" }}>
                        {national.length ? (
                          <>
                            {national.map((list) => (
                              <>
                                {list.data.length > 0 ? (
                                  <Col
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    lg={6}
                                    xl={6}
                                    xxl={4}
                                    style={{ paddingBottom: "1.5rem" }}
                                  >
                                    <div
                                      className="card-university"
                                      style={{
                                        backgroundColor: "#fff",
                                        borderRadius: "15px",
                                      }}
                                    >
                                      <div
                                        className="card-header"
                                        style={{
                                          padding: "1.5rem 1.5rem 0px",
                                          borderRadius: "15px  15px 0 0",
                                        }}
                                      >
                                        <h5>{list.title}</h5>
                                        <p
                                          style={{
                                            textAlign: "left",
                                            marginTop: "0.5rem",
                                            marginBottom: "0.5rem",
                                          }}
                                        >
                                          {t("community_search.amount")}{" "}
                                          {list.data.length} {list.item_name}
                                        </p>
                                      </div>
                                      <div className="card-university-body">
                                        {list.data.length ? (
                                          <div className="list">
                                            {list.data
                                              .sort((x, y) =>
                                                x.co_researcher_name_th.localeCompare(
                                                  y.co_researcher_name_th,
                                                  "th"
                                                )
                                              )
                                              .map((listdata) => (
                                                <div className="link_feature">
                                                  <Link
                                                    to={`/CoResearcher/expertise?co_researcher_id=${btoa(
                                                      listdata.co_researcher_id
                                                    )}`}
                                                    className="linkexternal"
                                                  >
                                                    <Row
                                                      className="p-2 align-items-center justify-content-md-center "
                                                      style={{
                                                        width: "100%",
                                                      }}
                                                      data-bs-toggle="tooltip"
                                                      data-bs-placement="bottom"
                                                      title={
                                                        listdata.co_researcher_name_th
                                                      }
                                                    >
                                                      <Col md="2">
                                                        <img
                                                          className="rounded-circle mx-auto d-block img-researcher"
                                                          width={40}
                                                          height={40}
                                                          src={
                                                            listdata.co_researcher_image
                                                          }
                                                        />
                                                      </Col>
                                                      <Col md="10">
                                                        <text>
                                                          {listdata[
                                                            t(
                                                              "community_search.com_name"
                                                            )
                                                          ]
                                                            ? listdata[
                                                                t(
                                                                  "community_search.com_name"
                                                                )
                                                              ]
                                                            : listdata.co_researcher_name_th}
                                                        </text>
                                                      </Col>
                                                    </Row>
                                                  </Link>
                                                  <hr className="line-researcher" />
                                                </div>
                                              ))}
                                          </div>
                                        ) : (
                                          <div className="list pt-4">
                                            {t("notfound")}
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  </Col>
                                ) : null}
                              </>
                            ))}
                          </>
                        ) : (
                          <div className="pt-4">{t("notfound")}</div>
                        )}
                      </Row>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </Col>
              {/* เครือข่ายระดับนานาชาติ */}
              <Col sm={12} style={{ marginTop: "2rem" }}>
                <Accordion
                  className="bg-title body-universitys"
                  defaultActiveKey="0"
                >
                  <Accordion.Item
                    eventKey="0"
                    style={{
                      backgroundColor: "transparent",
                      border: "none",
                    }}
                  >
                    <Accordion.Header>
                      <h3
                        style={{
                          textAlign: "left",
                          color: "#212529",
                          marginBottom: "2rem",
                        }}
                      >
                        {t("community_search.inter_network")}
                      </h3>
                    </Accordion.Header>
                    <Accordion.Body>
                      <Row style={{ paddingLeft: "1rem" }}>
                        {international.length ? (
                          <>
                            {international.length > 0 ? (
                              <Col
                                xs={12}
                                sm={12}
                                md={12}
                                lg={6}
                                xl={6}
                                xxl={4}
                                style={{ paddingBottom: "1.5rem" }}
                              >
                                <div
                                  className="card-university"
                                  style={{
                                    backgroundColor: "#fff",
                                    borderRadius: "15px",
                                  }}
                                >
                                  <div
                                    className="card-header"
                                    style={{
                                      padding: "1.5rem 1.5rem 0px",
                                      borderRadius: "15px  15px 0 0",
                                    }}
                                  >
                                    <h5>
                                      {t("community_search.inter_network")}
                                    </h5>
                                    <p
                                      style={{
                                        textAlign: "left",
                                        marginTop: "0.5rem",
                                        marginBottom: "0.5rem",
                                      }}
                                    >
                                      {t("community_search.amount")}{" "}
                                      {international.length}{" "}
                                      {t("community_search.network")}
                                    </p>
                                  </div>
                                  <div className="card-university-body">
                                    {international.length ? (
                                      <div className="list">
                                        {international
                                          .sort((x, y) =>
                                            x.co_researcher_name_th.localeCompare(
                                              y.co_researcher_name_th,
                                              "th"
                                            )
                                          )
                                          .map((listdata) => (
                                            <div className="link_feature">
                                              <Link
                                                to={`/CoResearcher/expertise?co_researcher_id=${btoa(
                                                  listdata.co_researcher_id
                                                )}`}
                                                className="linkexternal"
                                              >
                                                <Row
                                                  className="p-2 align-items-center justify-content-md-center "
                                                  style={{
                                                    width: "100%",
                                                  }}
                                                  data-bs-toggle="tooltip"
                                                  data-bs-placement="bottom"
                                                  title={
                                                    listdata.co_researcher_name_th
                                                  }
                                                >
                                                  <Col sm={2} md={2}>
                                                    <img
                                                      className="rounded-circle mx-auto d-block img-researcher"
                                                      width={40}
                                                      height={40}
                                                      src={
                                                        listdata.co_researcher_image
                                                      }
                                                    />
                                                  </Col>
                                                  <Col sm={10} md={10}>
                                                    <text>
                                                      {listdata[
                                                        t(
                                                          "community_search.com_name"
                                                        )
                                                      ]
                                                        ? listdata[
                                                            t(
                                                              "community_search.com_name"
                                                            )
                                                          ]
                                                        : listdata.co_researcher_name_th}
                                                    </text>
                                                  </Col>
                                                </Row>
                                              </Link>
                                              <hr className="line-researcher" />
                                            </div>
                                          ))}
                                      </div>
                                    ) : (
                                      <div className="list pt-4">
                                        {t("notfound")}
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </Col>
                            ) : null}
                          </>
                        ) : (
                          <div className="pt-4">{t("notfound")}</div>
                        )}
                      </Row>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </Col>
            </>
          )}
        </Row>
      </div>
    </div>
  );
}

export default withRouter(SearchPageCoRe);
