import React from "react";
import { Bar } from "react-chartjs-2";
const GroupedBar = (props) => {
  const { chartData } = props;
  return (
    <div>
      {chartData.map((data, i) => {
        return (
          <Bar
            key={i}
            data={data}
            options={{
              responsive: true,
              scales: {
                y: {
                  title: {
                    display: true,
                    text: `ผลกระทบ`,
                  },
                  ticks: {
                    display: true,
                  },
                },
              },
            }}
          />
        );
      })}
    </div>
  );
};

export default GroupedBar;
