import React, { useState, useRef } from "react";

import { Row, Col, Modal, Button } from "react-bootstrap";
// import { useTranslation } from "react-i18next";

import { FaChevronCircleDown } from "react-icons/fa";

import "react-image-gallery/styles/css/image-gallery.css";

import "./style_concept.scss";
import no_img_product from "../../images/no_img_product.png";
import Youtubeshow from "../Detail/Youtubeshow";
import Imageshow from "../Detail/Imageshow";

const KnowledgeDetail = (props) => {
  // const { t } = useTranslation();
  const detailRef = useRef();

  const [show_detail, setshow_detail] = useState(false);
  const [data_detail, setdata_detail] = useState({});

  const [data_outcome, setdata_outcome] = useState({});
  const [data_knowledge, setdata_knowledge] = useState({});
  const [img_knowledge, setimg_knowledge] = useState([]);
  const [img_output, setimg_output] = useState([]);
  const [img_outcome, setimg_outcome] = useState([]);

  const [modalShow_kn, setModalShow_kn] = React.useState(false);
  const [show, setShow] = useState(false);

  const handleCloseKN = () => {
    setModalShow_kn(false);
    // console.log("close!!!", modalShow_kn);
  };
  const handleClose = () => {
    setShow(false);
    // console.log("close!!!", show);
  };

  const handleShowKnowledge = (data) => {
    setModalShow_kn(true);
    setdata_knowledge(data);
    setimg_knowledge(data.images);
  };
  const handleShowOutcome = (data) => {
    setShow(true);
    setdata_outcome(data);
    setimg_outcome(data.images);
  };

  const { output, output_length, loading } = props;

  // console.log("output ", output);

  const onClickOutput = (output_id) => {
    setshow_detail(true);
    for (var i = 0; i < output_length; i++) {
      if (output[i].output_id === output_id) {
        setdata_detail(output[i]);
        setimg_output(output[i].images);
      }
    }
    detailRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const imagesOutput = [];
  if (data_detail.output_image) {
    imagesOutput.push({
      original: data_detail.output_image,
      thumbnail: data_detail.output_image,
    });
  }
  img_output.forEach(function (data) {
    if (data.output_image) {
      imagesOutput.push({
        original: data.output_image,
        thumbnail: data.output_image,
      });
    }
  });

  const imagesKn = [];

  if (data_knowledge.knowledge_image) {
    imagesKn.push({
      original: data_knowledge.knowledge_image,
      thumbnail: data_knowledge.knowledge_image,
    });
  }
  img_knowledge.forEach(function (data) {
    if (data.knowledge_images_name) {
      imagesKn.push({
        original: data.knowledge_images_name,
        thumbnail: data.knowledge_images_name,
      });
    }
  });

  const imagesOutcome = [];

  if (data_outcome.outcome_image) {
    imagesOutcome.push({
      original: data_outcome.outcome_image,
      thumbnail: data_outcome.outcome_image,
    });
  }
  img_outcome.forEach(function (data) {
    if (data.outcome_image) {
      imagesOutcome.push({
        original: data.outcome_image,
        thumbnail: data.outcome_image,
      });
    }
  });

  // console.log("show_detail", data_detail);
  return (
    <div className="div-knowledge-detail">
      {loading ? (
        <div className="demo"></div>
      ) : (
        <Row className="div-detail">
          {output_length > 0 ? (
            <>
              <h5 style={{ paddingTop: "1rem", textAlign: "left" }}>
                ผลผลิตทั้งหมด {output_length} รายการ
              </h5>
              <div className="line-bottom" style={{ margin: "1rem 0rem" }} />

              <Col sm={12}>
                <Row>
                  {output.map((data, index) => (
                    <Col xs={12} sm={12} md={6} lg={6} xl={4} key={index}>
                      <div
                        className="card-output"
                        style={{ backgroundColor: "#fff" }}
                        onClick={() => onClickOutput(data.output_id)}
                      >
                        <img
                          className="img-output"
                          src={
                            data.output_image
                              ? data.output_image
                              : no_img_product
                          }
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null; // prevents looping
                            currentTarget.src = no_img_product;
                          }}
                          alt="output_image"
                        />
                        <div className="output-descreption">
                          <Row>
                            <Col xs={10}>
                              <h6>
                                <b>{data.output_name}</b>
                              </h6>
                            </Col>
                            <Col xs={2} style={{ textAlign: "left" }}>
                              <FaChevronCircleDown
                                size={30}
                                className="arrow-down"
                              />
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </Col>
                  ))}
                </Row>
              </Col>
            </>
          ) : (
            <h6 style={{ textAlign: "center" }}>ไม่พบข้อมูล</h6>
          )}
          <Col sm={12}>
            <div className="line-bottom" style={{ margin: "1rem 3rem" }} />
          </Col>
          <div
            style={{ paddingTop: "2rem", textAlign: "left" }}
            ref={detailRef}
          >
            {show_detail ? (
              <Col sm={12} className="div-knowledge-tree">
                <ul className="tree">
                  <li className="parent">
                    <div className="card card-knowledge-tree">
                      <Row>
                        <Col sm={12} style={{ marginBottom: "1rem" }}>
                          <h3>{data_detail.output_name}</h3>
                          <hr />
                        </Col>

                        <Imageshow
                          image={
                            data_detail.output_image
                              ? data_detail.output_image
                              : no_img_product
                          }
                          images={imagesOutput}
                          imageshow={imagesOutput}
                          lg={5}
                        />

                        <Youtubeshow
                          url_video={
                            data_detail.output_video
                              ? data_detail.output_video
                              : ""
                          }
                          xs={12}
                          sm={12}
                          md={12}
                          lg={7}
                          paddingTop={"0"}
                        />

                        <Col
                          xs={12}
                          sm={12}
                          md={12}
                          lg={12}
                          style={{ paddingTop: "2rem" }}
                        >
                          <hr />
                          <div className="knowledge-tree-detail">
                            <p style={{ textAlign: "left" }}>
                              <b>รายละเอียด : </b> {data_detail.output_detail}
                            </p>
                            <p>
                              <b>กระบวนการ : </b>
                              {data_detail.output_process.replace(
                                /<[^>]+>|&nbsp;|&quot;/g,
                                ""
                              )}
                            </p>
                            <p>
                              {" "}
                              <b>ลิงค์ที่เกี่ยวข้อง : </b>
                              <a
                                href={data_detail.output_link}
                                rel="noreferrer"
                                target="_blank"
                              >
                                {data_detail.output_link}{" "}
                              </a>
                            </p>
                          </div>
                        </Col>
                      </Row>
                    </div>
                    <ul style={{ marginLeft: "1rem" }}>
                      <li className="child" style={{ paddingLeft: "35px" }}>
                        <div style={{ paddingTop: "3rem" }}>
                          <div className="card knowledge-tree-outcome">
                            <h3>ความรู้เดิม (Knowledge)</h3>
                            <div
                              className="line-bottom"
                              style={{ margin: "1rem 3rem" }}
                            />
                            {data_detail.knowledges.length ? (
                              <Row>
                                {data_detail.knowledges.map((data, index) => (
                                  <Col
                                    xs={12}
                                    sm={12}
                                    md={6}
                                    lg={6}
                                    xl={4}
                                    key={index}
                                  >
                                    <div
                                      className="card-output"
                                      style={{
                                        backgroundColor: "#5d1809",
                                      }}
                                      onClick={() => handleShowKnowledge(data)}
                                    >
                                      <img
                                        className="img-output"
                                        src={
                                          data.knowledge_image
                                            ? data.knowledge_image
                                            : no_img_product
                                        }
                                        onError={({ currentTarget }) => {
                                          currentTarget.onerror = null; // prevents looping
                                          currentTarget.src = no_img_product;
                                        }}
                                        alt="knowledge_image"
                                      />
                                      <div className="output-descreption">
                                        <Row>
                                          <Col xs={10}>
                                            <h6 style={{ color: "#fff" }}>
                                              <b>{data.knowledge_name}</b>
                                            </h6>
                                          </Col>
                                          <Col
                                            xs={2}
                                            style={{ textAlign: "left" }}
                                          >
                                            <FaChevronCircleDown
                                              size={30}
                                              className="arrow-down"
                                              style={{ color: "#fff" }}
                                            />
                                          </Col>
                                        </Row>
                                      </div>
                                    </div>
                                  </Col>
                                ))}
                              </Row>
                            ) : (
                              <h5 style={{ textAlign: "center" }}>
                                ไม่พบข้อมูล
                              </h5>
                            )}
                          </div>
                        </div>
                      </li>
                      <li className="child" style={{ paddingLeft: "35px" }}>
                        <div style={{ paddingTop: "3rem" }}>
                          <div className="card knowledge-tree-outcome">
                            <h3>ผลลัพธ์ (Outcome)</h3>
                            <div
                              className="line-bottom"
                              style={{ margin: "1rem 3rem" }}
                            />
                            {data_detail.outcomes.length ? (
                              <Row>
                                {data_detail.outcomes.map((data, index) => (
                                  <Col
                                    xs={12}
                                    sm={12}
                                    md={6}
                                    lg={6}
                                    xl={4}
                                    key={index}
                                  >
                                    <div
                                      className="card-output"
                                      style={{
                                        backgroundColor: "#5d1809",
                                      }}
                                      onClick={() => handleShowOutcome(data)}
                                    >
                                      <img
                                        className="img-output"
                                        src={
                                          data.outcome_image
                                            ? data.outcome_image
                                            : no_img_product
                                        }
                                        onError={({ currentTarget }) => {
                                          currentTarget.onerror = null; // prevents looping
                                          currentTarget.src = no_img_product;
                                        }}
                                        alt="outcome_image"
                                      />
                                      <div className="output-descreption">
                                        <Row>
                                          <Col xs={10}>
                                            <h6 style={{ color: "#fff" }}>
                                              <b>{data.outcome_detail}</b>
                                            </h6>
                                          </Col>
                                          <Col
                                            xs={2}
                                            style={{ textAlign: "left" }}
                                          >
                                            <FaChevronCircleDown
                                              size={30}
                                              className="arrow-down"
                                              style={{ color: "#fff" }}
                                            />
                                          </Col>
                                        </Row>
                                      </div>
                                    </div>
                                  </Col>
                                ))}
                              </Row>
                            ) : (
                              <h5 style={{ textAlign: "center" }}>
                                ไม่พบข้อมูล
                              </h5>
                            )}
                          </div>
                        </div>
                      </li>
                    </ul>
                  </li>
                </ul>
              </Col>
            ) : null}
          </div>
        </Row>
      )}
      <Modal
        show={modalShow_kn}
        onHide={handleCloseKN}
        animation={true}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton>
          <Modal.Title>{data_knowledge.knowledge_name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            {Object.keys(data_knowledge).length > 0 ? (
              <Imageshow
                image={
                  data_knowledge.knowledge_image
                    ? data_knowledge.knowledge_image
                    : no_img_product
                }
                images={data_knowledge.images}
                imageshow={imagesKn}
                lg={5}
              />
            ) : null}

            <Youtubeshow
              url_video={
                data_knowledge.knowledge_video
                  ? data_knowledge.knowledge_video
                  : ""
              }
              xs={12}
              sm={12}
              md={12}
              lg={7}
              paddingTop={"2rem"}
            />
            <Col sm={12} style={{ padding: "1rem 2rem 0 2rem" }}>
              <hr />
              <div style={{ padding: "0 2rem", textAlign: "left" }}>
                <p>
                  {" "}
                  <b>รายละเอียด : </b>
                  {data_knowledge.knowledge_detail}
                </p>
              </div>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseKN}>
            ปิด
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={show}
        onHide={handleClose}
        animation={true}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Imageshow
              image={
                data_outcome.outcome_image
                  ? data_outcome.outcome_image
                  : no_img_product
              }
              images={imagesOutcome}
              imageshow={imagesOutcome}
              lg={6}
            />

            <Youtubeshow
              url_video={
                data_outcome.outcome_video ? data_outcome.outcome_video : ""
              }
              xs={12}
              sm={12}
              md={12}
              lg={6}
              paddingTop={"2rem"}
            />
            <Col sm={12} style={{ padding: "1rem 2rem 0 2rem" }}>
              <hr />
              <div style={{ padding: "0 2rem" }}>
                <p>
                  {" "}
                  <b>รายละเอียด : </b>
                  {data_outcome.outcome_detail}
                </p>
              </div>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            ปิด
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default KnowledgeDetail;
