import { useEffect, useState, useRef } from "react";
import axios from "axios";
import token from "../../config/token.json";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import QRCode from "qrcode.react";
import no_img_product from "../../images/no_img_product.png";
import { FaChevronCircleDown } from "react-icons/fa";
import "react-image-gallery/styles/css/image-gallery.css";
import { FaPhoneSquareAlt, FaLine, FaFacebookSquare } from "react-icons/fa";

import { Row, Col } from "react-bootstrap";

import "./timeline.scss";
import Imageshow from "../Detail/Imageshow";
import { apiUrl } from "../../config/api.json";
const useStyles = makeStyles((theme) => ({
  appbar: {
    alignItems: "center",
    backgroundColor: "#fff",
    boxShadow: "none",
  },
  customLabel: {
    fontFamily: "Prompt",
    fontWeight: "bold",
    color: "black",
  },
  tabpanel: {
    marginLeft: "auto",
    marginRight: "auto",
  },

  cardlayout: {
    marginTop: 20,
    boxShadow:
      "rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px",
    [theme.breakpoints.up("sm")]: {
      marginTop: 0,
    },
  },

  tabsBg: {
    background:
      "linear-gradient(90deg,rgba(240, 99, 0, 1) 0%,rgba(255, 115, 0, 1) 38%,rgba(254, 148, 0, 1) 100%)",
  },

  paper: {
    padding: "6px 16px",
  },
  secondaryTail: {
    backgroundColor: theme.palette.secondary.main,
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-controls={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography style={{ fontFamily: "Prompt" }}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `scrollable-force-tab-${index}`,
    "aria-controls": `scrollable-force-tabpanel-${index}`,
  };
}

export default function DetailPage(props) {
  const classes = useStyles();
  const { concept_proposal_id } = props;

  const [educational, seteducational] = useState([]);
  const [working, setworking] = useState([]);
  const [purchasing, setpurchasing] = useState([]);
  const [purchasingDetail, setpurchasingDetail] = useState([]);
  const [loading, setloading] = useState(true);
  const [user, setuser] = useState([]);

  const [valuePage1, setValuePage1] = useState(0);

  const getParamsId = () => {
    const windowUrl = window.location.search;
    const params = new URLSearchParams(windowUrl);
    return params.get("user_idcard");
  };
  const idencrypt = getParamsId();

  const divdetailPuRef = useRef();
  const handleChangePage1 = (event, newValue) => {
    setValuePage1(newValue);
  };

  let id = concept_proposal_id;

  const geteducational = async () => {
    try {
      const result = await axios.get(`${apiUrl}/api/get/us-educational/${id}`, {
        headers: {
          "content-type": "application/json",
          "x-access-token": token.accesstoken,
        },
      });
      seteducational(result.data);
      setloading(false);
    } catch (err) {
      setloading(false);
    }
  };

  // useEffect(() => {
  //   window.scrollTo(0, 0);
  //   if (idencrypt === null || idencrypt === "") {
  //     props.history.push({
  //       pathname: "/SearchResearcher",
  //     });
  //   }
  //   axios
  //     .get(`${apiUrl}/api/get/bb-user/${idencrypt}`, {
  //       headers: {
  //         "content-type": "application/json",
  //         "x-access-token": token.accesstoken,
  //       },
  //     })
  //     .then(async (result) => {
  //       console.log("test", result.data[0]);
  //       setuser(result.data);
  //     });
  // }, []);

  const getexperience = async () => {
    const result = await axios.get(
      `${apiUrl}/api/get/working-experience/${id}`,
      {
        headers: {
          "content-type": "application/json",
          "x-access-token": token.accesstoken,
        },
      }
    );
    setworking(result.data);
  };

  const getbbuser = async () => {
    const result = await axios
      .get(`${apiUrl}/api/get/bb-user/${idencrypt}`, {
        headers: {
          "content-type": "application/json",
          "x-access-token": token.accesstoken,
        },
      })
      .then(async (result) => {
        // console.log("test", result.data[0]);
        setuser(result.data);
      });
  };

  const getpurchasing = async () => {
    const result = await axios.get(
      `${apiUrl}/api/get/progress-report/report-purchasing/${id}`,
      {
        headers: {
          "content-type": "application/json",
          "x-access-token": token.accesstoken,
        },
      }
    );
    setpurchasing(result.data);
  };

  useEffect(() => {
    geteducational();
    getexperience();
    getpurchasing();
    getbbuser();
  }, []);

  const onClickPu = (index) => {
    // console.log(index);
    setpurchasingDetail([purchasing[index]]);
    divdetailPuRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const divdetailPu = purchasingDetail.map((listValue, index) => {
    const imagespu = [];
    if (listValue.purchasing_equipment_image) {
      imagespu.push({
        original: `https://researcher.kims-rmuti.com/file-upload/equipment-upload/${listValue.purchasing_equipment_image}`,
        thumbnail: `https://researcher.kims-rmuti.com/file-upload/equipment-upload/${listValue.purchasing_equipment_image}`,
      });
    }
    listValue.images.forEach(function (data) {
      if (data.image_name) {
        imagespu.push({
          original: data.image_name,
          thumbnail: data.image_name,
        });
      }
    });

    return (
      <div
        className="blog-card "
        key={index}
        style={{ padding: "1.5rem", marginBottom: "0" }}
      >
        <Row>
          <Col sm={12}>
            <div className="description">
              <h1>{listValue.purchasing_equipment_name_th}</h1>
              <hr style={{ padding: "0 3rem" }} />
            </div>
          </Col>

          <Imageshow
            image={
              listValue.purchasing_equipment_image
                ? `https://researcher.kims-rmuti.com/file-upload/equipment-upload/${listValue.purchasing_equipment_image}`
                : no_img_product
            }
            images={imagespu}
            imageshow={imagespu}
            lg={5}
          />

          <Col sm={12} md={6}>
            {/* <div className="description"> */}
            <p>
              <b>ประเภทครุภัณฑ์ : </b> {listValue.durable_articles_name_th}
            </p>
            <p>
              <b>งบประมาณครุภัณฑ์ : </b>{" "}
              {listValue.purchasing_equipment_budget
                ? new Intl.NumberFormat("en-US").format(
                    listValue.purchasing_equipment_budget
                  )
                : "-"}{" "}
              บาท
            </p>
            <p>
              <b>ชื่อที่ตั้งครุภัณฑ์ : </b>{" "}
              {listValue.purchasing_equipment_location}
            </p>
            <p>
              <b>รายละเอียดครุภัณฑ์ : </b> {listValue.award_detail}
            </p>
            <p>
              <b>เหตุผลและความจำเป็น : </b> {listValue.award_detail}
            </p>
            {/* </div> */}
          </Col>
        </Row>
      </div>
    );
  });

  const Listtimeline = (obj) => {
    let { color, listValue, index } = obj;
    return (
      <li key={index}>
        <div className="date" style={{ backgroundColor: color }}>
          {listValue.educational_status}ปี {listValue.educational_graduation}
        </div>
        <div className="title"> สถาบัน : {listValue.educational_Institute}</div>
        <div className="descr">คณะ : {listValue.educational_faculty}</div>
        <div className="descr">สาขา : {listValue.educational_major}</div>
      </li>
    );
  };

  const Workingtimeline = (obj) => {
    let { color, listValue, index } = obj;
    return (
      <li key={index}>
        <div className="date" style={{ backgroundColor: color }}>
          {listValue.working_experience_star}
          {" - "}
          {listValue.working_experience_stop}
        </div>
        <div className="title">
          {" "}
          ตำแหน่ง : {listValue.working_experience_position}
        </div>
        <div className="descr">หน่วยงาน : {listValue.working_section}</div>
      </li>
    );
  };
  // console.log("pu : ", purchasing);
  return (
    <div>
      {loading ? (
        <div className="demo"></div>
      ) : (
        <>
          <AppBar
            position="static"
            color="default"
            className={classes.appbar}
            style={{ borderRadius: "10px 10px 0 0", paddingTop: "2rem" }}
          >
            <Tabs
              value={valuePage1}
              onChange={handleChangePage1}
              variant="scrollable"
              scrollButtons="on"
              TabIndicatorProps={{
                style: { background: "rgb(252, 113, 0)" },
              }}
              textColor="secondary"
              aria-label="scrollable tabs menu"
            >
              <Tab
                label="ประวัติการศึกษา"
                className={classes.customLabel}
                {...a11yProps(0)}
              />

              <Tab
                label="ประสบการณ์ทำงาน"
                className={classes.customLabel}
                {...a11yProps(1)}
              />
              <Tab
                label="ครุภัณฑ์"
                className={classes.customLabel}
                {...a11yProps(2)}
              />
              <Tab
                label="ข้อมูลติดต่อ"
                className={classes.customLabel}
                {...a11yProps(3)}
              />
            </Tabs>
          </AppBar>

          <div className="div-expertise">
            <TabPanel
              value={valuePage1}
              index={0}
              style={{ padding: "1.5rem 0  0" }}
            >
              {educational.length ? (
                <div className="timeline-researcher">
                  <ul>
                    {educational.map((listValue, index) => {
                      const color = [
                        "#41516C",
                        "#FBCA3E",
                        "#E24A68",
                        "#1B5F8C",
                        "#4CADAD",
                      ];
                      return (
                        <Listtimeline
                          color={color[index]}
                          key={index}
                          listValue={listValue}
                        />
                      );
                    })}
                  </ul>
                </div>
              ) : (
                <h6 style={{ textAlign: "center" }}>ไม่พบข้อมูล</h6>
              )}
            </TabPanel>

            <TabPanel
              value={valuePage1}
              index={1}
              style={{ padding: "1.5rem 0  0" }}
            >
              {working.length ? (
                <div className="timeline-researcher">
                  <ul>
                    {working.map((listValue, index) => {
                      const color = [
                        "#41516C",
                        "#FBCA3E",
                        "#E24A68",
                        "#1B5F8C",
                        "#4CADAD",
                      ];
                      return (
                        <Workingtimeline
                          color={color[index]}
                          key={index}
                          listValue={listValue}
                        />
                      );
                    })}
                  </ul>
                </div>
              ) : (
                <h6 style={{ textAlign: "center" }}>ไม่พบข้อมูล</h6>
              )}
            </TabPanel>
            <TabPanel
              value={valuePage1}
              index={2}
              style={{ padding: "0 1rem" }}
              className="re-expertise-detail"
            >
              {purchasing.length ? (
                <>
                  <h5 style={{ paddingTop: "1rem", textAlign: "left" }}>
                    ครุภัณฑ์ทั้งหมด {purchasing.length} รายการ
                  </h5>
                  <hr />
                  <Row style={{ paddingRight: "2rem" }}>
                    {purchasing.map((data, index) => (
                      <Col xs={12} sm={12} md={6} lg={6} xl={4} key={index}>
                        <div
                          className="card-output"
                          style={{ backgroundColor: "#fff" }}
                          onClick={() => onClickPu(index)}
                        >
                          <img
                            className="img-output"
                            src={
                              data.purchasing_equipment_image
                                ? `https://researcher.kims-rmuti.com/file-upload/equipment-upload/${data.purchasing_equipment_image}`
                                : no_img_product
                            }
                            alt="output_image"
                          />
                          <div className="output-descreption">
                            <Row>
                              <Col xs={10}>
                                <h6>
                                  <b>{data.purchasing_equipment_name_th}</b>
                                </h6>
                              </Col>
                              <Col xs={2} style={{ textAlign: "left" }}>
                                <FaChevronCircleDown
                                  size={30}
                                  className="arrow-down"
                                />
                              </Col>
                            </Row>
                          </div>
                        </div>
                      </Col>
                    ))}
                  </Row>
                  <div
                    className="line-bottom"
                    style={{ margin: "2rem 1rem" }}
                  />
                  <div ref={divdetailPuRef} style={{ marginTop: "2rem" }}>
                    {divdetailPu}
                  </div>
                </>
              ) : (
                <h6 style={{ textAlign: "center" }}>ไม่พบข้อมูล</h6>
              )}
            </TabPanel>

            <TabPanel
              value={valuePage1}
              index={3}
              style={{ padding: "1.5rem 0  0" }}
            >
              {user.length ? (
                <div>
                  {user.map((listValue, index) => {
                    return (
                      <Row
                        style={{
                          backgroundColor: "#fff",
                          padding: "2rem",
                          marginBottom: "2rem",
                          borderRadius: "10px",
                        }}
                      >
                        <Col xs={12} style={{ textAlign: "center" }}>
                          <Row>
                            <Col
                              lg={4}
                              // style={{ textAlign: "left" }}
                              key={index}
                            >
                              <h5>
                                QR Code:
                                <FaLine
                                  size={40}
                                  color={"#0ec863"}
                                  className="icon-socail"
                                />
                              </h5>
                              <div>
                                <p className="card-title">
                                  &nbsp;{" "}
                                  {listValue.active_line === 1 ? (
                                    <QRCode
                                      value={`https://line.me/ti/p/~${listValue.user_line}`}
                                      style={{ marginRight: 60 }}
                                    />
                                  ) : (
                                    <p>
                                      ไม่พบผู้ใช้ หรือผู้ใช้ไม่อนุญาตให้ค้นหา
                                    </p>
                                  )}
                                  <div></div>
                                </p>
                              </div>
                            </Col>
                            <Col lg={3}>
                              <h5>
                                QR Code:
                                <FaFacebookSquare
                                  size={40}
                                  color={"#1877F2"}
                                  className="icon-socail"
                                />
                              </h5>

                              <div>
                                <p className="card-title" textAlign="left">
                                  &nbsp;{" "}
                                  {listValue.active_facebook === 1 ? (
                                    <QRCode
                                      value={listValue.user_facebook}
                                      style={{ marginRight: 70 }}
                                    />
                                  ) : (
                                    <p>
                                      ไม่พบผู้ใช้ หรือผู้ใช้ไม่อนุญาตให้ค้นหา
                                    </p>
                                  )}
                                  <div></div>
                                </p>
                              </div>
                            </Col>
                            <Col lg={4}>
                              <h5>
                                QR Code
                                <FaPhoneSquareAlt
                                  size={40}
                                  color={"#cd8503"}
                                  className="icon-socail"
                                />
                              </h5>

                              <div>
                                <p className="card-title" textAlign="left">
                                  &nbsp;{" "}
                                  {listValue.active_mobile === 1 ? (
                                    <QRCode
                                      value={listValue.user_mobile}
                                      style={{ marginRight: 70 }}
                                    />
                                  ) : (
                                    <p>
                                      ไม่พบผู้ใช้ หรือผู้ใช้ไม่อนุญาตให้ค้นหา
                                    </p>
                                  )}
                                  <div></div>
                                </p>
                              </div>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    );
                  })}
                </div>
              ) : (
                <h6 style={{ textAlign: "center" }}>ไม่พบข้อมูล</h6>
              )}
            </TabPanel>
          </div>
        </>
      )}
    </div>
  );
}
