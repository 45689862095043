import React, { useState, useEffect, useRef } from "react";
import { CardTitle, Card, CardBody, Row, Col } from "reactstrap";
import BarChart from "../Chart";

import { Tabs, Tab } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { FaChevronCircleDown } from "react-icons/fa";

import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";

import axios from "axios";
import { apiUrl } from "../../config/api.json";
import { makeStyles } from "@material-ui/core/styles";
import Youtubeshow from "../Detail/Youtubeshow";
const useStyles = makeStyles((theme) => ({
  appbar: {
    alignItems: "center",
    backgroundColor: "#fff",
    boxShadow: "none",
  },
  root: {
    flexGrow: 1,
    width: "100%",
  },

  customLabel: {
    fontFamily: "Prompt",
    fontWeight: "bold",
    color: "black",
  },

  round: {
    borderRadius: "50%",
    border: "4px solid rgb(223, 223, 223)",
    width: "100%",
    boxShadow:
      "rgba(0, 0, 0, 0.293) 0px 19px 38px, rgba(0, 0, 0, 0.129) 0px 15px 12px",
    [theme.breakpoints.up("sm")]: {
      width: "65%",
    },
  },

  underlineCol: {
    borderBottom: "2px solid rgb(211, 211, 211)",
    paddingTop: 12,
  },

  tabpanel: {
    marginLeft: "auto",
    marginRight: "auto",
  },

  headerLabel: {
    position: "relative",
    fontFamily: "Prompt",
    fontWeight: "bold",
    color: "black",
    zIndex: 1,

    textAlign: "center",

    "&::after": {
      position: "absolute",
      content: '""',
      top: 10,
      borderBottom: "3px solid black",
      width: 120,
      left: "50%",
      marginTop: 10,
      marginLeft: "-60px",
      bottom: 0,
    },
  },

  cardlayout: {
    marginTop: 20,
    boxShadow:
      "rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px",
    [theme.breakpoints.up("sm")]: {
      marginTop: 0,
    },
  },

  tabsBg: {
    background:
      "linear-gradient(90deg,rgba(240, 99, 0, 1) 0%,rgba(255, 115, 0, 1) 38%,rgba(254, 148, 0, 1) 100%)",
  },
}));

const ImpactDetail = () => {
  const classes = useStyles();

  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);

  const [economy, seteconomy] = useState([]);
  const [society, setsociety] = useState([]);
  const [culture, setculture] = useState([]);
  const [environment, setenvironment] = useState([]);
  const [chartData, setChartData] = useState([]);

  const [economyDetail, seteconomyDetail] = useState({});
  const [societyDetail, setsocietyDetail] = useState({});
  const [cultureDetail, setcultureDetail] = useState({});
  const [environmentDetail, setenvironmentDetail] = useState({});

  const detaileconomyRef = useRef();
  const detailsocietyRef = useRef();
  const detailcultureRef = useRef();
  const detailenvironmentRef = useRef();

  const getParamsId = () => {
    const windowUrl = window.location.search;
    const params = new URLSearchParams(windowUrl);
    const project_id = params.get("project_id");
    if (!project_id) {
      const project_id = params.get("concep_id");
      return project_id;
    }
    return project_id;
  };
  const idencrypt = getParamsId();

  const getGraphaAll = async () => {
    try {
      const res = await axios.get(
        `${apiUrl}/api/get/concept_proposal-graphs/${atob(idencrypt)}`
      );
      seteconomy(res.data.economy);
      setsociety(res.data.social);
      setculture(res.data.culture);
      setenvironment(res.data.environment);
      setChartData([res.data.chartData]);

      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  const onClickeDetail = (index, type) => {
    if (type === "economy") {
      const data = economy[index];
      data.issues.forEach((el, index) => {
        const images = [];
        el.impacts.forEach((imp) => {
          imp.images.forEach((img) => {
            images.push({
              original: img.image_name,
              thumbnail: img.image_name,
            });
          });
        });
        data.issues[index]["img_side"] = images;
        // console.log(data.issues);
      });
      seteconomyDetail(data);
      detaileconomyRef.current.scrollIntoView({ behavior: "smooth" });
    } else if (type === "society") {
      const data = society[index];
      data.issues.forEach((el, index) => {
        const images = [];
        el.impacts.forEach((imp) => {
          imp.images.forEach((img) => {
            images.push({
              original: img.image_name,
              thumbnail: img.image_name,
            });
          });
        });
        data.issues[index]["img_side"] = images;
        // console.log(data.issues);
      });
      setsocietyDetail(data);
      detailsocietyRef.current.scrollIntoView({ behavior: "smooth" });
    } else if (type === "culture") {
      const data = culture[index];
      data.issues.forEach((el, index) => {
        const images = [];
        el.impacts.forEach((imp) => {
          imp.images.forEach((img) => {
            images.push({
              original: img.image_name,
              thumbnail: img.image_name,
            });
          });
        });
        data.issues[index]["img_side"] = images;
        // console.log(data.issues);
      });
      setcultureDetail(data);
      detailcultureRef.current.scrollIntoView({ behavior: "smooth" });
    } else if (type === "environment") {
      const data = environment[index];
      data.issues.forEach((el, index) => {
        const images = [];
        el.impacts.forEach((imp) => {
          imp.images.forEach((img) => {
            images.push({
              original: img.image_name,
              thumbnail: img.image_name,
            });
          });
        });
        data.issues[index]["img_side"] = images;
        // console.log(data.issues);
      });
      setenvironmentDetail(data);
      detailenvironmentRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    getGraphaAll();
  }, []);

  const FactorDetail = (obj) => {
    let { dataFactor, type_impact } = obj;
    return (
      <>
        {dataFactor.length ? (
          <Row className="justify-content-center">
            {dataFactor.map((ListItem, index) => (
              <Col key={index} xs={12} sm={12} md={6} lg={4} xl={4}>
                <div
                  className="card-output"
                  style={{ backgroundColor: "#fff", height: "auto" }}
                  onClick={() => onClickeDetail(index, type_impact)}
                >
                  <img
                    src={ListItem.outim}
                    alt="Cardimagecap"
                    className={classes.round}
                  />
                  <Row style={{ paddingTop: "1rem" }}>
                    <Col xs={10}>
                      <CardTitle
                        className={classes.customLabel}
                        style={{
                          fontFamily: "Prompt",
                          paddingTop: 15,
                          paddingBottom: 0,
                        }}
                      >
                        {ListItem.factor_name}
                      </CardTitle>
                    </Col>
                    <Col
                      xs={2}
                      style={{ textAlign: "left", marginTop: "1rem" }}
                    >
                      <FaChevronCircleDown size={30} className="arrow-down" />
                    </Col>
                  </Row>
                </div>
              </Col>
            ))}
          </Row>
        ) : (
          <h6>ไม่พบข้อมูล</h6>
        )}
      </>
    );
  };

  const DetailPage = (obj) => {
    let { dataDetail } = obj;
    return (
      <div>
        {Object.keys(dataDetail).length ? (
          <Col sm={12}>
            <div className="goal-detail">
              <h5 style={{ textAlign: "left" }}>
                <b> {dataDetail.factor_name}</b>{" "}
              </h5>
              <hr />
              {dataDetail.issues.length ? (
                <>
                  {dataDetail.issues.map((data, index) => (
                    <Row className="goal-row-detail">
                      <h6 style={{ textAlign: "left" }} key={index}>
                        <b>ประเด็น : {data.issues_name} </b>
                      </h6>
                      {data.impacts.length ? (
                        <div>
                          {data.impacts.map((d, index) => (
                            <>
                              <Row key={index}>
                                <Col sm={12}>
                                  <p
                                    style={{
                                      textAlign: "left",
                                      marginTop: "1rem",
                                    }}
                                    key={index}
                                  >
                                    {" "}
                                    - {d.impact_detail}{" "}
                                  </p>
                                </Col>

                                <Col sm={12} style={{ padding: "0 3rem" }}>
                                  <hr style={{ height: "0.5px" }} />
                                </Col>

                                {d.images.length ? (
                                  <>
                                    {d.images.length > 1 ? (
                                      <Col sm={12} md={12} lg={6} xl={6}>
                                        <ImageGallery
                                          showThumbnails={true}
                                          items={data.img_side}
                                        />
                                      </Col>
                                    ) : (
                                      <Col
                                        sm={12}
                                        md={12}
                                        lg={6}
                                        xl={6}
                                        className="div-image-knowledge"
                                      >
                                        {d.images.map((dataim, index) => (
                                          <img
                                            key={index}
                                            style={{ width: "100%" }}
                                            src={dataim.image_name}
                                            alt="goal_image"
                                          />
                                        ))}
                                      </Col>
                                    )}
                                  </>
                                ) : (
                                  <Col sm={12} md={12} lg={6} xl={6}>
                                    <div className="title-center">
                                      <h3
                                        style={{
                                          color: "#000",
                                          fontFamily: "Prompt",
                                        }}
                                      >
                                        ไม่มีรูปภาพ
                                      </h3>
                                    </div>
                                  </Col>
                                )}

                                <Youtubeshow
                                  url_video={
                                    d.vdos.length ? d.vdos[0].vdo_name : ""
                                  }
                                  xs={12}
                                  sm={12}
                                  md={12}
                                  lg={6}
                                  paddingTop={"0"}
                                  className={"goal-video"}
                                />
                              </Row>
                              <hr
                                style={{ height: "2px", marginTop: "2rem" }}
                              />
                            </>
                          ))}
                        </div>
                      ) : null}
                    </Row>
                  ))}
                </>
              ) : null}
            </div>
          </Col>
        ) : null}
      </div>
    );
  };

  // console.log("economy ", economy);
  return (
    <>
      {loading ? (
        <div className="demo"></div>
      ) : (
        <Card className="card-goal">
          <CardBody
            className="card-header-color"
            style={{ borderRadius: "10px" }}
          >
            <CardTitle
              tag="h5"
              style={{ padding: 5, color: "black", textAlign: "left" }}
            >
              {t("concept_proposal_page.impact.graph.header")}
            </CardTitle>
            <CardBody>
              {chartData[0] ? (
                <>
                  {chartData[0].datasets ? (
                    <Row className=" justify-content-center">
                      <Col md="12" lg="12">
                        <Card>
                          <CardBody>
                            <BarChart chartData={chartData} />
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  ) : (
                    <h6>ไม่พบข้อมูล</h6>
                  )}
                </>
              ) : (
                <h5 style={{ textAlign: "center" }}>ไม่พบข้อมูล</h5>
              )}
            </CardBody>
          </CardBody>

          <CardTitle tag="h5" className="card-title-goal">
            ประเภทผลกระทบ
          </CardTitle>
          <CardBody style={{ fontFamily: "Prompt" }}>
            <Tabs
              defaultActiveKey="economy"
              id="uncontrolled-tab-example"
              className="mb-3"
            >
              <Tab eventKey="economy" title="เศรษฐกิจ" tabClassName="economy">
                <div className="tab-table">
                  <FactorDetail
                    dataFactor={economy ? economy : []}
                    type_impact="economy"
                  />
                </div>
                <div ref={detaileconomyRef}>
                  <DetailPage dataDetail={economyDetail} />
                </div>
              </Tab>
              <Tab eventKey="society" title="สังคม" tabClassName="society">
                <div className="tab-table">
                  <FactorDetail
                    dataFactor={society ? society : []}
                    type_impact="society"
                  />
                </div>
                <div ref={detailsocietyRef}>
                  <DetailPage dataDetail={societyDetail} />
                </div>
              </Tab>
              <Tab eventKey="culture" title="วัฒนธรรม" tabClassName="culture">
                <div className="tab-table">
                  <FactorDetail
                    dataFactor={culture ? culture : []}
                    type_impact="culture"
                  />
                </div>
                <div ref={detailcultureRef}>
                  <DetailPage dataDetail={cultureDetail} />
                </div>
              </Tab>
              <Tab
                eventKey="environment"
                title="สิ่งแวดล้อม"
                tabClassName="environment"
              >
                <div className="tab-table">
                  <FactorDetail
                    dataFactor={environment ? environment : []}
                    type_impact="environment"
                  />
                </div>
                <div ref={detailenvironmentRef}>
                  <DetailPage dataDetail={environmentDetail} />
                </div>
              </Tab>
            </Tabs>
          </CardBody>
        </Card>
      )}
    </>
  );
};

export default ImpactDetail;
