import React from "react";
import "./styloading.scss";
function Divloading() {
  return (
    <div className="lds-ellipsis">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
}

export default Divloading;
