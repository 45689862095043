import React, { useEffect, useState } from "react";

import { Col, Row } from "reactstrap";

import "../Css/mapView.scss";
import {
  FaLink,
  FaPhoneSquareAlt,
  FaLine,
  FaFacebookSquare,
} from "react-icons/fa";
import QRCode from "qrcode.react";
import Button from "@material-ui/core/Button";
import PersonIcon from "@material-ui/icons/Person";
import CallIcon from "@material-ui/icons/Call";
import EmailIcon from "@material-ui/icons/Email";
import TwitterIcon from "@material-ui/icons/Twitter";
import InstagramIcon from "@material-ui/icons/Instagram";
import FacebookIcon from "@material-ui/icons/Facebook";
import LanguageIcon from "@material-ui/icons/Language";
import axios from "axios";
import { apiUrl } from "../../config/api.json";
export default function ContactPage(props) {
  const { co_researcher_id } = props;
  const [result, setresult] = useState({});
  const [location, setlocation] = useState([]);
  let id = atob(co_researcher_id);
  const getcoresearcher = async () => {
    const result = await axios.get(`${apiUrl}/api/get/co-researcher/${id}`);
    setresult(result.data[0]);
    setlocation([
      result.data[0].co_researcher_latitude,
      result.data[0].co_researcher_longitude,
    ]);
  };
  useEffect(() => {
    getcoresearcher();
  }, []);
  return (
    <div style={{ paddingLeft: "2rem" }}>
      <Row
        style={{
          backgroundColor: "#fff",
          padding: "2rem",
          marginBottom: "2rem",
          borderRadius: "10px",
        }}
      >
        <Col lg={3}>
          <Row>
            <Col xs={12} style={{ textAlign: "left" }}>
              <h5>ตำแหน่งที่ตั้ง :</h5>
            </Col>
            <Col
              xs={12}
              style={{
                textAlign: "left",
                width: "100%",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <div
                className="profile-image"
                style={{
                  align: "left",
                  width: "100%",
                  height: "50vh",
                  minHeight: "280px",
                  border: "1px solid #fff",
                  boxShadow: "18px 19px 0px -8px #c1c4c6db",
                }}
              >
                {location.length ? (
                  <iframe
                    style={{
                      align: "left",
                      width: "100%",
                      height: "100%",
                      borderRadius: "10px",
                    }}
                    title="location"
                    frameBorder={0}
                    scrolling="no"
                    marginHeight={0}
                    marginWidth={0}
                    src={`https://maps.google.com/maps?q=${location[0]},${location[1]}&hl=es&z=14&output=embed`}
                  />
                ) : null}
                {/* {location.length ? (
              <MapContainer
                center={location}
                zoom={6}
                scrollWheelZoom={true}
                zoomControl={false}
                style={{ width: "100%", height: "100%", borderRadius: "10px" }}
              >
                <TileLayer
                  attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <ZoomControl position="topright" />
                {markermap}
              </MapContainer>
            ) : null} */}
              </div>
            </Col>
          </Row>
        </Col>

        <Col lg={3} style={{ textAlign: "left" }}>
          <h5>ข้อมูลติดต่อ :</h5>
          <div style={{ padding: "2rem" }}>
            <p className="card-title" align="left">
              <PersonIcon /> {""}
              ผู้ประสานงาน : {""}
              {result.active_coordinator_name_th === 1
                ? result.coordinator_name_th
                : " "}{" "}
              {result.active_coordinator_lastname_th === 1
                ? result.coordinator_lastname_th
                : " "}{" "}
            </p>

            <p className="card-title" align="left">
              <CallIcon /> {""}
              โทรศัพท์ : {""}
              {result.active_co_researcher_phone === 1
                ? result.co_researcher_phone
                : " "}{" "}
            </p>
            <p className="card-title" align="left">
              <EmailIcon /> {""}
              Email : {""}
              {result.active_co_researcher_mail === 1 ? (
                <Button
                  a
                  href={`mailto:${result.co_researcher_mail}`}
                  target="_blank"
                >
                  {result.co_researcher_mail}{" "}
                </Button>
              ) : (
                " "
              )}{" "}
            </p>
            <p className="card-title" align="left">
              <FaLine size={25} />
              &nbsp; Line : {result.co_researcher_line}
            </p>
            <p className="card-title" align="left">
              <FacebookIcon />
              &nbsp; Facebook :{" "}
              {result.co_researcher_facebook ? (
                <Button a href={result.co_researcher_facebook} target="_blank">
                  {result.co_researcher_name_th}{" "}
                </Button>
              ) : (
                " "
              )}
            </p>

            <p className="card-title" align="left">
              <TwitterIcon />
              &nbsp; Twitter : {result.co_researcher_twitter}
            </p>

            <p className="card-title" align="left">
              <InstagramIcon />
              &nbsp; Instagram : {result.co_researcher_ig}
            </p>
            <p className="card-title" align="left">
              <LanguageIcon />
              &nbsp; Website :
              {result.co_researcher_website ? (
                <Button
                  a
                  href={result.co_researcher_website}
                  target="_blank"
                  style={{ fontSize: 16 }}
                >
                  {result.co_researcher_website.slice(8, 35)}
                </Button>
              ) : (
                " "
              )}
            </p>
          </div>
        </Col>

        <Col lg={2} style={{ textAlign: "left" }}>
          <h5>
            QR Code:
            <FaLine size={40} color={"#0ec863"} className="icon-socail" />
          </h5>
          <div>
            <p className="card-title" align="left">
              &nbsp;{" "}
              {result.active_co_researcher_line === 1 ? (
                <div>
                  <QRCode
                    value={`https://line.me/ti/p/~${result.co_researcher_line}`}
                    style={{ marginRight: 60 }}
                  />
                </div>
              ) : (
                <p>ไม่พบผู้ใช้ หรือผู้ใช้ไม่อนุญาตให้ค้นหา</p>
              )}
              &nbsp;
            </p>
          </div>
        </Col>
        <Col lg={2} style={{ textAlign: "left" }}>
          <h5>
            QR Code:
            <FaPhoneSquareAlt
              size={40}
              color={"#cd8503"}
              className="icon-socail"
            />
          </h5>

          <div>
            <p className="card-title" align="left">
              &nbsp;{" "}
              {result.active_co_researcher_phone === 1 ? (
                <div>
                  <QRCode
                    value={`tel:${result.co_researcher_phone}`}
                    style={{ marginRight: 70 }}
                  />
                </div>
              ) : (
                <p>ไม่พบผู้ใช้ หรือผู้ใช้ไม่อนุญาตให้ค้นหา</p>
              )}
            </p>
          </div>
        </Col>
        <Col lg={2} style={{ textAlign: "left" }}>
          <h5>
            QR Code:
            <FaFacebookSquare
              size={40}
              color={"#1877F2"}
              className="icon-socail"
            />
          </h5>

          <div>
            <p className="card-title" align="left">
              &nbsp;{" "}
              {result.active_co_researcher_facebook === 1 ? (
                <div>
                  <QRCode
                    value={`${result.co_researcher_facebook}`}
                    style={{ marginRight: 70 }}
                  />
                </div>
              ) : (
                <p>ไม่พบผู้ใช้ หรือผู้ใช้ไม่อนุญาตให้ค้นหา</p>
              )}
            </p>
          </div>
        </Col>
      </Row>
    </div>
  );
}
