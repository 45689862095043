import React, { useState, useEffect } from "react";
import D3Layer from "../D3Layer";
import { Row, Col, Accordion } from "react-bootstrap";
import {
  MapContainer,
  TileLayer,
  ZoomControl,
  LayerGroup,
} from "react-leaflet";

import noImg from "../../images/no-image.png";
import axios from "axios";
// import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { apiUrl } from "../../config/api.json";
const ProjectNetwork = (props) => {
  // const { t } = useTranslation();
  const [loading, setloading] = useState(true);
  // const [location, setlocation] = useState([]);
  const [dataMap, setdataMap] = useState({});
  const [rmuti_universitys, setrmuti_universitys] = useState([]);
  const [other_universitys, setother_universitys] = useState([]);
  const [co_researcher, setco_researcher] = useState([]);
  const [team_group, setteam_group] = useState([]);

  const getParamsId = () => {
    const windowUrl = window.location.search;
    const params = new URLSearchParams(windowUrl);
    const project_id = params.get("project_id");
    if (!project_id) {
      const project_id = params.get("concep_id");
      return [project_id, "/api/get/concept_proposal-map/"];
    }
    return [project_id, "/api/get/us-project-map/"];
  };

  const [idencrypt, url] = getParamsId();
  const mapData = async () => {
    try {
      const response = await axios.get(`${apiUrl}${url}${atob(idencrypt)}`);
      setdataMap(response.data);
      setco_researcher([
        {
          title: "ชุมชน",
          item_name: "ชุมชน",
          data: response.data.co_researcher.community,
        },
        {
          title: "หน่วยงานภาคธุรกิจ",
          item_name: "หน่วย",
          data: response.data.co_researcher.bussiness,
        },
        {
          title: "หน่วยงานภาครัฐ",
          item_name: "หน่วย",
          data: response.data.co_researcher.government,
        },
        {
          title: "ปราชณ์",
          item_name: "คน",
          data: response.data.co_researcher.expert,
        },
        {
          title: "หน่วยงานระดับนานาชาติ",
          item_name: "หน่วย",
          data: response.data.co_researcher.international,
        },
      ]);
      setrmuti_universitys(response.data.rmuti_universitys);
      setother_universitys(response.data.other_universitys);
      setteam_group(response.data.team_group);
      // console.log("map: res");
      setloading(false);
    } catch (err) {
      setloading(false);
    }
  };

  useEffect(() => {
    if (idencrypt === null || idencrypt === "")
      props.history.push({
        pathname: "/SearchProject",
      });
    mapData();
  }, []);
  // console.log("co_researcher ", co_researcher);
  return (
    <div style={{ marginRight: "-25px" }}>
      {loading ? (
        <div className="demo"></div>
      ) : (
        <Row>
          <Col sm={12}>
            <MapContainer
              center={[14.947, 103.793]}
              zoom={6}
              scrollWheelZoom={true}
              zoomControl={false}
              style={{
                width: "100%",
                minHeight: "450px",
                height: "70vh",
                margin: "0",
                zIndex: "0",
                borderRadius: "15px",
              }}
            >
              <TileLayer
                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />
              <LayerGroup>
                {Object.keys(dataMap).length > 0 ? (
                  <D3Layer location={dataMap} />
                ) : null}
              </LayerGroup>

              <ZoomControl position="topright" />
            </MapContainer>
          </Col>
          <Col sm={12}>
            <div
              className="dashboard"
              style={{ marginTop: "-4.5rem", marginBottom: "-1rem" }}
            >
              <div className="all-card">
                <Row>
                  <Col>
                    <div
                      className="card"
                      style={{ border: "2px solid rgb(0 0 0 / 10%)" }}
                    >
                      <div className="card-body ">
                        <h5 className="card-title text-title">
                          เครือข่ายทั้งหมด
                        </h5>
                        <h2 className="card-text text-amount">
                          {dataMap.count_all ? dataMap.count_all : 0} เครือข่าย
                        </h2>
                      </div>
                    </div>
                  </Col>
                  <Col>
                    <div
                      className="card"
                      style={{ border: "2px solid rgb(0 0 0 / 10%)" }}
                    >
                      <div className="card-body ">
                        <h5 className="card-title text-title">
                          เครือข่ายนักวิจัย
                        </h5>
                        <h2 className="card-text text-amount">
                          {dataMap.count_researcher
                            ? dataMap.count_researcher
                            : 0}{" "}
                          เครือข่าย
                        </h2>
                      </div>
                    </div>
                  </Col>
                  <Col>
                    <div
                      className="card"
                      style={{ border: "2px solid rgb(0 0 0 / 10%)" }}
                    >
                      <div className="card-body ">
                        <h5 className="card-title text-title">
                          เครือข่ายความร่วมมือ
                        </h5>
                        <h2 className="card-text text-amount">
                          {dataMap.count_coresearcher
                            ? dataMap.count_coresearcher
                            : 0}{" "}
                          เครือข่าย
                        </h2>
                      </div>
                    </div>
                  </Col>
                  <Col>
                    <div
                      className="card"
                      style={{ border: "2px solid rgb(0 0 0 / 10%)" }}
                    >
                      <div className="card-body ">
                        <h5 className="card-title text-title">
                          เครือข่ายความร่วมมืออื่นๆ
                        </h5>
                        <h2 className="card-text text-amount">
                          {dataMap.count_team_researcher
                            ? dataMap.count_team_researcher
                            : 0}{" "}
                          เครือข่าย
                        </h2>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
          {/*  เครือข่ายนักวิจัย */}
          <Col sm={12}>
            <Accordion
              style={{ padding: "10px 25px 0 25px" }}
              className="bg-title body-universitys"
              defaultActiveKey="0"
            >
              <Accordion.Item
                eventKey="0"
                style={{
                  backgroundColor: "transparent",
                  border: "none",
                }}
              >
                <Accordion.Header>
                  <h3
                    style={{
                      textAlign: "left",
                      color: "#212529",
                      marginBottom: "2rem",
                    }}
                  >
                    เครือข่ายนักวิจัย
                  </h3>
                </Accordion.Header>
                <Accordion.Body>
                  <Row style={{ paddingLeft: "1rem" }}>
                    {/* มทร.อีสาน */}
                    {rmuti_universitys.map((list) => (
                      <>
                        {list.data.length > 0 ? (
                          <Col
                            xs={12}
                            sm={12}
                            md={12}
                            lg={6}
                            xl={6}
                            xxl={4}
                            style={{ paddingBottom: "1.5rem" }}
                          >
                            <div
                              className="card-university"
                              style={{
                                backgroundColor: "#fff",
                                borderRadius: "15px",
                              }}
                            >
                              <div
                                className="card-header"
                                style={{
                                  padding: "1.5rem 1.5rem 0px",
                                  borderRadius: "15px  15px 0 0",
                                }}
                              >
                                <h5>{list.name}</h5>
                                <p
                                  style={{
                                    textAlign: "left",
                                    marginTop: "0.5rem",
                                    marginBottom: "0.5rem",
                                  }}
                                >
                                  จำนวน {list.data.length} คน
                                </p>
                              </div>
                              <div className="card-university-body">
                                {list.data.length ? (
                                  <div className="list">
                                    {list.data.map((listdata) => (
                                      <div className="link_feature">
                                        <Link
                                          to={`/Researcher/locations?user_idcard=${btoa(
                                            listdata.user_idcard
                                          )}`}
                                          className="linkexternal"
                                        >
                                          <Row
                                            className="p-2 align-items-center justify-content-md-center"
                                            style={{ width: "100%" }}
                                          >
                                            <Col md="2">
                                              <img
                                                className="rounded-circle mx-auto d-block"
                                                width={40}
                                                height={40}
                                                src={
                                                  listdata.img
                                                    ? listdata.img
                                                    : "https://www.km-innovations.rmuti.ac.th/researcher/icon/researcher.png"
                                                }
                                                alt="logo-researcher"
                                              />
                                            </Col>
                                            <Col
                                              md="10"
                                              style={{ paddingLeft: "2rem" }}
                                            >
                                              {listdata.label}
                                            </Col>
                                          </Row>
                                        </Link>
                                      </div>
                                    ))}
                                  </div>
                                ) : (
                                  <div className="list pt-4">ไม่พบข้อมูล</div>
                                )}
                              </div>
                            </div>
                          </Col>
                        ) : null}
                      </>
                    ))}
                    {/* มหาลัยเครือข่าย */}
                    {other_universitys.length > 0 ? (
                      <Col
                        xs
                        sm={12}
                        md
                        lg={6}
                        xl={6}
                        xxl={4}
                        style={{ paddingBottom: "1.5rem" }}
                      >
                        <div
                          className="card-university"
                          style={{
                            backgroundColor: "#fff",
                            borderRadius: "15px",
                          }}
                        >
                          <div
                            className="card-header"
                            style={{
                              padding: "1.5rem 1.5rem 0px",
                              borderRadius: "15px  15px 0 0",
                            }}
                          >
                            <h5>มหาลัยเครือข่าย</h5>
                            <p
                              style={{
                                textAlign: "left",
                                marginTop: "0.5rem",
                                marginBottom: "0.5rem",
                              }}
                            >
                              จำนวน {other_universitys.length} คน
                            </p>
                          </div>
                          <div className="card-university-body">
                            {other_universitys.length ? (
                              <div className="list">
                                {other_universitys.map((listdata) => (
                                  <div className="link_feature">
                                    <Link
                                      to={`/Researcher/locations?user_idcard=${btoa(
                                        listdata.user_idcard
                                      )}`}
                                      className="linkexternal"
                                    >
                                      <Row
                                        className="p-2 align-items-center justify-content-md-center"
                                        style={{ width: "100%" }}
                                      >
                                        <Col md="2">
                                          <img
                                            className="rounded-circle mx-auto d-block"
                                            width={40}
                                            height={40}
                                            src={
                                              listdata.img
                                                ? listdata.img
                                                : "https://www.km-innovations.rmuti.ac.th/researcher/icon/researcher.png"
                                            }
                                            alt="logo-researcher"
                                          />
                                        </Col>
                                        <Col
                                          md="10"
                                          style={{ paddingLeft: "2rem" }}
                                        >
                                          {listdata.fullname}
                                        </Col>
                                        <Col
                                          md="12"
                                          style={{
                                            paddingTop: "0.8rem",
                                            paddingLeft: "2rem",
                                          }}
                                        >
                                          {listdata.name}
                                        </Col>
                                      </Row>
                                    </Link>
                                    <hr style={{ margin: "0.5rem 1rem" }} />
                                  </div>
                                ))}
                              </div>
                            ) : (
                              <div className="list pt-4">ไม่พบข้อมูล</div>
                            )}
                          </div>
                        </div>
                      </Col>
                    ) : null}
                  </Row>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Col>
          {/* เครือข่ายความร่วมมือ */}
          <Col sm={12} style={{ marginTop: "2rem" }}>
            {co_researcher.length ? (
              <Accordion
                style={{ padding: "10px 25px 0 25px" }}
                className="bg-title body-universitys"
                defaultActiveKey="0"
              >
                <Accordion.Item
                  eventKey="0"
                  style={{
                    backgroundColor: "transparent",
                    border: "none",
                  }}
                >
                  <Accordion.Header>
                    <h3
                      style={{
                        textAlign: "left",
                        color: "#212529",
                        marginBottom: "2rem",
                      }}
                    >
                      เครือข่ายความร่วมมือ
                    </h3>
                  </Accordion.Header>
                  <Accordion.Body>
                    <Row style={{ paddingLeft: "1rem" }}>
                      {co_researcher.length ? (
                        <>
                          {co_researcher.map((list, index) => (
                            <>
                              {list.data.length > 0 ? (
                                <Col
                                  key={index}
                                  xs={12}
                                  sm={12}
                                  md={12}
                                  lg={6}
                                  xl={6}
                                  xxl={4}
                                  style={{ paddingBottom: "1.5rem" }}
                                >
                                  <div
                                    className="card-university"
                                    style={{
                                      backgroundColor: "#fff",
                                      borderRadius: "15px",
                                    }}
                                  >
                                    <div
                                      className="card-header"
                                      style={{
                                        padding: "1.5rem 1.5rem 0px",
                                        borderRadius: "15px  15px 0 0",
                                      }}
                                    >
                                      <h5>{list.title}</h5>
                                      <p
                                        style={{
                                          textAlign: "left",
                                          marginTop: "0.5rem",
                                          marginBottom: "0.5rem",
                                        }}
                                      >
                                        จำนวน {list.data.length}{" "}
                                        {list.item_name}
                                      </p>
                                    </div>
                                    <div className="card-university-body">
                                      {list.data.length ? (
                                        <div className="list">
                                          {list.data
                                            .sort((x, y) =>
                                              x.label.localeCompare(
                                                y.label,
                                                "th"
                                              )
                                            )
                                            .map((listdata) => (
                                              <div className="link_feature">
                                                <Link
                                                  to={`/CoResearcher/expertise?co_researcher_id=${btoa(
                                                    listdata.co_id
                                                  )}`}
                                                  className="linkexternal"
                                                >
                                                  <Row
                                                    className="p-2 align-items-center justify-content-md-center "
                                                    style={{
                                                      width: "100%",
                                                    }}
                                                    data-bs-toggle="tooltip"
                                                    data-bs-placement="bottom"
                                                    title={listdata.label}
                                                  >
                                                    <Col md="2">
                                                      <img
                                                        className="rounded-circle mx-auto d-block"
                                                        width={40}
                                                        height={40}
                                                        src={
                                                          listdata.img
                                                            ? listdata.img
                                                            : noImg
                                                        }
                                                        alt="logo-researcher"
                                                      />
                                                    </Col>
                                                    <Col
                                                      md="10"
                                                      style={{
                                                        paddingLeft: "2rem",
                                                      }}
                                                    >
                                                      {
                                                        listdata.co_researcher_name_th
                                                      }
                                                    </Col>
                                                  </Row>
                                                </Link>
                                              </div>
                                            ))}
                                        </div>
                                      ) : (
                                        <div className="list pt-4">
                                          ไม่พบข้อมูล
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </Col>
                              ) : null}
                            </>
                          ))}
                        </>
                      ) : (
                        <div className="pt-4">ไม่พบข้อมูล</div>
                      )}
                    </Row>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            ) : null}
          </Col>
          {/* เครือข่ายความร่วมมืออื่นๆ */}
          <Col sm={12} style={{ marginTop: "2rem" }}>
            {team_group.length ? (
              <Accordion
                style={{ padding: "10px 25px 0 25px" }}
                className="bg-title body-universitys"
                defaultActiveKey="0"
              >
                <Accordion.Item
                  eventKey="0"
                  style={{
                    backgroundColor: "transparent",
                    border: "none",
                  }}
                >
                  <Accordion.Header>
                    <h3
                      style={{
                        textAlign: "left",
                        color: "#212529",
                        marginBottom: "2rem",
                      }}
                    >
                      เครือข่ายความร่วมมืออื่นๆ
                    </h3>
                  </Accordion.Header>
                  <Accordion.Body>
                    <Row style={{ paddingLeft: "1rem" }}>
                      {team_group.length ? (
                        <>
                          {team_group.map((list, index) => (
                            <>
                              {list.data.length > 0 ? (
                                <Col
                                  key={index}
                                  xs={12}
                                  sm={12}
                                  md={12}
                                  lg={6}
                                  xl={6}
                                  xxl={4}
                                  style={{ paddingBottom: "1.5rem" }}
                                >
                                  <div
                                    className="card-university"
                                    style={{
                                      backgroundColor: "#fff",
                                      borderRadius: "15px",
                                    }}
                                  >
                                    <div
                                      className="card-header"
                                      style={{
                                        padding: "1.5rem 1.5rem 0px",
                                        borderRadius: "15px  15px 0 0",
                                      }}
                                    >
                                      <h5>{list.position_name}</h5>
                                      <p
                                        style={{
                                          textAlign: "left",
                                          marginTop: "0.5rem",
                                          marginBottom: "0.5rem",
                                        }}
                                      >
                                        จำนวน {list.data.length} คน
                                      </p>
                                    </div>
                                    <div className="card-university-body">
                                      {list.data.length ? (
                                        <div className="list">
                                          {list.data
                                            .sort((x, y) =>
                                              x.label.localeCompare(
                                                y.label,
                                                "th"
                                              )
                                            )
                                            .map((listdata) => (
                                              <div className="link_feature">
                                                <Link
                                                  to={`/Researcher/locations?user_idcard=${btoa(
                                                    listdata.user_idcard
                                                  )}`}
                                                  className="linkexternal"
                                                >
                                                  <Row
                                                    className="p-2 align-items-center justify-content-md-center "
                                                    style={{
                                                      width: "100%",
                                                    }}
                                                    data-bs-toggle="tooltip"
                                                    data-bs-placement="bottom"
                                                    title={listdata.label}
                                                  >
                                                    <Col md="2">
                                                      <img
                                                        className="rounded-circle mx-auto d-block"
                                                        width={40}
                                                        height={40}
                                                        src={
                                                          listdata.img
                                                            ? listdata.img
                                                            : noImg
                                                        }
                                                        alt="logo-researcher"
                                                      />
                                                    </Col>
                                                    <Col
                                                      md="10"
                                                      style={{
                                                        paddingLeft: "2rem",
                                                      }}
                                                    >
                                                      {listdata.fullname}
                                                    </Col>
                                                  </Row>
                                                </Link>
                                              </div>
                                            ))}
                                        </div>
                                      ) : (
                                        <div className="list pt-4">
                                          ไม่พบข้อมูล
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </Col>
                              ) : null}
                            </>
                          ))}
                        </>
                      ) : (
                        <div className="pt-4">ไม่พบข้อมูล</div>
                      )}
                    </Row>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            ) : null}
          </Col>
        </Row>
      )}
    </div>
  );
};

export default ProjectNetwork;
