import "../Css/d3.css";
import ProjectNetwork from "./ConceptPage/ProjectNetwork";
import ProjectInnovation from "./ConceptPage/ProjectInnovation";
import KnowledgeDetail from "./ConceptPage/KnowledgeDetail";
import NewknowledgeDetail from "./ConceptPage/NewKnowledgeDetail";
import ImpactDetail from "./ConceptPage/ImpactDetail";
import Knowledges from "./ConceptPage/Knowledge";
import React, { useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import { Button } from "@material-ui/core";
import { Route, Switch, Link } from "react-router-dom";
import "./Css/Detail.scss";
import axios from "axios";
import "./Css/mapView.scss";
import "../index.css";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { makeStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router";
import { useTranslation } from "react-i18next";
import "./Css/researcher.scss";
import "./Css/project.scss";
import GoalsDetail from "./ConceptPage/GoalsDetail";
import ResearcherIcon from "../icon/profession.svg";
import BudgetIcon from "../icon/budget.svg";
import SourceIcon from "../icon/source_fund.svg";
import DateIcon from "../icon/date.svg";
import NetworkIcon from "../icon/network.svg";
import KnowledgeIcon from "../icon/knowledge.svg";
import newKnowledgeIcon from "../icon/newknowledge.svg";
import InnovationIcon from "../icon/innovation.svg";
import ImpactIcon from "../icon/impact.svg";
import GoalIcon from "../icon/goal.svg";
import { apiUrl } from "../config/api.json";
const useStyles = makeStyles((theme) => ({
  appbar: {
    alignItems: "center",
    backgroundColor: "#fff",
    boxShadow: "none",
  },
  root: {
    flexGrow: 1,
    width: "100%",
    // backgroundColor: theme.palette.background.paper,
  },

  customLabel: {
    fontFamily: "Prompt",
    fontWeight: "bold",
    color: "black",
  },

  round: {
    borderRadius: "50%",
    border: "4px solid rgb(223, 223, 223)",
    width: "100%",
    boxShadow:
      "rgba(0, 0, 0, 0.293) 0px 19px 38px, rgba(0, 0, 0, 0.129) 0px 15px 12px",
    [theme.breakpoints.up("sm")]: {
      width: "65%",
    },
  },

  underlineCol: {
    borderBottom: "2px solid rgb(211, 211, 211)",
    paddingTop: 12,
  },

  tabpanel: {
    marginLeft: "auto",
    marginRight: "auto",
  },

  headerLabel: {
    position: "relative",
    fontFamily: "Prompt",
    fontWeight: "bold",
    color: "black",
    zIndex: 1,

    textAlign: "center",

    "&::after": {
      position: "absolute",
      content: '""',
      top: 10,
      borderBottom: "3px solid black",
      width: 120,
      left: "50%",
      marginTop: 10,
      marginLeft: "-60px",
      bottom: 0,
    },
  },

  cardlayout: {
    marginTop: 20,
    boxShadow:
      "rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px",
    [theme.breakpoints.up("sm")]: {
      marginTop: 0,
    },
  },

  tabsBg: {
    background:
      "linear-gradient(90deg,rgba(240, 99, 0, 1) 0%,rgba(255, 115, 0, 1) 38%,rgba(254, 148, 0, 1) 100%)",
  },
}));

function ProjectDetail(props) {
  const { typeproject } = props;
  const [loading_profile, setLoading_profile] = useState(true);
  const [loading, setloading] = useState(true);
  const { t } = useTranslation();

  const [project, setproject] = useState({});
  const [location, setlocation] = useState([]);
  const [output, setoutput] = useState([]);

  const classes = useStyles();

  const getParamsId = () => {
    const windowUrl = window.location.search;
    const params = new URLSearchParams(windowUrl);
    const project_id = params.get("project_id");
    if (!project_id) {
      const project_id = params.get("concep_id");
      return project_id;
    }
    return project_id;
  };
  const idencrypt = getParamsId();
  let id;
  var apiconcept;
  var apiknowledge;
  var apiimpact;
  var pathproject;
  var project_name_id;
  if (typeproject === "concep") {
    apiconcept = "/api/get/concept_proposal/detail/";
    pathproject = "/ProjectDetailConcep";
    project_name_id = "concep_id";
    apiknowledge = "/api/get/concept_proposal/knowledge/";
    apiimpact = "";
  } else if (typeproject === "usproject") {
    apiconcept = "/api/get/us-project/";
    pathproject = "/ProjectDetail";
    project_name_id = "project_id";
    apiknowledge = "/api/get/us-project/knowledge/";
    apiimpact = "/api/get/us-project/impact/";
  }
  const getconcept_proposal = (id) => {
    axios
      .get(`${apiUrl}${apiconcept}${id}`)
      .then(async (result) => {
        setproject(result.data);
        setLoading_profile(false);
        setlocation([
          {
            lat: result.data.concept_proposal_latitude,
            lng: result.data.concept_proposal_longitude,
          },
        ]);
      })
      .catch((err) => {
        console.log(err);
        setLoading_profile(false);
      });
  };

  const getknowledge = async (id) => {
    try {
      const res = await axios.get(`${apiUrl}${apiknowledge}${id}`);
      setoutput(res.data);
      setloading(false);
    } catch (err) {
      setloading(false);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    if (idencrypt === null || idencrypt === "") {
      props.history.push({
        pathname: "/SearchProject",
      });
    } else {
      id = atob(idencrypt);
    }
    getconcept_proposal(id);
    getknowledge(id);
  }, []);
  // console.log("project:", project);

  return (
    <div className="body-detail">
      <div
        className="body-coresearcher"
        style={{ borderBottomRightRadius: "0", borderBottomLeftRadius: "0" }}
      >
        <Row style={{ paddingTop: "1rem" }}>
          <Col sm={12}>
            {loading_profile ? (
              <div className="demo"></div>
            ) : (
              <Row>
                <Col sm={12}>
                  <section
                    className="profile-info"
                    style={{ marginTop: "20px" }}
                  >
                    <h4>
                      <strong>
                        {project[t("concept_proposal_page.concept_name")]
                          ? project[t("concept_proposal_page.concept_name")]
                          : project.concept_proposal_name_th}
                      </strong>
                    </h4>
                  </section>
                </Col>
                <Col sm={1} />
                <Col sm={10}>
                  <div style={{ textAlign: "left", paddingLeft: "1rem" }}>
                    <p>
                      {/* <RiUser2Line size={35} />{" "} */}
                      <img
                        src={ResearcherIcon}
                        alt="icon"
                        style={{ width: "35px", height: "35px" }}
                      />{" "}
                      {t("concept_proposal_page.menu1")}:
                      <Link
                        to={`/Researcher/locations?user_idcard=${btoa(
                          project.user_idcard
                        )}`}
                        style={{ textDecoration: "none" }}
                      >
                        <Button style={{ fontFamily: "Prompt" }}>
                          {project.prefix_name_th}
                          {project.user_first_name_th}{" "}
                          {project.user_last_name_th}
                        </Button>
                      </Link>
                    </p>
                    <p>
                      {/* <GiMoneyStack size={35} />{" "} */}
                      <img
                        src={BudgetIcon}
                        alt="icon"
                        style={{ width: "30px", height: "30px" }}
                      />{" "}
                      {t("concept_proposal_page.menu2")}:{" "}
                      {project.concept_budget
                        ? parseInt(project.concept_budget).toLocaleString("en")
                        : ""}{" "}
                      บาท
                    </p>
                    <p>
                      <img
                        src={SourceIcon}
                        alt="icon"
                        style={{ width: "35px", height: "35px" }}
                      />{" "}
                      {t("concept_proposal_page.menu3")}:{" "}
                      {project.source_funds_name}
                    </p>
                    <p>
                      <img
                        src={DateIcon}
                        alt="icon"
                        style={{ width: "28px", height: "28px" }}
                      />{" "}
                      {t("concept_proposal_page.menu4")}: {project.concept_year}
                    </p>
                  </div>
                </Col>
              </Row>
            )}
          </Col>
          <Col sm={12}>
            <div className="line-bottom" style={{ margin: "1rem 3rem" }} />
          </Col>
        </Row>

        <Row style={{ width: "100%" }}>
          <Col sm={12}>
            <section className="statistics">
              <Route
                path={"/"}
                render={(history) => (
                  <AppBar
                    position="static"
                    color="default"
                    className={classes.appbar}
                  >
                    <Tabs
                      variant="scrollable"
                      scrollButtons="on"
                      TabIndicatorProps={{
                        style: { background: "#5d1809" },
                      }}
                      textColor="secondary"
                      value={
                        history.location.pathname !== "/"
                          ? history.location.pathname
                          : false
                      }
                    >
                      <Tab
                        value={`${pathproject}/ProjectNetwork`}
                        label={t("concept_proposal_page.tabmenu.menu1")}
                        className={classes.customLabel}
                        icon={
                          <img
                            src={NetworkIcon}
                            alt="icon"
                            style={{ width: "30px", height: "30px" }}
                          />
                        }
                        component={Link}
                        to={`${pathproject}/ProjectNetwork?${project_name_id}=${idencrypt}`}
                      />

                      <Tab
                        value={`${pathproject}/knowledgeDetail`}
                        label={t("concept_proposal_page.tabmenu.menu3")}
                        className={classes.customLabel}
                        icon={
                          <img
                            src={KnowledgeIcon}
                            alt="icon"
                            style={{ width: "30px", height: "30px" }}
                          />
                        }
                        component={Link}
                        to={`${pathproject}/knowledgeDetail?${project_name_id}=${idencrypt}`}
                      />

                      <Tab
                        value={`${pathproject}/NewknowledgeDetail`}
                        label={t("concept_proposal_page.tabmenu.menu4")}
                        className={classes.customLabel}
                        icon={
                          <img
                            src={newKnowledgeIcon}
                            alt="icon"
                            style={{ width: "30px", height: "30px" }}
                          />
                        }
                        component={Link}
                        to={`${pathproject}/NewknowledgeDetail?${project_name_id}=${idencrypt}`}
                      />
                      <Tab
                        value={`${pathproject}/ProjectInnovation`}
                        className={classes.customLabel}
                        label={t("researcher.tabmenu.menu5")}
                        icon={
                          <img
                            src={InnovationIcon}
                            alt="icon"
                            style={{ width: "30px", height: "30px" }}
                          />
                        }
                        component={Link}
                        to={`${pathproject}/ProjectInnovation?${project_name_id}=${idencrypt}`}
                      />

                      <Tab
                        value={`${pathproject}/ImpactDetail`}
                        label={t("concept_proposal_page.tabmenu.menu5")}
                        className={classes.customLabel}
                        icon={
                          <img
                            src={ImpactIcon}
                            alt="icon"
                            style={{ width: "30px", height: "30px" }}
                          />
                        }
                        component={Link}
                        to={`${pathproject}/ImpactDetail?${project_name_id}=${idencrypt}`}
                      />

                      <Tab
                        value={`${pathproject}/GoalsDetail`}
                        label={t("concept_proposal_page.tabmenu.menu6")}
                        // label=""
                        className={classes.customLabel}
                        icon={
                          <img
                            src={GoalIcon}
                            alt="icon"
                            style={{ width: "30px", height: "30px" }}
                          />
                        }
                        component={Link}
                        to={`${pathproject}/GoalsDetail?${project_name_id}=${idencrypt}`}
                      />
                    </Tabs>
                  </AppBar>
                )}
              />
            </section>
          </Col>
        </Row>
      </div>
      <div
        style={{
          backgroundColor: "#5d1809",
          width: "100%",
          marginTop: "0rem",
          borderRadius: "0 0 15px 15px",
        }}
      >
        <div style={{ padding: "2rem" }}>
          <Switch>
            <Route path={`${pathproject}/knowledege`}>
              <Knowledges concept_proposal_id={idencrypt} />
            </Route>
            <Route path={`${pathproject}/ProjectNetwork`}>
              <ProjectNetwork location={location} />
            </Route>
            <Route path={`${pathproject}/ProjectInnovation`}>
              <ProjectInnovation />
            </Route>
            <Route path={`${pathproject}/knowledgeDetail`}>
              <KnowledgeDetail
                output={output}
                output_length={output.length}
                loading={loading}
              />
            </Route>
            <Route path={`${pathproject}/NewknowledgeDetail`}>
              <NewknowledgeDetail />
            </Route>
            <Route path={`${pathproject}/ImpactDetail`}>
              <ImpactDetail />
            </Route>
            <Route path={`${pathproject}/GoalsDetail`}>
              <GoalsDetail />
            </Route>
          </Switch>
        </div>
      </div>
    </div>
  );
}

export default withRouter(ProjectDetail);
