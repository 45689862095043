import React, { useState, useEffect, useRef } from "react";
import { CardTitle, Card, CardBody, Row, Col } from "reactstrap";
import { Tabs, Tab } from "react-bootstrap";
// import { useTranslation } from "react-i18next";
import { FaChevronCircleDown } from "react-icons/fa";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import { makeStyles } from "@material-ui/core/styles";
import Youtubeshow from "../Detail/Youtubeshow";
const useStyles = makeStyles((theme) => ({
  appbar: {
    alignItems: "center",
    backgroundColor: "#fff",
    boxShadow: "none",
  },
  root: {
    flexGrow: 1,
    width: "100%",
    // backgroundColor: theme.palette.background.paper,
  },

  customLabel: {
    fontFamily: "Prompt",
    fontWeight: "bold",
    color: "black",
  },

  round: {
    borderRadius: "50%",
    border: "4px solid rgb(223, 223, 223)",
    width: "100%",
    boxShadow:
      "rgba(0, 0, 0, 0.293) 0px 19px 38px, rgba(0, 0, 0, 0.129) 0px 15px 12px",
    [theme.breakpoints.up("sm")]: {
      width: "65%",
    },
  },

  underlineCol: {
    borderBottom: "2px solid rgb(211, 211, 211)",
    paddingTop: 12,
  },

  tabpanel: {
    marginLeft: "auto",
    marginRight: "auto",
  },

  headerLabel: {
    position: "relative",
    fontFamily: "Prompt",
    fontWeight: "bold",
    color: "black",
    zIndex: 1,

    textAlign: "center",

    "&::after": {
      position: "absolute",
      content: '""',
      top: 10,
      borderBottom: "3px solid black",
      width: 120,
      left: "50%",
      marginTop: 10,
      marginLeft: "-60px",
      bottom: 0,
    },
  },

  cardlayout: {
    marginTop: 20,
    boxShadow:
      "rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px",
    [theme.breakpoints.up("sm")]: {
      marginTop: 0,
    },
  },

  tabsBg: {
    background:
      "linear-gradient(90deg,rgba(240, 99, 0, 1) 0%,rgba(255, 115, 0, 1) 38%,rgba(254, 148, 0, 1) 100%)",
  },
}));

const Impact = (prop) => {
  const classes = useStyles();
  const { economy, society, culture, environment, loading_page } = prop;
  //   const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [economyDetail, seteconomyDetail] = useState({});
  const [societyDetail, setsocietyDetail] = useState({});
  const [cultureDetail, setcultureDetail] = useState({});
  const [environmentDetail, setenvironmentDetail] = useState({});

  const detaileconomyRef = useRef();
  const detailsocietyRef = useRef();
  const detailcultureRef = useRef();
  const detailenvironmentRef = useRef();

  // const getId = (url) => {
  //   const regExp =
  //     /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
  //   const match = url.match(regExp);
  //   return match && match[2].length === 11 ? match[2] : null;
  // };

  const onClickeDetail = (index, type) => {
    if (type === "economy") {
      seteconomyDetail(economy[index] ? economy[index] : {});
      detaileconomyRef.current.scrollIntoView({ behavior: "smooth" });
    } else if (type === "society") {
      setsocietyDetail(society[index]);
      detailsocietyRef.current.scrollIntoView({ behavior: "smooth" });
    } else if (type === "culture") {
      setcultureDetail(culture[index]);
      detailcultureRef.current.scrollIntoView({ behavior: "smooth" });
    } else if (type === "environment") {
      setenvironmentDetail(environment[index]);
      detailenvironmentRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    setLoading(loading_page);
  }, [loading_page]);

  const FactorDetail = (obj) => {
    let { dataFactor, type_impact } = obj;
    return (
      <>
        {dataFactor.length ? (
          <Row className="justify-content-center">
            {dataFactor.map((ListItem, index) => (
              <Col key={index} xs={12} sm={12} md={6} lg={4} xl={4}>
                <div
                  className="card-output"
                  style={{ backgroundColor: "#fff", height: "auto" }}
                  onClick={() => onClickeDetail(index, type_impact)}
                >
                  <img
                    src={`https://researcher.kims-rmuti.com/icon/impact/${ListItem.bd_outcome_image}`}
                    alt="Cardimagecap"
                    className={classes.round}
                  />
                  <Row style={{ paddingTop: "1rem" }}>
                    <Col xs={10}>
                      <CardTitle
                        className={classes.customLabel}
                        style={{
                          fontFamily: "Prompt",
                          paddingTop: 15,
                          paddingBottom: 0,
                        }}
                      >
                        {ListItem.factor_name}
                      </CardTitle>
                    </Col>
                    <Col
                      xs={2}
                      style={{ textAlign: "left", marginTop: "1rem" }}
                    >
                      <FaChevronCircleDown size={30} className="arrow-down" />
                    </Col>
                  </Row>
                </div>
              </Col>
            ))}
          </Row>
        ) : (
          <h6>ไม่พบข้อมูล</h6>
        )}
      </>
    );
  };

  const DetailPage = (obj) => {
    let { dataDetail } = obj;
    console.log("dataDetail", dataDetail);
    return (
      <div>
        {Object.keys(dataDetail).length > 0 ? (
          <Col sm={12}>
            <div className="goal-detail">
              <h5 style={{ textAlign: "left" }}>
                <b> {dataDetail.factor_name}</b>{" "}
              </h5>
              <hr />
              {dataDetail?.issues?.map((data, i) => (
                <Row className="goal-row-detail" key={i}>
                  <h6 style={{ textAlign: "left" }}>
                    <b>ประเด็น : {data.issues_name} </b>
                  </h6>
                  {data.impact.length ? (
                    <div>
                      {data.impact.map((d, index) => (
                        <div key={index}>
                          <Row>
                            <Col sm={12}>
                              <p
                                style={{
                                  textAlign: "left",
                                  marginTop: "1rem",
                                }}
                              >
                                {" "}
                                - {d.impact_detail}{" "}
                              </p>
                            </Col>

                            <Col sm={12} style={{ padding: "0 3rem" }}>
                              <hr style={{ height: "0.5px" }} />
                            </Col>

                            {d.impact_image?.length ? (
                              <>
                                {d.impact_image.length ? (
                                  <Col sm={12} md={12} lg={6} xl={6}>
                                    <ImageGallery
                                      showThumbnails={true}
                                      items={d.impact_image}
                                    />
                                  </Col>
                                ) : (
                                  <Col
                                    sm={12}
                                    md={12}
                                    lg={6}
                                    xl={6}
                                    className="div-image-knowledge"
                                  >
                                    {d.image_name.map((data_img, index) => (
                                      <img
                                        key={index}
                                        style={{ width: "100%" }}
                                        src={data_img.image_name}
                                        alt="goal_image"
                                      />
                                    ))}
                                  </Col>
                                )}
                              </>
                            ) : (
                              <Col sm={12} md={12} lg={6} xl={6}>
                                <div className="title-center">
                                  <h3
                                    style={{
                                      color: "#000",
                                      fontFamily: "Prompt",
                                    }}
                                  >
                                    ไม่มีรูปภาพ
                                  </h3>
                                </div>
                              </Col>
                            )}
                            <Youtubeshow
                              url_video={
                                d.impact_vdos.length
                                  ? d.impact_vdos[0]?.vdo_name
                                  : ""
                              }
                              xs={12}
                              sm={12}
                              md={12}
                              lg={6}
                              paddingTop={"0"}
                              className={"goal-video"}
                            />
                          </Row>
                        </div>
                      ))}
                    </div>
                  ) : null}
                  <hr style={{ height: "0.5px" }} />
                </Row>
              ))}
            </div>
          </Col>
        ) : null}
      </div>
    );
  };
  return (
    <>
      <Card className="card-goal">
        <CardTitle tag="h5" className="card-title-goal">
          ประเภทผลกระทบ
        </CardTitle>
        <CardBody style={{ fontFamily: "Prompt" }}>
          <Tabs
            defaultActiveKey="economy"
            id="uncontrolled-tab-example"
            className="mb-3"
          >
            <Tab eventKey="economy" title="เศรษฐกิจ" tabClassName="economy">
              <div className="tab-table">
                <FactorDetail dataFactor={economy} type_impact="economy" />
              </div>
              <div ref={detaileconomyRef}>
                <DetailPage dataDetail={economyDetail} />
              </div>
            </Tab>
            <Tab eventKey="society" title="สังคม" tabClassName="society">
              <div className="tab-table">
                <FactorDetail dataFactor={society} type_impact="society" />
              </div>
              <div ref={detailsocietyRef}>
                <DetailPage dataDetail={societyDetail} />
              </div>
            </Tab>
            <Tab eventKey="culture" title="วัฒนธรรม" tabClassName="culture">
              <div className="tab-table">
                <FactorDetail dataFactor={culture} type_impact="culture" />
              </div>
              <div ref={detailcultureRef}>
                <DetailPage dataDetail={cultureDetail} />
              </div>
            </Tab>
            <Tab
              eventKey="environment"
              title="สิ่งแวดล้อม"
              tabClassName="environment"
            >
              <div className="tab-table">
                <FactorDetail
                  dataFactor={environment}
                  type_impact="environment"
                />
              </div>
              <div ref={detailenvironmentRef}>
                <DetailPage dataDetail={environmentDetail} />
              </div>
            </Tab>
          </Tabs>
        </CardBody>
      </Card>
    </>
  );
};

export default Impact;
