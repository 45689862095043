import { MapContainer, TileLayer, ZoomControl } from "react-leaflet";
import React, { useEffect, useState } from "react";

import D3Layer from "../D3Layer";
import "../Css/mapView.scss";
import { Card, Row, Col, Accordion } from "react-bootstrap";

import axios from "axios";
import { Link } from "react-router-dom";
import { apiUrl } from "../../config/api.json";
export default function ProjectPage(props) {
  const { co_researcher_id } = props;
  const [dataM, setdataM] = useState({});
  // const [projects, setprojects] = useState([]);

  const [counts_all, setcounts_all] = useState([]);
  const [counts_research, setcounts_research] = useState([]);
  const [counts_academic, setcounts_academic] = useState([]);
  const [counts_u2t, setcounts_u2t] = useState([]);

  const [projectResearch, setprojectResearch] = useState([]);
  const [projectAcademic, setprojectAcademic] = useState([]);
  const [projectU2t, setprojectU2t] = useState([]);

  let id = atob(co_researcher_id);

  const mapData = async () => {
    const response = await axios.get(
      `${apiUrl}/api/get/co-researcher/map/${id}`
    );
    setdataM(response.data);
  };

  const getproject = async () => {
    const response = await axios.get(
      `${apiUrl}/api/get/co-researcher/projects/${id}`
    );
    // setprojects(response.data);
    setprojectResearch(response.data.research);
    setprojectAcademic(response.data.academic);
    setprojectU2t(response.data.u2t);

    setcounts_all(response.data.counts_all);
    setcounts_research(response.data.counts_research);
    setcounts_academic(response.data.counts_academic);
    setcounts_u2t(response.data.counts_u2t);
  };

  useEffect(() => {
    mapData();
    getproject();
  }, []);

  return (
    <div>
      <Row style={{ marginLeft: "0" }}>
        <Col xs={12}>
          <Card
            className="card-border"
            style={{
              marginTop: "10px",
              fontFamily: "Prompt",
              borderRadius: "15px",
              boxShadow: "none",
              border: "none",
            }}
          >
            <MapContainer
              center={[13, 100]}
              zoom={3}
              scrollWheelZoom={true}
              zoomControl={false}
              style={{
                width: "100%",
                minHeight: "450px",
                height: "65vh",
                margin: "0",
                zIndex: "0",
                borderRadius: "15px",
              }}
            >
              <TileLayer
                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />

              <ZoomControl position="topright" />
              {Object.keys(dataM).length > 0 ? (
                <D3Layer location={dataM} />
              ) : null}
              {/* <D3Layer location={dataM} /> */}
            </MapContainer>
          </Card>
        </Col>
        <Col xs={12}>
          <div
            className="dashboard"
            style={{
              marginTop: "-4.5rem",
              marginBottom: "-1rem",
              marginLeft: "1rem",
            }}
          >
            <div className="all-card">
              <Row>
                <Col>
                  <div className="card">
                    <div className="card-body ">
                      <h5 className="card-title text-title">
                        โครงการดำเนินงานทั้งหมด
                      </h5>
                      <h2 className="card-text text-amount">
                        {counts_all} โครงการ
                      </h2>
                    </div>
                  </div>
                </Col>
                <Col>
                  <div className="card">
                    <div className="card-body ">
                      <h5 className="card-title text-title">
                        โครงการดำเนินงานวิจัย
                      </h5>
                      <h2 className="card-text text-amount">
                        {counts_research} โครงการ
                      </h2>
                    </div>
                  </div>
                </Col>
                <Col>
                  <div className="card">
                    <div className="card-body ">
                      <h5 className="card-title text-title">
                        โครงการดำเนินงานบริการวิชาการ
                      </h5>
                      <h2 className="card-text text-amount">
                        {counts_academic} โครงการ
                      </h2>
                    </div>
                  </div>
                </Col>
                <Col>
                  <div className="card">
                    <div className="card-body ">
                      <h5 className="card-title text-title">
                        โครงการดำเนินงานบริการวิชาการ(U2T)
                      </h5>
                      <h2 className="card-text text-amount">
                        {counts_u2t} โครงการ
                      </h2>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </Col>
        <Col xs={12}>
          {projectResearch.length ? (
            <Accordion
              className="bg-title body-universitys"
              defaultActiveKey="0"
              style={{ paddingBottom: "10px" }}
            >
              <Accordion.Item
                eventKey="0"
                style={{
                  backgroundColor: "transparent",
                  border: "none",
                }}
              >
                <Accordion.Header>
                  <h3
                    style={{
                      textAlign: "left",
                      color: "#212529",
                      marginBottom: "2rem",
                    }}
                  >
                    โครงการดำเนินงานวิจัย
                  </h3>
                </Accordion.Header>
                <Accordion.Body>
                  <Row style={{ paddingLeft: "1rem" }}>
                    {projectResearch.length ? (
                      <>
                        {projectResearch.map((listValue) => (
                          <Col
                            xs={12}
                            sm={12}
                            md={12}
                            lg={6}
                            xl={6}
                            xxl={4}
                            style={{ paddingBottom: "1.5rem" }}
                          >
                            <div
                              className="card-university"
                              style={{
                                backgroundColor: "#fff",
                                borderRadius: "15px",
                              }}
                            >
                              <div
                                className="card-header"
                                style={{
                                  padding: "1.5rem 1.5rem 0px",
                                  borderRadius: "15px  15px 0 0",
                                  height: "170px",
                                }}
                              >
                                <h5>{listValue.concept_proposal_name_th}</h5>
                              </div>
                              <div
                                className="card-university-body"
                                style={{ textAlign: "left" }}
                              >
                                <h6>
                                  <b>งบประมาณ : </b>{" "}
                                  {listValue.concept_budget
                                    ? new Number(
                                        listValue.concept_budget
                                      ).toLocaleString("en")
                                    : ""}{" "}
                                  บาท
                                </h6>
                                <h6>
                                  <b>ปี : </b>
                                  {listValue.concept_year}
                                </h6>
                                <h6>
                                  <b>ชื่อนักวิจัย : </b>
                                  {listValue.prefix_name_th}
                                  {listValue.user_first_name_th}
                                  &nbsp;
                                  {listValue.user_last_name_th}
                                </h6>
                                <h6>
                                  <b>หน่วยงาน : </b>
                                  {listValue.name}
                                </h6>
                                <Link
                                  to={
                                    listValue.project_id
                                      ? `/ProjectDetail/ProjectNetwork?project_id=${btoa(
                                          listValue.project_id
                                        )}`
                                      : `/ProjectDetailConcep/ProjectNetwork?concep_id=${btoa(
                                          listValue.concept_proposal_id
                                        )}`
                                  }
                                  className="btn-innovation"
                                >
                                  ดูเพิ่มเติม
                                </Link>
                              </div>
                            </div>
                          </Col>
                        ))}
                      </>
                    ) : (
                      <div className="pt-4">ไม่พบข้อมูล</div>
                    )}
                  </Row>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          ) : null}
        </Col>
        <Col xs={12}>
          {projectAcademic.length ? (
            <Accordion
              className="bg-title body-universitys"
              defaultActiveKey="0"
              style={{ paddingBottom: "10px", marginTop: "2rem" }}
            >
              <Accordion.Item
                eventKey="0"
                style={{
                  backgroundColor: "transparent",
                  border: "none",
                }}
              >
                <Accordion.Header>
                  <h3
                    style={{
                      textAlign: "left",
                      color: "#212529",
                      marginBottom: "2rem",
                    }}
                  >
                    โครงการดำเนินงานบริการวิชาการ
                  </h3>
                </Accordion.Header>
                <Accordion.Body>
                  <Row style={{ paddingLeft: "1rem" }}>
                    {projectAcademic.length ? (
                      <>
                        {projectAcademic.map((listValue) => (
                          <Col
                            xs={12}
                            sm={12}
                            md={12}
                            lg={6}
                            xl={6}
                            xxl={4}
                            style={{ paddingBottom: "1.5rem" }}
                          >
                            <div
                              className="card-university"
                              style={{
                                backgroundColor: "#fff",
                                borderRadius: "15px",
                              }}
                            >
                              <div
                                className="card-header"
                                style={{
                                  padding: "1.5rem 1.5rem 0px",
                                  borderRadius: "15px  15px 0 0",
                                  height: "170px",
                                }}
                              >
                                <h5>{listValue.concept_proposal_name_th}</h5>
                              </div>
                              <div
                                className="card-university-body"
                                style={{ textAlign: "left" }}
                              >
                                <h6>
                                  <b>งบประมาณ : </b>{" "}
                                  {listValue.concept_budget
                                    ? new Number(
                                        listValue.concept_budget
                                      ).toLocaleString("en")
                                    : ""}{" "}
                                  บาท
                                </h6>
                                <h6>
                                  <b>ปี : </b>
                                  {listValue.concept_year}
                                </h6>
                                <h6>
                                  <b>ชื่อนักวิจัย : </b>
                                  {listValue.prefix_name_th}
                                  {listValue.user_first_name_th}
                                  &nbsp;
                                  {listValue.user_last_name_th}
                                </h6>
                                <h6>
                                  <b>หน่วยงาน : </b>
                                  {listValue.name}
                                </h6>
                                <Link
                                  to={
                                    listValue.project_id
                                      ? `/ProjectDetail/ProjectNetwork?project_id=${btoa(
                                          listValue.project_id
                                        )}`
                                      : `/ProjectDetailConcep/ProjectNetwork?concep_id=${btoa(
                                          listValue.concept_proposal_id
                                        )}`
                                  }
                                  className="btn-innovation"
                                >
                                  ดูเพิ่มเติม
                                </Link>
                              </div>
                            </div>
                          </Col>
                        ))}
                      </>
                    ) : (
                      <div className="pt-4">ไม่พบข้อมูล</div>
                    )}
                  </Row>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          ) : null}
        </Col>
        <Col xs={12}>
          {projectU2t.length ? (
            <Accordion
              className="bg-title body-universitys"
              defaultActiveKey="0"
              style={{ paddingBottom: "10px", marginTop: "2rem" }}
            >
              <Accordion.Item
                eventKey="0"
                style={{
                  backgroundColor: "transparent",
                  border: "none",
                }}
              >
                <Accordion.Header>
                  <h3
                    style={{
                      textAlign: "left",
                      color: "#212529",
                      marginBottom: "2rem",
                    }}
                  >
                    โครงการดำเนินงานบริการวิชาการ(U2T)
                  </h3>
                </Accordion.Header>
                <Accordion.Body>
                  <Row style={{ paddingLeft: "1rem" }}>
                    {projectU2t.length ? (
                      <>
                        {projectU2t.map((listValue) => (
                          <Col
                            xs={12}
                            sm={12}
                            md={12}
                            lg={6}
                            xl={6}
                            xxl={4}
                            style={{ paddingBottom: "1.5rem" }}
                          >
                            <div
                              className="card-university"
                              style={{
                                backgroundColor: "#fff",
                                borderRadius: "15px",
                              }}
                            >
                              <div
                                className="card-header"
                                style={{
                                  padding: "1.5rem 1.5rem 0px",
                                  borderRadius: "15px  15px 0 0",
                                  height: "170px",
                                }}
                              >
                                <h5>{listValue.concept_proposal_name_th}</h5>
                              </div>
                              <div
                                className="card-university-body"
                                style={{ textAlign: "left" }}
                              >
                                <h6>
                                  <b>งบประมาณ : </b>{" "}
                                  {listValue.concept_budget
                                    ? new Number(
                                        listValue.concept_budget
                                      ).toLocaleString("en")
                                    : ""}{" "}
                                  บาท
                                </h6>
                                <h6>
                                  <b>ปี : </b>
                                  {listValue.concept_year}
                                </h6>
                                <h6>
                                  <b>ชื่อนักวิจัย : </b>
                                  {listValue.prefix_name_th}
                                  {listValue.user_first_name_th}
                                  &nbsp;
                                  {listValue.user_last_name_th}
                                </h6>
                                <h6>
                                  <b>หน่วยงาน : </b>
                                  {listValue.name}
                                </h6>
                                <Link
                                  to={
                                    listValue.project_id
                                      ? `/ProjectDetail/ProjectNetwork?project_id=${btoa(
                                          listValue.project_id
                                        )}`
                                      : `/ProjectDetailConcep/ProjectNetwork?concep_id=${btoa(
                                          listValue.concept_proposal_id
                                        )}`
                                  }
                                  className="btn-innovation"
                                >
                                  ดูเพิ่มเติม
                                </Link>
                              </div>
                            </div>
                          </Col>
                        ))}
                      </>
                    ) : (
                      <div className="pt-4">ไม่พบข้อมูล</div>
                    )}
                  </Row>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          ) : null}
        </Col>
      </Row>
    </div>
  );
}
