import { Marker } from "react-leaflet";
import React, { useEffect, useState } from "react";
import { Col, Row, CardText } from "reactstrap";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import "./Css/Detail.scss";
import axios from "axios";
import Typography from "@material-ui/core/Typography";
import "./Css/mapView.scss";
import { Icon } from "leaflet";
import { HiOutlineLightBulb } from "react-icons/hi";
import { GiGiftOfKnowledge } from "react-icons/gi";
import {
  AiOutlineUser,
  AiOutlineReconciliation,
  AiOutlineCoffee,
  AiOutlineApartment,
} from "react-icons/ai";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import AppBar from "@material-ui/core/AppBar";
import { FaHandsHelping } from "react-icons/fa";
import noImg from "../images/no-image.png";
import { useTranslation } from "react-i18next";
import { Route, Switch, Link } from "react-router-dom";
import ExpertisePage from "./CoresearcherPage/ExpertistPage";
import KnowledgePage from "./CoresearcherPage/KnowledgePage";
import ProductPage from "./CoresearcherPage/ProductPage";
import InnovationPage from "./CoresearcherPage/InnovationPage";
import ProjectPage from "./CoresearcherPage/ProjectPage";
import PropertyPage from "./CoresearcherPage/PropertyPage";
import CreativePage from "./CoresearcherPage/CreativePage";
import ContactPage from "./CoresearcherPage/ContactPage";
import { apiUrl } from "../config/api.json";
function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  appbar: {
    alignItems: "center",
    backgroundColor: "#fff",
    boxShadow: "none",
  },
  root: {
    flexGrow: 1,
    width: "100%",
    // backgroundColor: theme.palette.background.paper,
  },

  customLabel: {
    fontFamily: "Prompt",
    fontWeight: "bold",
    color: "black",
  },

  round: {
    borderRadius: "50%",
    border: "4px solid rgb(223, 223, 223)",
    width: "100%",
    boxShadow:
      "rgba(0, 0, 0, 0.293) 0px 19px 38px, rgba(0, 0, 0, 0.129) 0px 15px 12px",
    [theme.breakpoints.up("sm")]: {
      width: "65%",
    },
  },

  underlineCol: {
    borderBottom: "2px solid rgb(211, 211, 211)",
    paddingTop: 12,
  },

  tabpanel: {
    marginLeft: "auto",
    marginRight: "auto",
  },

  headerLabel: {
    position: "relative",
    fontFamily: "Prompt",
    fontWeight: "bold",
    color: "black",
    zIndex: 1,

    textAlign: "center",

    "&::after": {
      position: "absolute",
      content: '""',
      top: 10,
      borderBottom: "3px solid black",
      width: 120,
      left: "50%",
      marginTop: 10,
      marginLeft: "-60px",
      bottom: 0,
    },
  },

  cardlayout: {
    marginTop: 20,
    boxShadow:
      "rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px",
    [theme.breakpoints.up("sm")]: {
      marginTop: 0,
    },
  },

  tabsBg: {
    background:
      "linear-gradient(90deg,rgba(240, 99, 0, 1) 0%,rgba(255, 115, 0, 1) 38%,rgba(254, 148, 0, 1) 100%)",
  },
}));

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function CoResearcher(props) {
  const { t } = useTranslation();
  const [CoResearcher, setCoResearcher] = useState({});
  const [location, setlocation] = useState([]);
  const classes = useStyles();
  var myParam = document.location.search.split("page")[1];
  var param = 0;
  if (myParam) {
    param = parseInt(myParam);
  }
  const [value, setValue] = useState(param);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [valuePage1, setValuePage1] = useState(0);

  const handleChangePage1 = (event, newValue) => {
    setValuePage1(newValue);
  };

  const markermap = location.map((d, i, p) => {
    if (d.lat && d.lng) {
      return (
        <Marker
          key={`${i}`}
          position={[d.lat, d.lng]}
          icon={
            new Icon({
              iconUrl:
                p.co_researcher_group_id === 1
                  ? `https://www.km-innovations.rmuti.ac.th/researcher/file-upload/co_researcher-upload/${p.co_researcher_image}`
                  : p.co_researcher_group_id === 2
                  ? `https://www.km-innovations.rmuti.ac.th/researcher/file-upload/co_researcher-upload/${p.co_researcher_image}`
                  : p.co_researcher_group_id === 3
                  ? `https://www.km-innovations.rmuti.ac.th/researcher/file-upload/co_researcher-upload/${p.co_researcher_image}`
                  : "https://images.vexels.com/media/users/3/140527/isolated/preview/449b95d58f554656b159dd3ca21ab123-home-round-icon.png",
              iconSize: [40, 41],
              className: "image-icon",
            })
          }
        ></Marker>
      );
    } else {
      return <></>;
    }
  });

  const getParamsId = () => {
    const windowUrl = window.location.search;
    const params = new URLSearchParams(windowUrl);
    const co_id = params.get("co_researcher_id");
    return co_id;
  };
  const idencrypt = getParamsId();

  useEffect(() => {
    let id;
    window.scrollTo(0, 0);
    if (idencrypt === null || idencrypt === undefined) {
      props.history.push({
        pathname: "/SearchPageCo",
      });
      console.log(null);
    } else {
      id = atob(idencrypt);
    }
    axios
      .get(`${apiUrl}/api/get/co-researcher/${id}`)
      .then((result) => {
        setCoResearcher(result.data[0]);
        setlocation([
          {
            lat: result.data[0].co_researcher_latitude,
            lng: result.data[0].co_researcher_longitude,
          },
        ]);
      })
      .catch((err) => {
        console.error(err);
        setlocation([]);
        setCoResearcher({});
      });
  }, []);
  return (
    <div className="body-detail">
      <div className="body-coresearcher">
        <Row>
          <Col sm={12} md={12} lg={4} className="img-profile-header">
            <img
              className="profile-image"
              src={CoResearcher.co_researcher_image}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src = noImg;
              }}
              alt="pimage"
            />
          </Col>
          <Col sm={12} md={12} lg={8}>
            <section className="profile-info" style={{ marginTop: "20px" }}>
              <h4>
                <strong>
                  {CoResearcher[t("community_search.com_name")]
                    ? CoResearcher[t("community_search.com_name")]
                    : CoResearcher.co_researcher_name_th}
                </strong>
              </h4>
              <CardText style={{ textAlign: "left", color: "#000" }}>
                <p>
                  <b>{t("community.history")} : </b>
                  {CoResearcher.co_researcher_history}
                </p>
              </CardText>
            </section>
          </Col>
          <Col sm={12}>
            <div className="line-bottom" style={{ margin: "1rem 3rem" }} />
          </Col>
        </Row>

        <Row style={{ width: "100%" }}>
          <Col sm={12}>
            <section className="statistics">
              <Route
                path={"/"}
                render={(history) => (
                  <AppBar
                    position="static"
                    color="default"
                    className={classes.appbar}
                  >
                    <Tabs
                      value={
                        history.location.pathname !== "/"
                          ? history.location.pathname
                          : false
                      }
                      onChange={handleChange}
                      variant="scrollable"
                      scrollButtons="on"
                      TabIndicatorProps={{
                        style: { background: "#5d1809" },
                      }}
                      textColor="secondary"
                      aria-label="scrollable tabs menu"
                    >
                      <Tab
                        value={`/CoResearcher/expertise`}
                        label={t("community.tabmenu.expertise")}
                        className={classes.customLabel}
                        icon={<AiOutlineApartment size={30} color="#5d1809" />}
                        component={Link}
                        to={`/CoResearcher/expertise?co_researcher_id=${idencrypt}`}
                      />
                      <Tab
                        value={`/CoResearcher/knowledge`}
                        label={t("community.tabmenu.knowledge")}
                        className={classes.customLabel}
                        icon={<GiGiftOfKnowledge size={30} color="#5d1809" />}
                        component={Link}
                        to={`/CoResearcher/knowledge?co_researcher_id=${idencrypt}`}
                      />
                      <Tab
                        label={t("community.tabmenu.product")}
                        className={classes.customLabel}
                        icon={<AiOutlineCoffee size={30} color="#5d1809" />}
                        value={`/CoResearcher/product`}
                        component={Link}
                        to={`/CoResearcher/product?co_researcher_id=${idencrypt}`}
                      />

                      <Tab
                        label={t("community.tabmenu.innovation")}
                        className={classes.customLabel}
                        icon={<HiOutlineLightBulb size={30} color="#5d1809" />}
                        value={`/CoResearcher/innovation`}
                        component={Link}
                        to={`/CoResearcher/innovation?co_researcher_id=${idencrypt}`}
                      />
                      <Tab
                        label={t("community.tabmenu.creative")}
                        className={classes.customLabel}
                        icon={<HiOutlineLightBulb size={30} color="#5d1809" />}
                        value={`/CoResearcher/creative`}
                        component={Link}
                        to={`/CoResearcher/creative?co_researcher_id=${idencrypt}`}
                      />
                      <Tab
                        label={t("community.tabmenu.project")}
                        className={classes.customLabel}
                        icon={<FaHandsHelping size={30} color="#5d1809" />}
                        value={`/CoResearcher/project`}
                        component={Link}
                        to={`/CoResearcher/project?co_researcher_id=${idencrypt}`}
                      />
                      <Tab
                        label={t("community.tabmenu.patent")}
                        className={classes.customLabel}
                        icon={
                          <AiOutlineReconciliation size={30} color="#5d1809" />
                        }
                        value={`/CoResearcher/property`}
                        component={Link}
                        to={`/CoResearcher/property?co_researcher_id=${idencrypt}`}
                      />
                      <Tab
                        label={t("community.tabmenu.contact")}
                        className={classes.customLabel}
                        icon={<AiOutlineUser size={30} color="#5d1809" />}
                        value={`/CoResearcher/contact`}
                        component={Link}
                        to={`/CoResearcher/contact?co_researcher_id=${idencrypt}`}
                      />
                    </Tabs>
                  </AppBar>
                )}
              />
            </section>
          </Col>
        </Row>
        <div className="expertise-detail">
          <Switch>
            <Route path="/CoResearcher/expertise">
              <ExpertisePage co_researcher_id={idencrypt} classes={classes} />
            </Route>

            <Route path="/CoResearcher/knowledge">
              <KnowledgePage co_researcher_id={idencrypt} />
            </Route>

            <Route path="/CoResearcher/product">
              <ProductPage co_researcher_id={idencrypt} />
            </Route>

            <Route path="/CoResearcher/innovation">
              <InnovationPage co_researcher_id={idencrypt} />
            </Route>

            <Route path="/CoResearcher/creative">
              <CreativePage co_researcher_id={idencrypt} />
            </Route>

            <Route path="/CoResearcher/project">
              <ProjectPage
                co_researcher_id={idencrypt}
                value={value}
                classes={classes}
              />
            </Route>

            <Route path="/CoResearcher/property">
              <PropertyPage
                co_researcher_id={idencrypt}
                classes={classes}
                valuePage1={valuePage1}
                handleChangePage1={handleChangePage1}
              />
            </Route>

            <Route path="/CoResearcher/contact">
              <ContactPage
                co_researcher_id={idencrypt}
                classes={classes}
                markermap={markermap}
              />
            </Route>
          </Switch>
        </div>
      </div>
    </div>
  );
}

export default withRouter(CoResearcher);
