import { Col } from "react-bootstrap";

const Youtubeshow = (props) => {
  const { url_video, xs, sm, md, lg, paddingTop, className } = props;
  const getId = (url) => {
    const regExp =
      /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|shorts\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = url.match(regExp);
    return match && match[2].length === 11 ? match[2] : null;
  };

  const youtubeId = getId(url_video);
  return (
    <Col
      xs={xs}
      sm={sm}
      md={md}
      lg={lg}
      className={className}
      style={{ paddingTop: paddingTop }}
    >
      <hr className="line-expertise" />
      <h4 style={{ textAlign: "left" }}>วิดีโอ</h4>
      {url_video !== "" ? (
        <>
          {youtubeId !== null ? (
            <iframe
              style={{ width: "100%", minHeight: "430px" }}
              src={`https://www.youtube.com/embed/${youtubeId}`}
              title="YouTube video player"
              frameBorder={0}
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          ) : (
            <div className="title-center">
              <h3 style={{ color: "#000", fontFamily: "Prompt" }}>
                ลิงค์วิดีโอไม่ถูกต้อง
              </h3>
            </div>
          )}
        </>
      ) : (
        <div className="title-center">
          <h3 style={{ color: "#000", fontFamily: "Prompt" }}>ไม่มีวิดีโอ</h3>
        </div>
      )}
    </Col>
  );
};

export default Youtubeshow;
