import { Box, Button, CircularProgress } from "@material-ui/core";
import { Card, Row, Col, Form, FormGroup, Input } from "reactstrap";
import React, { useRef } from "react";
import "./Css/Search.scss";
import "../index.css";
import { MapContainer, TileLayer } from "react-leaflet";
import { withRouter } from "react-router";
import { FaSearch } from "react-icons/fa";
import axios from "axios";
import { useTranslation } from "react-i18next";
import D3Layer from "./D3LayerV1";
import { Link } from "react-router-dom";
import token from "../config/token.json";
import { apiUrl } from "../config/api.json";
function SearchPageProject() {
  const detailRef = useRef();
  const windowUrl = window.location.search;
  const parameter = new URLSearchParams(windowUrl);
  const checkproject = parameter.get("checkproject");
  const checkservice = parameter.get("checkservice");
  const checku2t = parameter.get("checku2t");

  const checkyear = parameter.get("year");

  const { t } = useTranslation();
  // const [width, setwidth] = React.useState(window.innerWidth);
  const width = window.innerWidth;
  const [count_researcher, setcount_researcher] = React.useState(0);
  const [count_project, setcount_project] = React.useState(0);
  const [count_academic, setcount_academic] = React.useState(0);
  const [count_u2t, setcount_u2t] = React.useState(0);

  const [year, setYear] = React.useState(checkyear);
  const [option_year, setoption_year] = React.useState([]);

  const [selected1, setSelected1] = React.useState(1);
  const [selected2, setSelected2] = React.useState(2);
  const [selected5, setSelected5] = React.useState(5);
  const [loading, setLoading] = React.useState(false);

  const [check1, setcheck1] = React.useState(true);
  const [check2, setcheck2] = React.useState(true);
  const [check5, setcheck5] = React.useState(true);

  const [showdetail, setshowdetail] = React.useState(false);
  const [statusdetail, setstatusdetail] = React.useState(0);

  const [map1, setmap1] = React.useState([]);
  const [listdata, setlistdata] = React.useState([]);

  const [isHovering, setIsHovering] = React.useState(false);
  const [isHovering1, setIsHovering1] = React.useState(false);
  const [isHovering2, setIsHovering2] = React.useState(false);
  const [isHovering3, setIsHovering3] = React.useState(false);
  const [isHovering4, setIsHovering4] = React.useState(false);

  const handleMouseEnter = (e, type) => {
    if (type === 1) {
      setIsHovering(true);
    }
    if (type === 2) {
      setIsHovering1(true);
    }
    if (type === 3) {
      setIsHovering2(true);
    }
    if (type === 4) {
      setIsHovering3(true);
    }
    if (type === 5) {
      setIsHovering4(true);
    }
  };
  const handleMouseLeave = (e, type) => {
    if (type === 1) {
      setIsHovering(false);
    }
    if (type === 2) {
      setIsHovering1(false);
    }
    if (type === 3) {
      setIsHovering2(false);
    }
    if (type === 4) {
      setIsHovering3(false);
    }
    if (type === 5) {
      setIsHovering4(false);
    }
  };
  const handleChange = (event) => {
    setYear(event.target.value);
  };
  const mapDataCondition = async (
    type_project,
    type_service,
    type_u2t,
    year
  ) => {
    const response = await axios
      .get(
        `${apiUrl}/api/get/visualize/map?academic=${type_project}&academic_service=${type_service}&academic_service_u2t=${type_u2t}&year=${year}`,
        {
          headers: {
            "content-type": "application/json",
            "x-access-token": token.accesstoken,
          },
        }
      )
      .then((response) => {
        const researcher = response?.data?.nodes
          .filter(
            (item) => item.label === "นักวิจัย" || item.label === "ผู้ร่วมวิจัย"
          )
          .filter(
            (v, i, a) =>
              a.findIndex((v2) => v2.user_idcard === v.user_idcard) === i
          );
        setcount_researcher(researcher.length);
        setcount_project(response.data.count_project);
        setcount_academic(response.data.count_academic);
        setcount_u2t(response.data.count_u2t);
        setlistdata(response.data.nodes);
        return response.data;
      })
      .catch((err) => {
        return [];
      });
  };

  const onSubmit = (event) => {
    event.preventDefault();
    window.location.href = `/monitoring/map?checkproject=${check1}&checkservice=${check2}&checku2t=${check5}&year=${year}`;
  };
  const retrieveProjects = () => {
    setLoading(true);
    const conboolean = (string) => JSON.parse(string);
    setcheck1(conboolean(checkproject));
    setcheck2(conboolean(checkservice));
    setcheck5(conboolean(checku2t));
    const mapdataCon = mapDataCondition(
      conboolean(checkproject) === true ? 1 : "",
      conboolean(checkservice) === true ? 2 : "",
      conboolean(checku2t) === true ? 5 : "",
      checkyear
    );
    setmap1(mapdataCon);
    setLoading(false);
  };

  const getyear = () => {
    const d = new Date();
    const numyear = d.getFullYear() + 543;
    const y = [];
    for (let index = 0; index < 8; index++) {
      y.push(numyear - index);
    }
    setoption_year(y);
    console.log("y", y);
  };

  const mapRef = useRef();

  React.useEffect(() => {
    window.scrollTo(0, 0);
    retrieveProjects();
    getyear();
  }, []);

  const handleChange1 = (event) => {
    setcheck1(!check1);
    if (check1 === false) {
      setSelected1(1);
    }
    if (check1 === true) {
      setSelected1("");
    }
  };

  const handleChange2 = (event) => {
    setcheck2(!check2);
    if (check2 === false) {
      setSelected2(2);
    }
    if (check2 === true) {
      setSelected2("");
    }
  };

  const handleChange5 = (event) => {
    setcheck5(!check5);
    if (check5 === false) {
      setSelected5(5);
    }
    if (check5 === true) {
      setSelected5("");
    }
  };

  const onClickShowDeatil = (e, type) => {
    setshowdetail(true);
    setstatusdetail(type);
    setTimeout(() => {
      detailRef.current.scrollIntoView({ behavior: "smooth" });
    }, 200);
  };

  const Details = (data) => {
    const campuse = listdata.filter((item) => item.type === "parent");
    const project = listdata.filter((item) => item.label === "งานวิจัย");
    console.log("project", project);
    const projectservice = listdata.filter(
      (item) => item.label === "บริการวิชาการ"
    );
    const u2t = listdata.filter((item) => item.label === "U2T");
    const researcher = listdata
      .filter(
        (item) => item.label === "นักวิจัย" || item.label === "ผู้ร่วมวิจัย"
      )
      .filter(
        (v, i, a) => a.findIndex((v2) => v2.user_idcard === v.user_idcard) === i
      );

    setcount_researcher(researcher.length);
    return (
      <div ref={detailRef}>
        {data.type === 1 ? (
          <div className=" bg-title">
            <div
              className="card-university"
              style={{
                backgroundColor: "#fff",
                borderRadius: "15px",
              }}
            >
              <div
                className="card-header"
                style={{
                  padding: "1.5rem 1.5rem 0px",
                  borderRadius: "15px  15px 0 0",
                }}
              >
                <h5>วิทยาเขต</h5>
                <p
                  style={{
                    textAlign: "left",
                    marginTop: "0.5rem",
                    marginBottom: "0.5rem",
                  }}
                >
                  {t("dashboard.amount")} {campuse.length}{" "}
                  {t("dashboard.campuses")}
                </p>
              </div>
              <div className="card-university-body">
                {campuse.length ? (
                  <div className="list">
                    {campuse.map((listdata) => (
                      <div className="link_feature">
                        <Row
                          className="p-2 align-items-center justify-content-md-center "
                          style={{ width: "100%" }}
                          data-bs-toggle="tooltip"
                          data-bs-placement="bottom"
                          title={listdata.label}
                        >
                          <Col md="2">
                            <img
                              className="rounded-circle mx-auto d-block img-researcher"
                              width={40}
                              height={40}
                              src={listdata.img}
                              alt="logo-research"
                            />
                          </Col>
                          <Col md="10">
                            <text>{listdata.label}</text>
                          </Col>
                        </Row>
                        {/* </Link> */}
                        <hr
                          style={{
                            margin: "0.5rem 2rem",
                          }}
                        />
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className="list pt-4">{t("notfound")}</div>
                )}
              </div>
            </div>
          </div>
        ) : data.type === 2 ? (
          <div className=" bg-title">
            <div
              className="card-university"
              style={{
                backgroundColor: "#fff",
                borderRadius: "15px",
              }}
            >
              <div
                className="card-header"
                style={{
                  padding: "1.5rem 1.5rem 0px",
                  borderRadius: "15px  15px 0 0",
                }}
              >
                <h5>นักวิจัย</h5>
                <p
                  style={{
                    textAlign: "left",
                    marginTop: "0.5rem",
                    marginBottom: "0.5rem",
                  }}
                >
                  {t("dashboard.amount")} {researcher.length}{" "}
                  {t("dashboard.people")}
                </p>
              </div>
              <div className="card-university-body">
                {researcher.length ? (
                  <div className="list">
                    {researcher.map((listdata) => (
                      <div className="link_feature">
                        <Link
                          to={`/Researcher/locations?user_idcard=${btoa(
                            listdata.user_idcard
                          )}`}
                          className="linkexternal"
                        >
                          <Row
                            className="p-2 align-items-center justify-content-md-center "
                            style={{ width: "100%" }}
                            data-bs-toggle="tooltip"
                            data-bs-placement="bottom"
                            title={listdata.label}
                          >
                            <Col md="2">
                              <img
                                className="rounded-circle mx-auto d-block img-researcher"
                                width={40}
                                height={40}
                                src={listdata.img}
                                alt="logo-research"
                              />
                            </Col>
                            <Col md="4">
                              <text>{listdata.fullname}</text>
                            </Col>
                          </Row>
                        </Link>
                        <hr
                          style={{
                            margin: "0.5rem 2rem",
                          }}
                        />
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className="list pt-4">{t("notfound")}</div>
                )}
              </div>
            </div>
          </div>
        ) : data.type === 3 ? (
          <div className=" bg-title">
            <div
              className="card-university"
              style={{
                backgroundColor: "#fff",
                borderRadius: "15px",
              }}
            >
              <div
                className="card-header"
                style={{
                  padding: "1.5rem 1.5rem 0px",
                  borderRadius: "15px  15px 0 0",
                }}
              >
                <h5>งานวิจัย</h5>
                <p
                  style={{
                    textAlign: "left",
                    marginTop: "0.5rem",
                    marginBottom: "0.5rem",
                  }}
                >
                  {t("dashboard.amount")} {project.length}{" "}
                  {t("dashboard.projects")}
                </p>
              </div>
              <div className="card-university-body">
                {project.length ? (
                  <div className="list">
                    {project.map((listdata) => (
                      <div className="link_feature">
                        <Link
                          to={
                            listdata.project_id
                              ? `/ProjectDetail/ProjectNetwork?project_id=${btoa(
                                  listdata.project_id
                                )}`
                              : `/ProjectDetailConcep/ProjectNetwork?concep_id=${btoa(
                                  listdata.concept_proposal_id
                                )}`
                          }
                          className="linkexternal"
                        >
                          <Row
                            className="p-2 align-items-center justify-content-md-center "
                            style={{ width: "100%" }}
                            data-bs-toggle="tooltip"
                            data-bs-placement="bottom"
                            title={listdata.label}
                          >
                            <Col md="2">
                              <img
                                className="rounded-circle mx-auto d-block img-researcher"
                                width={40}
                                height={40}
                                src={listdata.img}
                                alt="logo-research"
                              />
                            </Col>
                            <Col md="10">
                              <text>
                                {listdata[t("project_name")]
                                  ? listdata[t("project_name")]
                                  : listdata.project_name_th}
                              </text>
                            </Col>
                          </Row>
                        </Link>
                        <hr
                          style={{
                            margin: "0.5rem 2rem",
                          }}
                        />
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className="list pt-4">{t("notfound")}</div>
                )}
              </div>
            </div>
          </div>
        ) : data.type === 4 ? (
          <div className=" bg-title">
            <div
              className="card-university"
              style={{
                backgroundColor: "#fff",
                borderRadius: "15px",
              }}
            >
              <div
                className="card-header"
                style={{
                  padding: "1.5rem 1.5rem 0px",
                  borderRadius: "15px  15px 0 0",
                }}
              >
                <h5>งานบริการวิชาการ</h5>
                <p
                  style={{
                    textAlign: "left",
                    marginTop: "0.5rem",
                    marginBottom: "0.5rem",
                  }}
                >
                  {t("dashboard.amount")} {projectservice.length}{" "}
                  {t("dashboard.projects")}
                </p>
              </div>
              <div className="card-university-body">
                {projectservice.length ? (
                  <div className="list">
                    {projectservice.map((listdata) => (
                      <div className="link_feature">
                        <Link
                          to={
                            listdata.project_id
                              ? `/ProjectDetail/ProjectNetwork?project_id=${btoa(
                                  listdata.project_id
                                )}`
                              : `/ProjectDetailConcep/ProjectNetwork?concep_id=${btoa(
                                  listdata.concept_proposal_id
                                )}`
                          }
                          className="linkexternal"
                        >
                          <Row
                            className="p-2 align-items-center justify-content-md-center "
                            style={{ width: "100%" }}
                            data-bs-toggle="tooltip"
                            data-bs-placement="bottom"
                            title={listdata.label}
                          >
                            <Col md="2">
                              <img
                                className="rounded-circle mx-auto d-block img-researcher"
                                width={40}
                                height={40}
                                src={listdata.img}
                                alt="logo-research"
                              />
                            </Col>
                            <Col md="10">
                              <text>
                                {" "}
                                {listdata[t("project_name")]
                                  ? listdata[t("project_name")]
                                  : listdata.project_name_th}
                              </text>
                            </Col>
                          </Row>
                        </Link>
                        <hr
                          style={{
                            margin: "0.5rem 2rem",
                          }}
                        />
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className="list pt-4">{t("notfound")}</div>
                )}
              </div>
            </div>
          </div>
        ) : data.type === 5 ? (
          <div className=" bg-title">
            <div
              className="card-university"
              style={{
                backgroundColor: "#fff",
                borderRadius: "15px",
              }}
            >
              <div
                className="card-header"
                style={{
                  padding: "1.5rem 1.5rem 0px",
                  borderRadius: "15px  15px 0 0",
                }}
              >
                <h5>งานบริการวิชาการ (U2T)</h5>
                <p
                  style={{
                    textAlign: "left",
                    marginTop: "0.5rem",
                    marginBottom: "0.5rem",
                  }}
                >
                  {t("dashboard.amount")} {u2t.length} {t("dashboard.projects")}
                </p>
              </div>
              <div className="card-university-body">
                {u2t.length ? (
                  <div className="list">
                    {u2t.map((listdata) => (
                      <div className="link_feature">
                        <Link
                          to={
                            listdata.project_id
                              ? `/ProjectDetail/ProjectNetwork?project_id=${btoa(
                                  listdata.project_id
                                )}`
                              : `/ProjectDetailConcep/ProjectNetwork?concep_id=${btoa(
                                  listdata.concept_proposal_id
                                )}`
                          }
                          className="linkexternal"
                        >
                          <Row
                            className="p-2 align-items-center justify-content-md-center "
                            style={{ width: "100%" }}
                            data-bs-toggle="tooltip"
                            data-bs-placement="bottom"
                            title={listdata.label}
                          >
                            <Col md="2">
                              <img
                                className="rounded-circle mx-auto d-block img-researcher"
                                width={40}
                                height={40}
                                src={listdata.img}
                                alt="logo-research"
                              />
                            </Col>
                            <Col md="10">
                              <text>
                                {listdata[t("project_name")]
                                  ? listdata[t("project_name")]
                                  : listdata.project_name_th}
                              </text>
                            </Col>
                          </Row>
                        </Link>
                        <hr
                          style={{
                            margin: "0.5rem 2rem",
                          }}
                        />
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className="list pt-4">{t("notfound")}</div>
                )}
              </div>
            </div>
          </div>
        ) : null}
      </div>
    );
  };

  // useEffect(() => {
  //   setwidth(window.innerWidth);
  // }, [width]);

  // console.log("width", width);
  // console.log("width21212", window.innerWidth);

  return (
    <div className="body-detail">
      <div className="card-searcher">
        <Row>
          <Col sm={12}>
            <Card className=" card-search">
              <div style={{ padding: "1rem" }}>
                <Box
                  component="form"
                  sx={{
                    "& .MuiTextField-root": {
                      m: 0,
                      width: "100%",
                    },
                  }}
                  noValidate
                  autoComplete="off"
                >
                  <div>
                    <Row className="align-items-center justify-content-md-center">
                      <Col md={7} xs={12}>
                        <Form
                          id="outlined-multiline-flexible"
                          multiline
                          Input
                          type="checkbox"
                          InputLabelProps={{
                            style: { fontFamily: "Prompt" },
                          }}
                          InputProps={{ style: { fontFamily: "Prompt" } }}
                          FormHelperTextProps={{
                            style: { fontFamily: "Prompt" },
                          }}
                          label="ประเภทงานวิจัย"
                          helperText="โปรดเลือก"
                        >
                          <FormGroup check inline className="font-search">
                            <div>
                              <img
                                width="45"
                                height="45"
                                className="rounded-circle"
                                aria-label="Placeholder: Image"
                                preserveAspectRatio="xMidYMid slice"
                                src={`https://researcher.kims-rmuti.com/icon/res@300x.png`}
                                style={{ padding: "11px" }}
                              />
                              <Input
                                type="checkbox"
                                onChange={handleChange1}
                                value={selected1}
                                checked={check1}
                                style={{
                                  marginTop: "15px",
                                }}
                              />
                              {t("map.research")}
                            </div>
                          </FormGroup>
                          <FormGroup check inline className="font-search">
                            <div>
                              <img
                                width="45"
                                height="45"
                                className="rounded-circle"
                                aria-label="Placeholder: Image"
                                preserveAspectRatio="xMidYMid slice"
                                src={`https://researcher.kims-rmuti.com/icon/AS.jpg`}
                                style={{ padding: "11px" }}
                              />
                              <Input
                                type="checkbox"
                                onChange={handleChange2}
                                value={selected2}
                                checked={check2}
                                style={{
                                  marginTop: "15px",
                                }}
                              />
                              {t("map.academic")}
                            </div>
                          </FormGroup>

                          <FormGroup check inline className="font-search">
                            <div>
                              <Input
                                type="checkbox"
                                onChange={handleChange5}
                                value={selected5}
                                checked={check5}
                                style={{
                                  marginTop: "15px",
                                }}
                              />
                              <img
                                width="45"
                                height="45"
                                className="rounded-circle"
                                aria-label="Placeholder: Image"
                                preserveAspectRatio="xMidYMid slice"
                                src={`https://researcher.kims-rmuti.com/icon/U2T.jpg`}
                                style={{ padding: "11px" }}
                              />
                              {t("map.u2t")}
                            </div>
                          </FormGroup>
                        </Form>
                      </Col>

                      <Col md={2} xs={6}>
                        <select
                          className="form-select card-border  btn-search"
                          aria-label="Default select example"
                          style={{
                            color: "white",
                            border: "0px solid black",
                          }}
                          onChange={handleChange}
                          value={year}
                        >
                          {option_year.map((d) => (
                            <option value={d}>
                              {t("year")} {d}
                            </option>
                          ))}
                        </select>
                      </Col>

                      <Col md={2} xs={6}>
                        <Button
                          className="card-border  btn-search"
                          variant="contained"
                          type="submit"
                          onClick={onSubmit}
                          size="medium"
                          disabled={loading}
                          startIcon={<FaSearch size={13} />}
                        >
                          {loading && <CircularProgress size={22} />}
                          {!loading && t("search")}
                        </Button>
                      </Col>
                    </Row>
                  </div>
                </Box>
              </div>
            </Card>
          </Col>

          {width < 600 ? (
            <div style={{ marginTop: "4rem" }}></div>
          ) : (
            <Col sm={12}>
              <Card
                className="card-border"
                style={{
                  marginTop: "10px",
                  fontFamily: "Prompt",
                  borderRadius: "15px",
                  boxShadow: "none",
                  border: "none",
                }}
              >
                <MapContainer
                  center={[15, 103]}
                  zoom={7}
                  minZoom={3}
                  maxZoom={21}
                  ref={mapRef}
                  gestureHandling={true}
                  zoomControl={false}
                  style={{
                    width: "100%",
                    minHeight: "450px",
                    height: "70vh",
                    margin: "0",
                    zIndex: "0",
                    borderRadius: "15px",
                  }}
                >
                  <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                  {map1.length ? <D3Layer location={map1} /> : null}
                </MapContainer>
              </Card>
            </Col>
          )}
          <Col sm={12}>
            <div
              className="dashboard"
              style={{ marginTop: "-4.5rem", marginBottom: "-1rem" }}
            >
              <div className="all-card">
                <Row style={{ paddingLeft: "10px" }}>
                  <Col>
                    <div
                      className="card "
                      style={{
                        cursor: "pointer",
                        backgroundColor: isHovering ? "#eeeeee" : "",
                        color: isHovering ? "white" : "",
                      }}
                      onMouseEnter={(e) => handleMouseEnter(e, 1)}
                      onMouseLeave={(e) => handleMouseLeave(e, 1)}
                      onClick={(event) => onClickShowDeatil(event, 1)}
                    >
                      <div className="card-body ">
                        <h5 className="card-title text-title">
                          {t("dashboard.rmuti")}
                        </h5>
                        <h2 className="card-text text-amount">
                          5 {t("dashboard.campuses")}
                        </h2>
                      </div>
                    </div>
                  </Col>
                  <Col>
                    <div
                      className="card"
                      style={{
                        cursor: "pointer",
                        backgroundColor: isHovering1 ? "#eeeeee" : "",
                        color: isHovering1 ? "white" : "",
                      }}
                      onMouseEnter={(e) => handleMouseEnter(e, 2)}
                      onMouseLeave={(e) => handleMouseLeave(e, 2)}
                      onClick={(event) => onClickShowDeatil(event, 2)}
                    >
                      <div className="card-body ">
                        <h5 className="card-title text-title">
                          {t("dashboard.researcher")}
                        </h5>
                        <h2 className="card-text text-amount">
                          {count_researcher} {t("dashboard.people")}
                        </h2>
                      </div>
                    </div>
                  </Col>
                  <Col>
                    <div
                      className="card"
                      style={{
                        cursor: "pointer",
                        backgroundColor: isHovering2 ? "#eeeeee" : "",
                        color: isHovering2 ? "white" : "",
                      }}
                      onMouseEnter={(e) => handleMouseEnter(e, 3)}
                      onMouseLeave={(e) => handleMouseLeave(e, 3)}
                      onClick={(event) => onClickShowDeatil(event, 3)}
                    >
                      <div
                        className="card-body "
                        style={{ padding: "1rem 0.5rem" }}
                      >
                        <h5 className="card-title text-title">
                          {t("dashboard.research")}
                        </h5>
                        <h2 className="card-text text-amount">
                          {count_project} {t("dashboard.projects")}
                        </h2>
                      </div>
                    </div>
                  </Col>
                  <Col>
                    <div
                      className="card"
                      style={{
                        cursor: "pointer",
                        backgroundColor: isHovering3 ? "#eeeeee" : "",
                        color: isHovering3 ? "white" : "",
                      }}
                      onMouseEnter={(e) => handleMouseEnter(e, 4)}
                      onMouseLeave={(e) => handleMouseLeave(e, 4)}
                      onClick={(event) => onClickShowDeatil(event, 4)}
                    >
                      <div className="card-body ">
                        <h5 className="card-title text-title">
                          {t("dashboard.academic")}
                        </h5>
                        <h2 className="card-text text-amount">
                          {count_academic} {t("dashboard.projects")}
                        </h2>
                      </div>
                    </div>
                  </Col>
                  <Col>
                    <div
                      className="card"
                      style={{
                        cursor: "pointer",
                        backgroundColor: isHovering4 ? "#eeeeee" : "",
                        color: isHovering4 ? "white" : "",
                      }}
                      onMouseEnter={(e) => handleMouseEnter(e, 5)}
                      onMouseLeave={(e) => handleMouseLeave(e, 5)}
                      onClick={(event) => onClickShowDeatil(event, 5)}
                    >
                      <div className="card-body ">
                        <h5 className="card-title text-title">
                          {t("dashboard.u2t")}
                        </h5>
                        <h2 className="card-text text-amount">
                          {count_u2t} {t("dashboard.projects")}
                        </h2>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
          <Col>{showdetail ? <Details type={statusdetail} /> : null}</Col>
        </Row>
      </div>
    </div>
  );
}

export default withRouter(SearchPageProject);
