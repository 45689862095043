import React, { useEffect, useState } from "react";

import { Row, Col } from "react-bootstrap";
import "../Css/Detail.scss";
import axios from "axios";
import { FcOnlineSupport, FcPhone, FcViewDetails } from "react-icons/fc";
import no_img_product from "../../images/no_img_product.png";
import { Link } from "react-router-dom";

import "react-image-gallery/styles/css/image-gallery.css";
import { apiUrl } from "../../config/api.json";
export default function ProductPage(props) {
  const { co_researcher_id } = props;
  const [product, setproduct] = useState([]);
  const [countall, setcountall] = useState("");
  const [productproject, setproductproject] = useState([]);
  const [product_district, setproduct_district] = useState([]);
  const [product_community, setproduct_community] = useState([]);
  const [product_phi, setproduct_phi] = useState([]);
  let id = atob(co_researcher_id);

  useEffect(() => {
    axios
      .get(
        `${apiUrl}/api/get/co-researcher/innovation/images/type?co_id=${id}&pi_type_id=2`
      )
      .then((result) => {
        setcountall(result.data.count_all);
        if (result.data.products) {
          setproduct(result.data.products);
        }
        if (result.data.products) {
          setproductproject(result.data.productsproject);
        }
        if (result.data.details) {
          result.data.details.forEach((el) => {
            if (el.co_group_name === "ตำบล") {
              if (el.products.length) {
                setproduct_district(el);
              }
            } else if (el.co_group_name === "ชุมชน") {
              if (el.products.length) {
                setproduct_community(el);
              }
            } else if (el.co_group_name === "ปราชญ์") {
              if (el.products.length) {
                setproduct_phi(el);
              }
            }
          });
        }
        setproduct(result.data.products);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const DetailPage = (obj) => {
    let { product } = obj;
    return (
      <>
        {product.map(function (listValue, index) {
          var url_image = "";
          if (listValue.co_researcher_pi_image) {
            url_image = `https://researcher.kims-rmuti.com/file-upload/co_innovationproduct_upload/${listValue.co_researcher_pi_image}`;
          } else if (listValue.images.length > 0) {
            if (listValue.images[0].co_innovation_image) {
              url_image = `https://researcher.kims-rmuti.com/file-upload/co_innovationproduct_upload/${listValue.images[0].co_innovation_image}`;
            }
          } else {
            url_image = no_img_product;
          }
          const url = `/InnovationDetail?type=product&innovationid=${listValue.co_researcher_pi_id}&typetable=`;
          return (
            <Col
              xs={12}
              sm={6}
              md={6}
              lg={4}
              style={{ padding: "1rem 1rem" }}
              key={index}
            >
              <div
                className="card card-bg-innovation card-innovation"
                style={{
                  boxShadow: "14px 13px 0px -5px rgb(159 159 159 / 37%)",
                  padding: "0.5rem",
                  backgroundColor: "#fff",
                }}
              >
                <div className="card__image-holder">
                  <img
                    className="card__image img-innovation"
                    src={url_image}
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null; // prevents looping
                      currentTarget.src = no_img_product;
                    }}
                    alt="co_researcher_pi_image"
                  />
                </div>
                <div
                  className="card-innovation-title"
                  style={{ backgroundColor: "#fff" }}
                >
                  <h2
                    className="innovation-title"
                    style={{ paddingTop: "0.5rem" }}
                  >
                    {listValue.co_researcher_pi_name
                      ? listValue.co_researcher_pi_name
                      : "-"}
                  </h2>
                  <small className="innovation">
                    ราคา:{" "}
                    {listValue.co_researcher_pi_price
                      ? listValue.co_researcher_pi_price
                      : "-"}{" "}
                    บาท
                  </small>
                </div>
                <div
                  className="descriptions"
                  style={{ backgroundColor: "#fff" }}
                >
                  <h5 className="title-hover">
                    {" "}
                    {listValue.co_researcher_pi_name
                      ? listValue.co_researcher_pi_name
                      : "-"}
                  </h5>
                  <p>
                    <FcViewDetails size={15} /> :{" "}
                    {listValue.co_researcher_pi_details
                      ? listValue.co_researcher_pi_details.replace(
                          /<[^>]+>|&nbsp;|&quot;/g,
                          ""
                        )
                      : "-"}{" "}
                  </p>
                  <p>
                    จำนวนการผลิต :{" "}
                    {listValue.co_researcher_pi_amount
                      ? listValue.co_researcher_pi_amount
                      : "-"}{" "}
                    ชิ้น
                  </p>
                  <p>
                    <FcOnlineSupport size={15} /> :{" "}
                    {listValue.co_researcher_pi_coordinator
                      ? listValue.co_researcher_pi_coordinator
                      : "-"}
                  </p>
                  <p>
                    <FcPhone size={15} /> :{" "}
                    {listValue.co_researcher_pi_phone
                      ? listValue.co_researcher_pi_phone
                      : "-"}
                  </p>
                  <Link to={url} className="btn-innovation">
                    ดูเพิ่มเติม
                  </Link>
                </div>
              </div>
            </Col>
          );
        })}
      </>
    );
  };
  return (
    <div>
      <Row className="div-detail">
        {/* {product.length ? (
          <> */}
        <h5 style={{ paddingTop: "1rem", textAlign: "left" }}>
          ผลิตภัณฑ์ทั้งหมด {countall} รายการ
        </h5>
        <div className="line-bottom" style={{ margin: "1rem 0rem" }} />
        <DetailPage product={product} />

        <h5 style={{ paddingTop: "1rem", textAlign: "left" }}>
          ผลิตภัณฑ์ของโครงการ
        </h5>
        <div className="line-bottom" style={{ margin: "1rem 0rem" }} />
        <DetailPage product={productproject} />

        {Object.keys(product_district).length ? (
          <div>
            <h5 style={{ paddingTop: "1rem", textAlign: "left" }}>
              {product_district.co_group_name}
            </h5>
            <div className="line-bottom" style={{ margin: "1rem 1rem" }} />
            {product_district.products?.map((ex, i) => (
              <div key={i}>
                <p style={{ textAlign: "left" }}>{ex.co_researcher_name_th}</p>
                <Row style={{ paddingRight: "2rem" }}>
                  <DetailPage product={ex.products} />
                </Row>
              </div>
            ))}
          </div>
        ) : null}

        {Object.keys(product_community).length ? (
          <div>
            <h5 style={{ paddingTop: "1rem", textAlign: "left" }}>
              {product_community.co_group_name}
            </h5>
            <div className="line-bottom" style={{ margin: "1rem 1rem" }} />
            {product_community.products?.map((ex, i) => (
              <div key={i}>
                <p style={{ textAlign: "left" }}>{ex.co_researcher_name_th}</p>
                <Row style={{ paddingRight: "2rem" }}>
                  <DetailPage product={ex.products} />
                </Row>
              </div>
            ))}
          </div>
        ) : null}

        {Object.keys(product_phi).length ? (
          <div>
            <h5 style={{ paddingTop: "1rem", textAlign: "left" }}>
              {product_phi.co_group_name}
            </h5>
            <div className="line-bottom" style={{ margin: "1rem 1rem" }} />
            {product_phi.products?.map((ex, i) => (
              <div key={i}>
                <p style={{ textAlign: "left" }}>{ex.co_researcher_name_th}</p>
                <Row style={{ paddingRight: "2rem" }}>
                  <DetailPage product={ex.products} />
                </Row>
              </div>
            ))}
          </div>
        ) : null}
        {/* </>
        ) : (
          <h6 style={{ textAlign: "center" }}>ไม่พบข้อมูล</h6>
        )} */}
      </Row>
    </div>
  );
}
