import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";

import axios from "axios";
import { FcOnlineSupport, FcPhone, FcViewDetails } from "react-icons/fc";
import no_img_innovation from "../../images/no_img_innovation.png";
import { Link } from "react-router-dom";
import { apiUrl } from "../../config/api.json";
export default function InnovationPage(props) {
  const { co_researcher_id } = props;
  const [innovations, setinnovations] = useState([]);
  const [countall, setcountall] = useState("");
  const [innovationsproject, setinnovationsproject] = useState([]);
  const [innovations_district, setinnovations_district] = useState([]);
  const [innovations_community, setinnovations_community] = useState([]);
  const [innovations_phi, setinnovations_phi] = useState([]);

  let id = atob(co_researcher_id);

  useEffect(() => {
    axios
      .get(
        `${apiUrl}/api/get/co-researcher/innovation/images/type?co_id=${id}&pi_type_id=1`
      )
      .then((result) => {
        setcountall(result.data.count_all);
        if (result.data.innovations) {
          setinnovations(result.data.innovations);
        }
        if (result.data.innovationsproject) {
          setinnovationsproject(result.data.innovationsproject);
        }
        if (result.data.details) {
          result.data.details.forEach((el) => {
            if (el.co_group_name === "ตำบล") {
              if (el.innovations.length) {
                setinnovations_district(el);
              }
            } else if (el.co_group_name === "ชุมชน") {
              if (el.innovations.length) {
                setinnovations_community(el);
              }
            } else if (el.co_group_name === "ปราชญ์") {
              if (el.innovations.length) {
                setinnovations_phi(el);
              }
            }
          });
        }
        // console.log("dayta : ", result.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const DetailPage = (obj) => {
    let { innovations } = obj;
    // console.log("obj", obj);
    return (
      <>
        {innovations.map(function (listValue, i) {
          var url_image = "";
          if (listValue.co_researcher_pi_image) {
            url_image = `https://researcher.kims-rmuti.com/file-upload/co_innovationproduct_upload/${listValue.co_researcher_pi_image}`;
          } else if (listValue.images.length > 0) {
            if (listValue.images[0].co_innovation_image) {
              url_image = `https://researcher.kims-rmuti.com/file-upload/${
                listValue.innovation_id
                  ? "us_innovation-upload"
                  : "co_innovationproduct_upload"
              }/${listValue.images[0].co_innovation_image}`;
            }
          } else {
            url_image = no_img_innovation;
          }
          const url = `/InnovationDetail?type=innovation&innovationid=${
            listValue.co_researcher_pi_id
              ? listValue.co_researcher_pi_id
              : listValue.innovation_id
          }&typetable=${listValue.innovation_id ? "ustable" : "cotable"}`;

          return (
            <Col xs={12} sm={6} md={6} lg={4} style={{ padding: "1rem 1rem" }}>
              <div
                className="card card-bg-innovation card-innovation"
                style={{
                  boxShadow: "14px 13px 0px -5px rgb(159 159 159 / 37%)",
                  padding: "0.5rem",
                  backgroundColor: "#fff",
                }}
              >
                <div className="card__image-holder">
                  <img
                    className="card__image img-innovation"
                    src={url_image}
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null; // prevents looping
                      currentTarget.src = no_img_innovation;
                    }}
                    alt="co_researcher_pi_image"
                  />
                </div>
                <div
                  className="card-innovation-title"
                  style={{ backgroundColor: "#fff" }}
                >
                  <h2
                    className="innovation-title"
                    style={{ paddingTop: "0.5rem" }}
                  >
                    {listValue.co_researcher_pi_name
                      ? listValue.co_researcher_pi_name
                      : listValue.innovation_name
                      ? listValue.innovation_name
                      : "-"}
                  </h2>
                  <small className="innovation">
                    ราคา:{" "}
                    {listValue.co_researcher_pi_price
                      ? listValue.co_researcher_pi_price
                      : listValue.innovation_price
                      ? listValue.innovation_price
                      : "-"}{" "}
                    บาท
                  </small>
                </div>
                <div
                  className="descriptions"
                  style={{ backgroundColor: "#fff" }}
                >
                  <h5 className="title-hover">
                    {" "}
                    {listValue.co_researcher_pi_name
                      ? listValue.co_researcher_pi_name
                      : listValue.innovation_name
                      ? listValue.innovation_name
                      : "-"}
                  </h5>
                  <p>
                    <FcViewDetails size={15} /> :{" "}
                    {listValue.co_researcher_pi_details
                      ? listValue.co_researcher_pi_details.replace(
                          /<[^>]+>|&nbsp;|&quot;/g,
                          ""
                        )
                      : "-"}{" "}
                  </p>
                  <p>
                    จำนวนการผลิต :{" "}
                    {listValue.co_researcher_pi_amount
                      ? listValue.co_researcher_pi_amount
                      : listValue.innovation_amount
                      ? listValue.innovation_amount
                      : "-"}{" "}
                    ชิ้น
                  </p>
                  <p>
                    <FcOnlineSupport size={15} /> :{" "}
                    {listValue.co_researcher_pi_coordinator
                      ? listValue.co_researcher_pi_coordinator
                      : "-"}
                  </p>
                  <p>
                    <FcPhone size={15} /> :{" "}
                    {listValue.co_researcher_pi_phone
                      ? listValue.co_researcher_pi_phone
                      : "-"}
                  </p>
                  <Link to={url} className="btn-innovation">
                    ดูเพิ่มเติม
                  </Link>
                </div>
              </div>
            </Col>
          );
        })}
      </>
    );
  };

  return (
    <div>
      <Row className="div-detail">
        {/* {innovations.length ? (
          <> */}
        <h5 style={{ paddingTop: "1rem", textAlign: "left" }}>
          นวัตกรรมทั้งหมด {countall} รายการ
        </h5>
        <div className="line-bottom" style={{ margin: "1rem 0rem" }} />
        <DetailPage innovations={innovations} />

        <h5 style={{ paddingTop: "1rem", textAlign: "left" }}>
          นวัตกรรมของโครงการ
        </h5>
        <div className="line-bottom" style={{ margin: "1rem 0rem" }} />
        <DetailPage innovations={innovationsproject} />

        {Object.keys(innovations_district).length ? (
          <div>
            <h5 style={{ paddingTop: "1rem", textAlign: "left" }}>
              {innovations_district.co_group_name}
            </h5>
            <div className="line-bottom" style={{ margin: "1rem 1rem" }} />
            {innovations_district.innovations?.map((ex, i) => (
              <div key={i}>
                <p style={{ textAlign: "left" }}>{ex.co_researcher_name_th}</p>
                <Row style={{ paddingRight: "2rem" }}>
                  <DetailPage innovations={ex.innovations} />
                </Row>
              </div>
            ))}
          </div>
        ) : null}

        {Object.keys(innovations_community).length ? (
          <div>
            <h5 style={{ paddingTop: "1rem", textAlign: "left" }}>
              {innovations_community.co_group_name}
            </h5>
            <div className="line-bottom" style={{ margin: "1rem 1rem" }} />
            {innovations_community.innovations?.map((ex, i) => (
              <div key={i}>
                <p style={{ textAlign: "left" }}>{ex.co_researcher_name_th}</p>
                <Row style={{ paddingRight: "2rem" }}>
                  <DetailPage innovations={ex.innovations} />
                </Row>
              </div>
            ))}
          </div>
        ) : null}

        {Object.keys(innovations_phi).length ? (
          <div>
            <h5 style={{ paddingTop: "1rem", textAlign: "left" }}>
              {innovations_phi.co_group_name}
            </h5>
            <div className="line-bottom" style={{ margin: "1rem 1rem" }} />
            {innovations_phi.innovations?.map((ex, i) => (
              <div key={i}>
                <p style={{ textAlign: "left" }}>{ex.co_researcher_name_th}</p>
                <Row style={{ paddingRight: "2rem" }}>
                  <DetailPage innovations={ex.innovations} />
                </Row>
              </div>
            ))}
          </div>
        ) : null}

        {/* </>
        ) : (
          <h6 style={{ textAlign: "center" }}>ไม่พบข้อมูล</h6>
        )} */}
      </Row>
    </div>
  );
}
