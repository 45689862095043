import TableContainer from "@material-ui/core/TableContainer";
import Grid from "@material-ui/core/Grid";
import { Card } from "@material-ui/core";
import { CardBody, CardText } from "reactstrap";

export default function Course(props) {
  const { course } = props;
  return (
    <Grid container spacing={1}>
      <Grid item xs>
        <TableContainer className="card-header-border ">
          <Card>
            <CardBody>
              <h5>หลักสูตร</h5>
            </CardBody>
            <CardBody>
              <hr style={{ marginTop: "-10px" }} />
              <Grid
                className="rc-body2"
                container
                spacing={1}
                style={{
                  fontFamily: "Prompt",
                  padding: "20px 20px 20px 20px",
                }}
              >
                <TableContainer className="">
                  <CardText
                    style={{
                      textAlign: "left",
                      padding: "20px 20px 20px 20px",
                    }}
                  >
                    {course.length ? (
                      <div>
                        {course.map((listValue, index) => {
                          return (
                            <CardText
                              key={index}
                              style={{
                                background: "#faebd6",
                                borderRadius: "10px 10px 10px 10px ",
                                padding: "20px 20px 20px 20px",
                              }}
                            >
                              <p> ชื่อหลักสูตร : {listValue.course_name_th}</p>
                              <p>
                                {" "}
                                ชื่อหลักสูตร(Eng.) : {
                                  listValue.course_name_en
                                }{" "}
                              </p>
                              <p>ระดับหลักสูตร : {listValue.course_type}</p>
                              <a
                                href={listValue.course_links}
                                target="_black"
                                style={{ fontFamily: "Prompt" }}
                              >
                                รายละเอียดเพิ่มเติม
                              </a>
                            </CardText>
                          );
                        })}
                      </div>
                    ) : (
                      <div>ไม่พบข้อมูล</div>
                    )}
                  </CardText>
                </TableContainer>
              </Grid>
            </CardBody>
          </Card>
        </TableContainer>
      </Grid>
    </Grid>
  );
}
