import { Col } from "react-bootstrap";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";

import no_img_product from "../../images/no_img_product.png";
const Imageshow = (props) => {
  const { image, images, imageshow, lg } = props;

  return (
    <>
      {images.length > 1 ? (
        <Col
          xs={12}
          sm={12}
          md={12}
          lg={lg}
          style={{
            paddingTop: "2rem",
            paddingBottom: "1rem",
            display: "block",
          }}
          className="div-image-knowledge"
        >
          <ImageGallery
            showThumbnails={true}
            showPlayButton={false}
            autoPlay={true}
            items={imageshow}
          />
        </Col>
      ) : (
        <Col
          xs={12}
          sm={12}
          md={12}
          lg={lg}
          style={{
            paddingTop: "2rem",
            paddingBottom: "1rem",
          }}
          className="div-image-knowledge"
        >
          <img
            className="img-radius"
            src={image}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src = no_img_product;
            }}
            style={{ width: "100%" }}
            alt="imgae-Co"
          />
        </Col>
      )}
    </>
  );
};

export default Imageshow;
